import { Colors, base_font, base_font_mb } from '@styles/base/styled';
const SignUpSoftRegPopupStyle = {
  Colors: {
    softRegPopupWrapper: Colors.black,
    innerWrap: Colors.white,
    subHeading: Colors.black,
    btmImgWrap: Colors.gray2,
    drawerInnerWrap: Colors.black,
    title: Colors.gray1,
    label: Colors.black,
    requiredSign: Colors.black,
    Desc: Colors.black,
    regLabelBigInterview: Colors.black,
    bigInterviewInput: Colors.gray1,
    footNoteBigInterview: Colors.gray1,
    BtmImgWrapHead: Colors.gray1,
    BigReferedTxt: Colors.white,
    BigReferedTxtSm: Colors.blue22,
  },
  FontSizes: {
    formHeading: (base_font * 1.62).toFixed(2) + 'rem',
    footNote: base_font_mb + 'rem',
    footNoteSm: (base_font * 0.75).toFixed(2) + 'rem',
    secDesc: (base_font * 0.875).toFixed(2) + 'rem',
    title: base_font + 'rem',
    heading: (base_font * 1.375).toFixed(2) + 'rem',
    continueBtn: (base_font * 1.25).toFixed(2) + 'rem',
  },
};

export default SignUpSoftRegPopupStyle;
