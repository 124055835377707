import CompAwards from '@styled/sapphire/awardAccolades';
import BreadcrumbStyle from '@styled/sapphire/common/breadcrumb';
import BtnStyle from '@styled/sapphire/common/button';
import CarouselStyle from '@styled/sapphire/common/carousel';
import FilterBtnStyle from '@styled/sapphire/common/filter';
import FormCheckStyle from '@styled/sapphire/common/formCheck';
import FormGroupStyle from '@styled/sapphire/common/formGroup';
import JobPltfrmStyle from '@styled/sapphire/common/gigJobPlatforms';
import HeaderSectionStyle from '@styled/sapphire/common/headerSection';
import jobGuideInfoStyle from '@styled/sapphire/common/jobGuidePopover';
import PltfrmTypeStyle from '@styled/sapphire/common/jobPlatformTypes';
import PillsStyle from '@styled/sapphire/common/linkPills';
import ModalStyle from '@styled/sapphire/common/popUp';
import JobSearchStyle from '@styled/sapphire/common/remoteJobSearch';
import HeroSecStyle from '@styled/sapphire/common/searchPillsBanner';
import SectionCollapse from '@styled/sapphire/common/sectionCollapsible';
import telecommuteJobStyle from '@styled/sapphire/common/telecommuteJob';
import ToasterSectionStyle from '@styled/sapphire/common/toaster';
import toolTipStyle from '@styled/sapphire/common/toolTip';
import UpComingEventStyle from '@styled/sapphire/common/widgets/upComingEvents';
import IndvCompStyle from '@styled/sapphire/companies';
import BannerStyle from '@styled/sapphire/companies/companiesHiringBanner';
import CompanyCtaPopupStyle from '@styled/sapphire/companies/companyCtaPopup';
import CompanyGuideStyle from '@styled/sapphire/companies/companyGuide';
import OverviewStyle from '@styled/sapphire/companies/overviewSuccess';
import PastJobsStyle from '@styled/sapphire/companies/pastJobs';
import CompActivityStyle from '@styled/sapphire/companyActivity';
import CompanyAwardsStyle from '@styled/sapphire/companyAwards';
import CompanyHiringStyle from '@styled/sapphire/companyHiring';
import CompanySearchesStyle from '@styled/sapphire/companySearches';
import CatLinkStyle from '@styled/sapphire/dropableCategories/index';
import IndPlatformStyle from '@styled/sapphire/gigs/companyDetails';
import BenefitStyle from '@styled/sapphire/homePage/benefits';
import GreatEmployer from '@styled/sapphire/homePage/greatEmployer';
import HeroBannerStyle from '@styled/sapphire/homePage/heroBanner';
import MobBannerStyle from '@styled/sapphire/homePage/mobHeroBanner';
import NewsBlogStyle from '@styled/sapphire/homePage/newsBlog';
import NotOrdinaryStyle from '@styled/sapphire/homePage/notOrdinarySection';
import PopularJobSearch from '@styled/sapphire/homePage/popularJobSearch';
import QualityBannerStyle from '@styled/sapphire/homePage/remoteJobQualityBanner';
import TestimonialStyle from '@styled/sapphire/homePage/testimonial';
import JobCardStyle from '@styled/sapphire/jobCard';
import JobDetailsStyle from '@styled/sapphire/jobDetails';
import JobInfoStyle from '@styled/sapphire/jobInfo';
import JobInfoFooterStyle from '@styled/sapphire/jobInfoFooter';
import PltfrmDetailStyle from '@styled/sapphire/jobPlatforms';
import PltfrmCatgStyle from '@styled/sapphire/jobPlatforms/category';
import EcoPlatformStyle from '@styled/sapphire/jobPlatforms/featuredPlatform';
import HeadBannerStyle from '@styled/sapphire/jobPlatforms/headingBanner';
import TopCatgStyle from '@styled/sapphire/latestCategories';
import LoginStyle from '@styled/sapphire/login';
import MapStyle from '@styled/sapphire/map';
import ApplicationStyle from '@styled/sapphire/myJobApplications';
import PlatformInfoStyle from '@styled/sapphire/platformInfo';
import RegisterUserStyle from '@styled/sapphire/registerUser';
import ResearchCompanyStyle from '@styled/sapphire/researchCompanies';
import UnAuthHeroSecStyle from '@styled/sapphire/searchPillsBannerUnauth';
import { DefaultTheme } from 'styled-components';
import SignUpSoftRegPopupStyle from './components/SoftRegPopup';
import AdvanceJobStyle from './components/advanceJobSearches';
import CatgoryDetailStyle from './components/categoryDetails';
import CommonStyle from './components/common';
import ApplyBtnStyle from './components/common/applyBtn';
import FormRadioStyle from './components/common/formRadio';
import FormControlStyle from './components/common/fromSelect';
import ChipsStyle from './components/common/mulitsSelectChips';
import MultiFilterStyle from './components/common/multiSelectdropdown';
import PaginationStyle from './components/common/pagination';
import ProgressStyle from './components/common/progressBar';
import RadialProgStyle from './components/common/radialProgress';
import SearchFilterStyle from './components/common/searchFilter';
import SidebarStyle from './components/common/sidebarWidget';
import CompRedesign from './components/companies/companyRedesignMob';
import UnAuthPopupStyle from './components/companies/unAuthPopup';
import CompanyByLetterStyle from './components/companyByLetter';
import CompanyDirectoryStyle from './components/companyDirectory';
import CompanyFlexibilityStyle from './components/companyFlexibility';
import EmployersStyle from './components/employers';
import ExitPopUpStyle from './components/exitPopUp';
import featureJobStyle from './components/featureRemoteJob';
import JobBannerStyle from './components/jobBanner';
import JobBoxStyle from './components/jobBox';
import JobDetailStyle from './components/jobDetailVisitor';
import JobNoteStyle from './components/jobNoteStyle';
import JobSavedSearchStyle from './components/jobSearchActivity';
import LayoutStyle from './components/layout';
import CareerWidgetStyle from './components/myJobApplications/jobWidgets/careerAdvice';
import SearchesChecklist from './components/myJobApplications/jobWidgets/jobSearchChecklists';
import SkillsWidgetStyle from './components/myJobApplications/jobWidgets/popularSkillTests';
import NewProfileFormStyle from './components/newProfileForm';
import RegisterUserVariantStyle from './components/registerUserVariant';
import RemoteJobChildPageStyle from './components/remoteJobChildPage';
import ResumeProfileStyle from './components/resumeProfile';
import SavedSearches from './components/savedSearches';
import SignUpStyle from './components/signUp';
import StepBannerStyle from './components/stepBanner';
import SuccessStoryVariantStyle from './components/successStoryVariant';
import TopCategoriesStyle from './components/topCategories';
import JobVariantDrawerStyle from './components/variants/jobVariantDrawer';
import FormCtrlStyle from './helpers/controls';
import LabelCommonStyle from './helpers/controls/labelCommon';

const SapphireTheme: DefaultTheme = {
  SearchFilterStyle,
  JobCardStyle,
  IndPlatformStyle,
  PltfrmDetailStyle,
  CompAwards,
  QualityBannerStyle,
  PltfrmCatgStyle,
  HeadBannerStyle,
  JobPltfrmStyle,
  PltfrmTypeStyle,
  NewsBlogStyle,
  EcoPlatformStyle,
  CarouselStyle,
  BtnStyle,
  SignUpSoftRegPopupStyle,
  GreatEmployer,
  JobInfoFooterStyle,
  CompActivityStyle,
  CompRedesign,
  UpComingEventStyle,
  JobSearchStyle,
  MobBannerStyle,
  BenefitStyle,
  HeroSecStyle,
  NewProfileFormStyle,
  PillsStyle,
  ToasterSectionStyle,
  toolTipStyle,
  jobGuideInfoStyle,
  ApplicationStyle,
  TestimonialStyle,
  PopularJobSearch,
  FilterBtnStyle,
  UnAuthHeroSecStyle,
  TopCatgStyle,
  SectionCollapse,
  CatLinkStyle,
  BreadcrumbStyle,
  NotOrdinaryStyle,
  ModalStyle,
  JobDetailsStyle,
  JobInfoStyle,
  RegisterUserStyle,
  CompanyHiringStyle,
  CompanySearchesStyle,
  CompanyAwardsStyle,
  ResearchCompanyStyle,
  IndvCompStyle,
  LoginStyle,
  MapStyle,
  PlatformInfoStyle,
  CompanyGuideStyle,
  OverviewStyle,
  BannerStyle,
  CompanyCtaPopupStyle,
  PastJobsStyle,
  FormControlStyle,
  FormCheckStyle,
  FormGroupStyle,
  FormCtrlStyle,
  HeaderSectionStyle,
  RadialProgStyle,
  HeroBannerStyle,
  JobSavedSearchStyle,
  ProgressStyle,
  ResumeProfileStyle,
  PaginationStyle,
  SidebarStyle,
  CatgoryDetailStyle,
  SavedSearches,
  SearchesChecklist,
  CareerWidgetStyle,
  SkillsWidgetStyle,
  telecommuteJobStyle,
  MultiFilterStyle,
  ChipsStyle,
  AdvanceJobStyle,
  FormRadioStyle,
  EmployersStyle,
  UnAuthPopupStyle,
  JobNoteStyle,
  ExitPopUpStyle,
  SignUpStyle,
  LayoutStyle,
  ApplyBtnStyle,
  CompanyFlexibilityStyle,
  CommonStyle,
  JobVariantDrawerStyle,
  JobBannerStyle,
  TopCategoriesStyle,
  featureJobStyle,
  JobBoxStyle,
  JobDetailStyle,
  RemoteJobChildPageStyle,
  SuccessStoryVariantStyle,
  CompanyDirectoryStyle,
  CompanyByLetterStyle,
  RegisterUserVariantStyle,
  StepBannerStyle,
  LabelCommonStyle,
};

export default SapphireTheme;
