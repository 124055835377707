import { Colors, base_font } from '@styles/sapphire/styled';

const CompanyDirectoryStyle = {
  BgColors: {
    searchCompanies: Colors.blue27,
    searchBtn: Colors.gray1,
    searchDropdown: Colors.white,
    searchDropdownItem: Colors.black,
    viewAllCompanyHeader: Colors.gray10,
    verticalNavigation: Colors.white,
  },
  Colors: {
    searchBtn: Colors.white,
    dotIcon: Colors.gray37,
    viewAllCompanyHeader: Colors.black,
    company: Colors.black,
    inputPlaceholder: Colors.black10,
  },
  BdrColors: {
    searchBtn: Colors.black,
    searchDropdown: Colors.gray37,
    inputSearch: Colors.black,
    line: Colors.black1,
  },
  FontSizes: {
    searchTitle: (base_font * 2.8125).toFixed(1) + 'rem',
    searchTitleMobile: (base_font * 2.5).toFixed(1) + 'rem',
    searchDropdownItem: base_font + 'rem',
    searchIcon: (base_font * 1.5).toFixed(1) + 'rem',
    dotIcon: (base_font * 0.25).toFixed(1) + 'rem',
    viewAllCompanyHeader: (base_font * 1.75).toFixed(1) + 'rem',
    viewAll: base_font + 'rem',
    company: base_font + 'rem',
  },
  FontWeight: {
    searchTitle: 600,
    searchDropdownItem: 600,
    letter: 600,
    viewAllCompanyHeader: 600,
    company: 600,
  },
};

export default CompanyDirectoryStyle;
