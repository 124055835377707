import { Colors, base_font } from '@styles/sapphire/styled';

const CompanyByLetterStyle = {
  BgColors: {},
  Colors: {
    companyByLetterDesc: Colors.black9,
    back: Colors.black,
    dotIcon: Colors.gray37,
    letter: Colors.black,
    letterHover: Colors.black1,
    paginationWrapper: Colors.black10,
    currentPage: Colors.black,
    showingResult: Colors.blue22,
  },
  BdrColors: {
    currentPage: Colors.gray37,
  },
  FontSizes: {
    companyByLetterTitle: (base_font * 1.5).toFixed(1) + 'rem',
    companyByLetterTitleMobile: (base_font * 1.25).toFixed(1) + 'rem',
    companyByLetterDesc: (base_font * 0.875).toFixed(1) + 'rem',
    back: (base_font * 0.875).toFixed(1) + 'rem',
    dotIcon: (base_font * 0.25).toFixed(1) + 'rem',
    letter: (base_font * 1.25).toFixed(1) + 'rem',
    paginationWrapper: (base_font * 0.875).toFixed(1) + 'rem',
    showingResult: (base_font * 0.875).toFixed(1) + 'rem',
    currentPage: (base_font * 0.875).toFixed(1) + 'rem',
  },
  FontWeight: {
    companyByLetterTitle: 500,
    companyByLetterDesc: 500,
    back: 600,
    letter: 600,
    paginationWrapper: 500,
    showingResult: 500,
  },
};

export default CompanyByLetterStyle;
