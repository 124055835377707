import { base_font, Colors, FontWeight } from '@styles/sapphire/styled';

const TopCategoriesStyle = {
  Colors: {
    catTitleColor: Colors.black9,
    categoryListColor: Colors.blue2,
    browseBtnColor: Colors.blue2,
  },
  BgColors: {
    topCategoriesBg: Colors.shadowyBlack8,
    categoryCardsBg: Colors.white,
  },
  BdrColors : {
    categoryBorderColor: Colors.blue2,
  },
  FontSizes: {
    catTitle: (base_font * 2.18).toFixed(1) + 'rem', // 3.5rem
    catTitleSm: base_font * 1.25 + 'rem', //2rem
    list: (base_font * 1.125).toFixed(1) + 'rem', // 1.8rem
    listSm: base_font * 0.875 + 'rem', //1.4rem
    baseFont: base_font + 'rem', //1.6rem
    browseBtn: (base_font * 1.125).toFixed(1) + 'rem', // 1.8rem
    browseBtnSm: base_font + 'rem', //1.6rem
  },
  FontWeight: {
    catListLight: 500,
    browseBtnLight: 500,
    browseBtnSemiBold: FontWeight.semibold, //600
  },
};

export default TopCategoriesStyle;
