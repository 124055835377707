import { createContext, ReactNode, useContext, useState } from 'react';
interface IProps {
  isUserPremium: boolean;
  setIsUserPremium: (_value: boolean) => void;
}
const PremiumUserContext = createContext<IProps | undefined>(undefined);
export const PremiumUserProvider = ({ children }: { children: ReactNode }) => {
  const [isUserPremium, setIsUserPremium] = useState<boolean>(false);
  return (
    <PremiumUserContext.Provider value={{ isUserPremium, setIsUserPremium }}>
      {children}
    </PremiumUserContext.Provider>
  );
};
export const usePremiumUser = () => {
  const context = useContext(PremiumUserContext);
  if (!context) {
    throw new Error('usePremiumUser must be used within a PremiumUserProvider');
  }
  return context;
};
