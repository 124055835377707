import { base_font, Colors } from '@styles/base/styled';

const LabelCommonStyle = {
  Colors: {
    LabelColor: Colors.black,
  },
  FontSizes: {
    labelSmall: (base_font * 0.75).toFixed(1) + 'rem',
    labelMedium: base_font + 'rem',
  },
};

export default LabelCommonStyle;
