import { Colors } from '@styles/base/styled';
import { base_font } from '@styles/sapphire/styled';

const JobBannerStyle = {
  Colors: {
    bannerListing: Colors.blue22,
  },
  FontSizes: {
    title: base_font * 2.85 + 'rem', // 4.5rem
    titleSm: base_font * 1.5 + 'rem', //2.4rem
    list: (base_font * 1.38).toFixed(1) + 'rem', // 2.2rem
    listSm: base_font + 'rem',
  },
  FontWeight: {
    listItemLight: 500,
  },
};

export default JobBannerStyle;
