import { base_font, Colors, FontWeight } from '@styles/sapphire/styled';

const JobBoxStyle = {
  Colors: {
    newText: Colors.orange1,
    jobTitle: Colors.black,
    dateText: Colors.black,
    locationText: Colors.blue22,
    locationIconText: Colors.gray39,
    pillText: Colors.black,
  },
  BgColors: {
    pillBgSM: Colors.blue28,
    pillBg: Colors.gray38,
  },
  BdrColors : {
    cardBorder: Colors.gray42,
  },
  FontSizes: {
    dateText: (base_font * 0.875).toFixed(1) + 'rem', //1.4rem
    newText: (base_font * 0.875).toFixed(1) + 'rem', //1.4rem
    jobTitle: (base_font * 1.25).toFixed(1) + 'rem', // 2rem
    jobTitleSm: (base_font * 1.125).toFixed(1) + 'rem', // 1.8rem
    pillText: (base_font * 0.875).toFixed(1) + 'rem', //1.4rem
    locTextSm: (base_font * 0.875).toFixed(1) + 'rem', //1.4rem
    locIconText: base_font * 0.75 + 'rem', //1.2rem
    locDocText: (base_font * 0.5625).toFixed(1) + 'rem', //0.9rem
  },
  FontWeight: {
    pillLight: FontWeight.medium,
    newSemiBold: FontWeight.semibold,
  },
};

export default JobBoxStyle;
