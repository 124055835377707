import { readCookie } from '@components/common';
import {
  ApiBaseUrl,
  ApiVersion,
  CONFIG_OPTIONS,
  _CONSTANTS,
} from '@components/common/constant';
import { experimentViewedEvent } from '@components/common/event-tracker';
import { ApiEndPoint } from '@components/shared.types';
import { track } from '@utils/eventTrack';
import APIRequest from 'src/lib/baseAPI';
import {
  ABtestData,
  FJPortalExperiments,
  ScopeDevices,
  deviceTypeRegex,
} from './experimentConstants';

interface CustomResponse {
  statusCode?: string;
  data: {
    experimentId: string;
    variant: number;
    variantname?: string;
    name?: string;
    isConducted: boolean;
  } | null;
}

const apiRequest = APIRequest.getInstance();

function GetQueryString(field: string) {
  if (typeof window === 'undefined') return null;
  var href = window.location.href;
  var reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i');
  var string = reg.exec(href);
  return string ? string[1] : null;
}

function createCookie(name: string, value: string, days?: number): void {
  let expires: string = '';
  if (days) {
    const date: Date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + value + expires + '; path=/';
}

export function getCookieValue(
  cookieString: string | undefined,
  key: string
): string | null {
  const cookiesArray: string[] = cookieString?.split('; ') || [];

  for (const cookie of cookiesArray) {
    const [name, value] = cookie.split('=');
    if (name === key) {
      return value;
    }
  }

  return null;
}

export function getExperimentVariant(
  experimentID: string,
  cookieData?: string
): number {
  let variant: number = 0;
  if (experimentID == FJPortalExperiments.fjWizardExperimentSpa.id) {
    return 3;
  }
  const cookieString: string | null = cookieData
    ? getCookieValue(cookieData, _CONSTANTS.CONDUCT_EXPERIMENT)
    : readCookie(_CONSTANTS.CONDUCT_EXPERIMENT) || '{}';

  const runTestCookie: string | null = cookieData
    ? getCookieValue(cookieData, ABtestData.RUNTEST)
    : readCookie(ABtestData.RUNTEST);
  if (runTestCookie) {
    const runTestValue: string[] = runTestCookie.split('_');
    if (runTestValue.length === 2 && runTestValue[0] === experimentID) {
      return parseInt(runTestValue[1]);
    }
  } else {
    const disableTestCookie: string | null = cookieData
      ? getCookieValue(cookieData, ABtestData.DISABLE_TESTS)
      : readCookie(ABtestData.DISABLE_TESTS);
    console.log('disableTestCookie -----', disableTestCookie);
    if (disableTestCookie && disableTestCookie === '1') {
      return 1;
    }
  }

  try {
    const parsedCookie: { [key: string]: { v: number } } = JSON.parse(
      cookieString || '{}'
    );
    console.log('parsedCookie --------  ', parsedCookie);
    if (parsedCookie && parsedCookie[experimentID]) {
      variant = parsedCookie[experimentID].v;
    }
  } catch (error) {
    console.error('Error while getting variant from cookie:', error);
  }

  return variant;
}

interface ExperimentData {
  v: number;
  s: number;
}

export function SyncMixpanel(): void {
  const queryStringExperiment = GetQueryString(ABtestData.RUNTEST);
  const queryStringDisableTest = GetQueryString(ABtestData.DISABLE_TESTS);
  if (queryStringDisableTest !== null) {
    createCookie(ABtestData.DISABLE_TESTS, queryStringDisableTest!);
  }
  if (queryStringExperiment !== null) {
    createCookie(ABtestData.RUNTEST, queryStringExperiment!);
  }
  if (typeof window !== 'undefined') {
    try {
      const cookieData = readCookie(_CONSTANTS.CONDUCT_EXPERIMENT);
      if (cookieData) {
        const parsedCookie: Record<string, ExperimentData> =
          JSON.parse(cookieData);

        Object.keys(parsedCookie).forEach((experimentId) => {
          if (parsedCookie[experimentId].s === 0) {
            parsedCookie[experimentId].s = 1;
          }
        });

        const updatedCookieString = JSON.stringify(parsedCookie);
        createCookie(_CONSTANTS.CONDUCT_EXPERIMENT, updatedCookieString);
      }
    } catch (error) {
      console.error('Error syncing Mixpanel:', error);
    }
  }
}

function getExperimentNameFromLocal(experimentId: string): string | false {
  const experiment = Object.values(FJPortalExperiments).find(
    (exp) => exp.id === experimentId
  );
  return experiment ? experiment.name : false;
}

function getVariantNameFromLocal(
  experimentId: string,
  variantId: string
): string | false {
  const experiment = Object.values(FJPortalExperiments).find(
    (exp) => exp.id === experimentId
  );
  if (
    experiment &&
    experiment.variantNameArr &&
    experiment.variantNameArr[variantId]
  ) {
    return experiment.variantNameArr[variantId];
  } else {
    return false;
  }
}

interface ExperimentDataForTrait {
  experimentId: string;
  variant: number;
}

export function triggerSPAUserExperimentTraits(
  experimentId: string,
  variant: number
) {
  if (typeof window === 'undefined') return;
  const experimentData: ExperimentDataForTrait = { experimentId, variant };
  let expKey =
    'Experiment: ' +
    (getExperimentNameFromLocal(experimentId) ||
      // @ts-ignore
      window.experiment.getExperimentName(experimentData.experimentId)
        .experimentName);
  let expObj: Record<string, string> = {};
  expObj[expKey] =
    getVariantNameFromLocal(experimentId, variant.toString()) ||
    // @ts-ignore
    window.experiment.getVariantName(
      experimentData.experimentId,
      experimentData.variant
    ).variantName;
  track('identify', expObj);
}

export function updateExperimentVariant(
  experimentId: string,
  newVariant: number,
  isSent?: number
): void {
  try {
    if (typeof window === 'undefined') {
      return;
    }
    let updatedCookieString = '';
    const parsedCookie = JSON.parse(readCookie('c_expt') || '{}');
    if (parsedCookie && parsedCookie[experimentId]) {
      parsedCookie[experimentId].v = newVariant;
      parsedCookie[experimentId].s = isSent !== undefined ? isSent : 0;
      updatedCookieString = JSON.stringify(parsedCookie);
    } else {
      const newExperiment = {
        [experimentId]: {
          v: newVariant,
          s: isSent !== undefined ? isSent : 0,
        },
      };

      const mergedCookie = { ...parsedCookie, ...newExperiment };
      updatedCookieString = JSON.stringify(mergedCookie);
    }
    createCookie(_CONSTANTS.CONDUCT_EXPERIMENT, updatedCookieString);
  } catch (error) {
    console.error('Error updating cookie:', error);
  }
}

export const conductExperimentV2API = async (
  UserId: string,
  experimentId: string,
  cookieHeader?: string
) => {
  const apiURL: ApiEndPoint = `/users/${UserId}/experiments/${experimentId}/conduct`;
  const experimentBody: any = {
    logTraits: true,
    conductForOldUsers: true,
    includeIterableIntegration: true,
    culture: 'string',
    skipGoverning: true,
  };
  const response = await apiRequest.post<any>(
    apiURL,
    experimentBody,
    process.env.NEXT_PUBLIC_EB_URL,
    ApiVersion.V2,
    cookieHeader
  );
  return response.data;
};

export const getUsersAllExperimentAndUpdateCookie = async (
  userID: string,
  cookieHeader: string,
  onlyNeedResponse: boolean = false
) => {
  const url: ApiEndPoint = `/users/${userID}/experiments?portalCd=${CONFIG_OPTIONS.portalCD}&status=active`;
  const response: any = await apiRequest.get(
    url,
    cookieHeader || '',
    process.env.NEXT_PUBLIC_EB_URL,
    ApiVersion.V3
  );
  if (response && response.statusCode === 200 && !onlyNeedResponse) {
    processExperiments(response.data);
    createCookie(_CONSTANTS.LAYER_ID, response.data.layer);
  }
  return response.data;
};

function processExperiments(experiments: any): void {
  if (experiments?.experiments && experiments?.experiments.length > 0) {
    experiments?.experiments.forEach((experiment: any) => {
      const experimentUid: string = experiment.experiment_uid;
      const variant: number = experiment.variant;
      updateExperimentVariant(experimentUid, variant, 1);
    });
  }
}

export async function ConductExperiment(
  userId: string,
  experimentObj: any,
  referrer?: any, // need referrer for event-tracking as wizard pages are soft loaded
  userAgentAtSSR?: string,
  cookieHeader?: string
) {
  var {
    experimentId,
    customResponse,
    userCreatedDate,
    exprConductionDate,
    experimentData,
    expData,
  } = getSpaExperimentConductionData(
    experimentObj,
    userAgentAtSSR,
    cookieHeader
  );
  let variant: number = getExperimentVariant(experimentId, cookieHeader);
  if (variant) {
    customResponse.data = {
      experimentId: experimentId,
      variant: variant,
      isConducted: false,
    };
  } else {
    if (isJSExperiment(expData)) {
      // @ts-ignore
      if (!window.experiment || !window.experiment.conductUserExperiment) {
        customResponse.data = {
          experimentId: experimentId,
          variant: 0,
          variantname: 'spa experiment js not found',
          name: 'spa experiment js not found for ' + experimentId,
          isConducted: false,
        };
      } else {
        if (experimentData.isNeedToGetOrConduct) {
          customResponse = handleExperimentNeedConduction(
            expData,
            userId,
            experimentId,
            customResponse
          );
        } else if (experimentData.variant != null) {
          customResponse.data = {
            experimentId: experimentId,
            variant: experimentData.variant,
            isConducted: false,
          };
        }
      }
    } else {
      customResponse = experimentObj.isVisitor
        ? await ConductVisitorBackendExperiment(
            userId,
            experimentObj,
            userAgentAtSSR
          )
        : await ConductExperimentV2(
            userId,
            experimentObj,
            userAgentAtSSR,
            undefined,
            cookieHeader
          );
    }
  }
  // event tracking
  if (customResponse.data && customResponse.data.isConducted === true) {
    if (typeof window !== 'undefined') {
      experimentViewedEvent(
        customResponse.data.experimentId,
        expData?.name,
        customResponse.data.variantname,
        referrer
      );
    }
  }

  return customResponse;
}

function getSpaExperimentConductionData(
  experimentObj: any,
  userAgentAtSSR?: string,
  cookie?: string
) {
  const experimentId = experimentObj.id;
  let experimentData = GetABTestConductionData(
    experimentId,
    false,
    userAgentAtSSR,
    cookie
  );
  let expData = Object.values(FJPortalExperiments).find(
    (m) => m.id == experimentId
  );
  let exprConductionDate = undefined;
  let customResponse: CustomResponse = { data: null };
  let userCreatedDate = '';
  return {
    experimentId,
    customResponse,
    userCreatedDate,
    exprConductionDate,
    experimentData,
    expData,
  };
}

export function GetABTestConductionData(
  experimentId: string,
  stopCookieCreation: boolean = false,
  userAgentAtSSR?: string,
  cookie?: string
) {
  let experimentData: {
    isNeedToGetOrConduct: boolean;
    variant: number | null;
    variantName?: string;
  } = {
    isNeedToGetOrConduct: true,
    variant: null, // variant can be assigned a number or null
  };

  if (experimentId == undefined || experimentId == null || experimentId == '') {
    return experimentData;
  }

  let expData = Object.values(FJPortalExperiments).find(
    (m) => m.id == experimentId
  );
  if (!checkExperimentScope(expData, userAgentAtSSR)) {
    //No need to conduct/Get experiment as it does not follow device sope requirements.
    experimentData = {
      isNeedToGetOrConduct: false,
      variant: 0,
      variantName:
        'experiment not eligible for get or conduction due to country code or device type configuration.',
    };
    return experimentData;
  }
  let disableAbTest = {
    frmQS: GetQueryString(ABtestData.DISABLE_TESTS),
    frmCookie: readCookie(ABtestData.DISABLE_TESTS, cookie),
    writeCookie: false,
  };
  let runAbTests = {
    frmQS: GetQueryString(ABtestData.RUNTEST),
    frmCookie: readCookie(ABtestData.RUNTEST, cookie),
    writeCookie: false,
  };
  let IsDisableTest = disableAbTest.frmQS;
  if (
    (IsDisableTest == null || IsDisableTest == undefined) &&
    disableAbTest.frmCookie != null &&
    disableAbTest.frmCookie != undefined
  ) {
    IsDisableTest = disableAbTest.frmCookie; // means value is in cookie
  } else if (
    IsDisableTest != null &&
    IsDisableTest != undefined &&
    !stopCookieCreation
  ) {
    disableAbTest.writeCookie = true; // means value is in Query String. So write or update Cookie value
  }

  let abTestsValue = runAbTests.frmQS;
  if (
    (abTestsValue == null || abTestsValue == undefined) &&
    runAbTests.frmCookie
  ) {
    abTestsValue = runAbTests.frmCookie; // means value is in cookie
  } else if (abTestsValue && !stopCookieCreation) {
    runAbTests.writeCookie = true; // means value is in Query String. So write or update Cookie value
  }
  if (disableAbTest.writeCookie) {
    createCookie(ABtestData.DISABLE_TESTS, IsDisableTest!);
  }
  if (abTestsValue) {
    if (runAbTests.writeCookie) {
      createCookie(ABtestData.RUNTEST, abTestsValue); // create cookie if needed
    }
    let multiAbtests = abTestsValue.split(',');
    if (multiAbtests && multiAbtests.length > 0) {
      let selectedAbtest = multiAbtests.find(
        (id) => id.toLowerCase().indexOf(experimentId.toLowerCase()) > -1
      );
      if (selectedAbtest) {
        let keyIndexPair = selectedAbtest.split('_');
        if (keyIndexPair && keyIndexPair.length == 2) {
          experimentData.isNeedToGetOrConduct = false;
          experimentData.variant = parseInt(keyIndexPair[1]); //return its case index
          return experimentData;
        }
      }
    }
  }
  if (
    IsDisableTest != null &&
    IsDisableTest != undefined &&
    IsDisableTest == '1'
  ) {
    experimentData.isNeedToGetOrConduct = false; // ABtest is disabled, return 0 case index
    experimentData.variant = null;
    return experimentData;
  }
  return experimentData;
}

function isScopeEligibleForDeviceType(userAgent: string, expScope: string) {
  const scopeDevices: string[] = expScope.split('|');
  let isEligible = false;

  if (
    deviceTypeRegex.mobile.test(userAgent) &&
    scopeDevices.includes(ScopeDevices.mobile)
  ) {
    isEligible = true;
  } else if (
    deviceTypeRegex.desktop.test(userAgent) &&
    !deviceTypeRegex.mobile.test(userAgent) &&
    scopeDevices.includes(ScopeDevices.desktop)
  ) {
    isEligible = true;
  }

  return isEligible;
}

function checkExperimentScope(experimentData: any, userAgentAtSSR?: string) {
  let isEligible = false;
  const expScope = experimentData && experimentData.scope.toLowerCase();
  if (expScope === '') {
    isEligible = true;
  } else if (expScope && expScope.length > 0) {
    let userAgent: string = '';
    if (typeof window !== 'undefined') {
      userAgent = navigator.userAgent.toLowerCase();
    } else if (typeof window === 'undefined' && userAgentAtSSR) {
      userAgent = userAgentAtSSR.toLowerCase();
    }
    isEligible = isScopeEligibleForDeviceType(userAgent, expScope);
  }
  return isEligible;
}

function handleExperimentNeedConduction(
  expData: any,
  userId: string,
  experimentId: string,
  customResponse: CustomResponse
): CustomResponse {
  // let layerUid =
  //   process.env.NEXT_PUBLIC_ENV === 'stg' ||
  //   process.env.NEXT_PUBLIC_ENV === 'prod'
  //     ? '1f0b7a91-de32-44bb-ab4c-e2b71096e8e3'
  //     : '79c392dd-d999-4471-94fc-dd4b2600a2e4';
  let layerUid = readCookie(_CONSTANTS.LAYER_ID);
  // @ts-ignore
  var expResult = window.experiment.conductUserExperiment(
    userId,
    layerUid,
    experimentId
  );
  if (expResult && expResult.statusCode == 'OK') {
    let variantName = '';
    if (expData.variantNameArr) {
      variantName = expData.variantNameArr[expResult.variant];
    }
    if (!variantName) {
      if (expResult.variant == 1) {
        variantName = 'Baseline';
      } else if (expResult.variant == 2) {
        variantName = 'Variability Estimator';
      } else if (expResult.variant > 2) {
        variantName = expData.name + ' - Variation ' + expResult.variant;
      }
    }

    customResponse.data = {
      experimentId: experimentId,
      variant: expResult.variant,
      variantname: variantName,
      isConducted: true,
    };
    updateExperimentVariant(experimentId, expResult.variant);
    triggerSPAUserExperimentTraits(experimentId, expResult.variant);
    SyncMixpanel();
  }
  return customResponse;
}

function isJSExperiment(expData: any) {
  // @ts-ignore
  return expData && expData.isJSExperiment && window.experiment;
}

export async function ConductExperimentV2(
  userId: string,
  experimentObj: any,
  userAgentAtSSR?: string,
  culture: string = 'en-US',
  cookieHeader?: string
): Promise<CustomResponse> {
  const experimentId = experimentObj.id;
  const {
    logTraits = true,
    conductForOldUsers = false,
    includeIterableIntegration = false,
  } = experimentObj;
  let customResponse: CustomResponse = {
    data: null,
    statusCode: '',
  };
  let experimentData = GetABTestConductionData(
    experimentId,
    false,
    userAgentAtSSR
  );
  let expData = Object.values(FJPortalExperiments).find(
    (m) => m.id == experimentId
  );
  if (experimentData.isNeedToGetOrConduct) {
    const experimentDetails = await conductExperimentV2API(
      userId,
      experimentId,
      cookieHeader
    );
    if (experimentDetails && experimentDetails.statusCode === 'ok') {
      updateExperimentVariant(experimentId, experimentDetails.data.variant);
      triggerSPAUserExperimentTraits(
        experimentId,
        experimentDetails.data.variant
      );
      experimentDetails.data.isConducted = true;
      customResponse = experimentDetails;
    }
  } else if (experimentData.variant != null) {
    customResponse.data = {
      experimentId: experimentId,
      variant: experimentData.variant,
      variantname: '',
      isConducted: false,
    };
  }
  return customResponse;
}

export function getUserID(): string | null {
  const cookieDataString = readCookie(_CONSTANTS.USER_STATUS);
  const cookieData = cookieDataString ? JSON.parse(cookieDataString) : null;
  return cookieData?.User?.UserId || null;
}

export function getUserIDServerSide(cookie: string): string | null {
  const cookieDataString = readCookie(_CONSTANTS.USER_STATUS, cookie);
  const cookieData = cookieDataString ? JSON.parse(cookieDataString) : null;
  return cookieData?.User?.UserId || null;
}

export function isGuest(): boolean {
  const cookieDataString = readCookie(_CONSTANTS.USER_STATUS);
  const cookieData = cookieDataString ? JSON.parse(cookieDataString) : null;
  return cookieData?.User?.Role == 0 ? true : false;
}

export function isLayerSet(): boolean {
  const cookieDataString = readCookie(_CONSTANTS.LAYER_ID);
  return cookieDataString ? true : false;
}

export function getVariantForExperimentFromAPI(
  experimentData: any,
  experimentId: string
): number {
  const { experiments } = experimentData;

  for (const experiment of experiments) {
    if (experiment.experiment_uid === experimentId) {
      return experiment.variant;
    }
  }

  return 0;
}

export async function ConductVisitorBackendExperiment(
  visitId: string,
  experimentObj: any,
  userAgentAtSSR?: string,
  culture: string = 'en-US'
): Promise<CustomResponse> {
  const experimentId = experimentObj.id;
  const {
    logTraits = true,
    conductForOldUsers = false,
    includeIterableIntegration = false,
  } = experimentObj;
  let customResponse: CustomResponse = { data: null };
  let experimentData = GetABTestConductionData(
    experimentId,
    false,
    userAgentAtSSR
  );
  let expData = Object.values(FJPortalExperiments).find(
    (m) => m.id == experimentId
  );
  if (experimentData.isNeedToGetOrConduct) {
    const experimentDetails = await ConductVisitorBackendExperimentAPI(
      visitId,
      experimentId
    );
    if (
      experimentDetails &&
      (experimentDetails.statusCode === 'ok' ||
        experimentDetails.statusCode === 'created')
    ) {
      updateExperimentVariant(experimentId, experimentDetails.data.variant);
      triggerSPAUserExperimentTraits(
        experimentId,
        experimentDetails.data.variant
      );
      experimentDetails.data.isConducted = true;
      customResponse = experimentDetails;
      customResponse = {
        data: {
          experimentId:
            customResponse.data?.experimentId ??
            experimentDetails.data.experiment_uid,
          isConducted:
            customResponse.data?.isConducted ??
            experimentDetails.data.isConducted,
          variant:
            customResponse.data?.variant ?? experimentDetails.data.variant,
          variantname:
            customResponse.data?.variantname ?? experimentDetails.data.variant,
        },
      };
    }
  } else if (experimentData.variant != null) {
    customResponse.data = {
      experimentId: experimentId,
      variant: experimentData.variant,
      variantname: experimentData.variantName,
      isConducted: false,
    };
  }
  return customResponse;
}

export const ConductVisitorBackendExperimentAPI = async (
  visitId: string,
  experimentId: string
) => {
  const apiURL: string = `/visitors/${visitId}/experiments/${experimentId}/conduct?skipGoverning=true`;
  const experimentBody: any = {
    logTraits: true,
    conductForOldUsers: true,
    includeIterableIntegration: true,
    culture: 'string',
    skipGoverning: true,
  };
  const response = await apiRequest.post<any>(
    apiURL,
    experimentBody,
    ApiBaseUrl.BASE_URL_EB
  );
  return response.data;
};

export function SearchResultSortExperimentVariantVisitor(
  deviceType: string,
  queryParams: any
): number {
  if (
    queryParams?.eID == 'c6f60743-009d-4908-9660-24301151011a' &&
    queryParams?.vID == '3' &&
    deviceType == 'desktop'
  ) {
    return 3;
  }
  return 0;
}

export const sortByPostedDateCdnExpRunning = (
  deviceType: string | undefined,
  expObj: any,
  query: any,
  sortByPostedDateExpVariant: any
) => {
  let sortingByDate: boolean = false;
  if (
    (deviceType == 'desktop' &&
      query.eID == 'c6f60743-009d-4908-9660-24301151011a' &&
      query.vID == '3') ||
    (deviceType == 'mobile' &&
      query.eID == 'be71cfb0-8518-4970-bb23-52c23edeba67' &&
      query.vID == '3')
  ) {
    sortingByDate = true;
  } else if (
    expObj &&
    expObj[FJPortalExperiments.fjSearchResultSortSubscriberDesktop.id]
  ) {
    const version =
      expObj[FJPortalExperiments.fjSearchResultSortSubscriberDesktop.id].v;
    sortingByDate = version === 3;
  } else if (
    expObj &&
    expObj[FJPortalExperiments.fjSearchResultSortSubscriberMobile.id]
  ) {
    const version =
      expObj[FJPortalExperiments.fjSearchResultSortSubscriberMobile.id].v;
    sortingByDate = version === 3;
  } else if (sortByPostedDateExpVariant == 3) {
    sortingByDate = true;
  }

  return sortingByDate;
};
