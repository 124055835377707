import { base_font, Colors } from '@styles/base/styled';
const JobDetailStyle = {
  Colors: {
    DetailSm: Colors.black,
    DetailTitle: Colors.black,
    DatailJobWrapPara: Colors.black,
    LocationGrp: Colors.black,
    JobDetailTitle: Colors.black,
    JobDetailSubheading: Colors.black,
  },
  BgColors: {
    CardWrapper: Colors.gray1,
  },
  BoxShadowColors: {
    CardWrapper: Colors.black,
  },

  BdrColors: { PageTitleHead: Colors.gray2 },
  FontSizes: {
    dateText: (base_font * 0.56).toFixed(1) + 'rem',
    jobTitleSm: (base_font * 0.8125).toFixed(1) + 'rem',
    locationText: (base_font * 0.8125).toFixed(1) + 'rem',
    pillText: (base_font * 0.56).toFixed(1) + 'rem',
    TitleText: (base_font * 1.5).toFixed(1) + 'rem',
    IconTick: (base_font * 0.75).toFixed(1) + 'rem',
    CardContent: (base_font * 1.25).toFixed(1) + 'rem',
    JobDetailTitle: (base_font * 1.25).toFixed(1) + 'rem',
    JobDetailSubheading: (base_font * 1.25).toFixed(1) + 'rem',
    DetailTitle: (base_font * 0.875).toFixed(1) + 'rem',
    BackArrow: (base_font * 1.125).toFixed(1) + 'rem',
  },
};

export default JobDetailStyle;
