import { Colors, base_font } from '@styles/sapphire/styled';

const RegisterUserVariantStyle = {
  Colors: {
    formWrapperLabel: Colors.black10,
    formWrapperInput: Colors.gray48,
    formWrapperPwdIcon: Colors.gray39,
    existingUser: Colors.black9,
    mobFormHeading: Colors.black9,
    signUpDesc: Colors.black10,
  },
  BgColors: {
    otherOptWrap: Colors.gray39,
    optionTxt: Colors.white,
  },
  FontSize: {
    formWrapperLabel: (base_font * 0.875).toFixed(1) + 'rem',
    mobFormHeading: (base_font * 1.125).toFixed(1) + 'rem',
    formFooterWrap: (base_font * 1.125).toFixed(1) + 'rem',
  },
};

export default RegisterUserVariantStyle;
