interface Experiment {
  id: string;
  name: string;
  isJSExperiment: boolean;
  isVisitor: boolean;
  logTraits: boolean;
  conductForOldUsers: boolean;
  getAfterSwap: boolean;
  scope: string;
  variantNameArr: { [key: string]: string };
  isTrialSubscriber?: boolean;
}

export const ABtestData = {
  DISABLE_TESTS: 'disabletests',
  RUNTEST: 'runtest',
};

interface PortalExperiments {
  [key: string]: Experiment;
}

export const deviceTypeRegex = {
  mobile: /mobile|android|iphone|ipad|ipod|Android|BlackBerry/i,
  desktop: /windows|macintosh|linux|mac os/i,
};

export enum ScopeDevices {
  mobile = 'mobile',
  desktop = 'desktop',
}

export const FJPortalExperiments: PortalExperiments = {
  fjWizardExperimentSpa: {
    id: '2a906cb4-9b19-4c2d-8a26-b1b39bd7b94b',
    name: 'FJ Wizard Funnel SPA Experiment',
    isJSExperiment: true,
    isVisitor: false,
    logTraits: true,
    conductForOldUsers: false,
    getAfterSwap: false,
    scope: '',
    variantNameArr: {
      '1': 'Baseline',
      '2': 'Variability Estimator',
      '3': 'Wizard funnel',
    },
  },
  fjJobTableLocDateDescExperimentMobile: {
    id: 'c81f0adc-4534-4ad6-a249-e24a123168e2',
    name: 'FJ Job Table And Inset ALL Mobile V1 LTV',
    isJSExperiment: false,
    isVisitor: false,
    logTraits: true,
    conductForOldUsers: false,
    getAfterSwap: false,
    scope: 'mobile',
    variantNameArr: {
      '1': 'Baseline',
      '2': 'Variability Estimator',
      '3': 'Location + Date + Detail Page ',
      '4': 'Location + Date + Detail Pop-up ',
    },
  },

  fjSearchResultSortSubscriberDesktop: {
    id: '6ea10197-cf08-4785-acd7-47e524a04301',
    name: 'FXJ Search Result Sort By Date Subscriber All Desktop CTR',
    isJSExperiment: false,
    isVisitor: false,
    logTraits: true,
    conductForOldUsers: true,
    getAfterSwap: false,
    scope: 'desktop',
    variantNameArr: {
      '1': 'Baseline',
      '2': 'Variability Estimator',
      '3': 'V1',
    },
  },
  fjSearchResultSortSubscriberMobile: {
    id: 'f467b29f-9841-4692-901c-1f8725643814',
    name: 'FXJ Search Result Sort By Date Subscriber All Mobile RC',
    isJSExperiment: false,
    isVisitor: false,
    logTraits: true,
    conductForOldUsers: true,
    getAfterSwap: false,
    scope: 'mobile',
    variantNameArr: {
      '1': 'Baseline',
      '2': 'Variability Estimator',
      '3': 'V1',
    },
  },
  fjExpertApplyMobile: {
    id: '59e7b07b-14ed-4a3e-9df4-d43e535cf34b',
    name: 'FJ ExpertApply All Mobile RC1',
    isJSExperiment: false,
    isVisitor: false,
    logTraits: true,
    conductForOldUsers: false,
    getAfterSwap: false,
    scope: 'mobile',
    variantNameArr: {
      '1': 'Baseline',
      '2': 'Variability Estimator',
      '3': 'V1: ExpertApply on FlexJobs',
    },
  },
  fjExpertApplyDesktop: {
    id: 'a24d1970-c858-4c02-ae47-3595a3f29a8e',
    name: 'FJ ExpertApply All Desktop RC1',
    isJSExperiment: false,
    isVisitor: false,
    logTraits: true,
    conductForOldUsers: false,
    getAfterSwap: false,
    scope: 'desktop',
    variantNameArr: {
      '1': 'Baseline',
      '2': 'Variability Estimator',
      '3': 'V1: ExpertApply on FlexJobs',
    },
  },
  fjfiltersMobile: {
    id: '2362a3e3-4b67-474e-987f-7bbef4b7d7aa',
    name: 'FJ Job Detail ABC Filters All Mobile',
    isJSExperiment: false,
    isVisitor: true,
    logTraits: true,
    conductForOldUsers: false,
    getAfterSwap: false,
    scope: 'mobile',
    variantNameArr: {
      '1': 'Baseline',
      '2': 'Variability Estimator',
      '3': 'V1:UI fixes with reg modal',
      '4': 'v2: UI fixes without reg modal',
    },
  },
  fjJobDetailVisitorMobile: {
    id: '79dfda7b-c2f1-4386-aeb2-b24d3bb0f4b4',
    name: 'FXJ Job Detail Page Visitor Mobile CVR',
    isJSExperiment: false,
    isVisitor: true,
    logTraits: true,
    conductForOldUsers: false,
    getAfterSwap: false,
    scope: 'mobile',
    variantNameArr: {
      '1': 'Baseline',
      '2': 'Variability Estimator',
      '3': 'v1: With FJ Header',
      '4': 'v2: Without FJ Header',
    },
  },
  fjRecommendedJobs: {
    id: '1872ace6-ab04-4fd6-a6cf-75839ced3ea5',
    name: 'FXJ JSS Recommended Jobs All ALL CVR V1',
    isJSExperiment: false,
    isVisitor: false,
    logTraits: true,
    conductForOldUsers: false,
    getAfterSwap: false,
    scope: '',
    variantNameArr: {
      '1': 'Baseline',
      '2': 'Variability Estimator',
      '3': 'v1: JSS Recommedded Jobs',
    },
  },
  fjWizardSalaryDesktop: {
    id: '94a5aed7-dec1-45a6-9b28-6190a727e05e',
    name: 'FXJ Wizard Salary Benefits Visitor Desktop CVR',
    isJSExperiment: false,
    isVisitor: true,
    logTraits: true,
    conductForOldUsers: false,
    getAfterSwap: false,
    scope: 'desktop',
    variantNameArr: {
      '1': 'Baseline',
      '2': 'Variability Estimator',
      '3': 'Variant: Add Salary & Benefits',
      '4': 'Variant: New Design',
      '5': 'Variant: New Design with Salary & Benefits',
    },
  },
  fjWizardSalaryMobile: {
    id: 'edd3cfae-1e13-4f8b-b125-2f1371e1327e',
    name: 'FXJ Wizard Salary Benefits All Mobile V1 CVR',
    isJSExperiment: false,
    isVisitor: true,
    logTraits: true,
    conductForOldUsers: false,
    getAfterSwap: false,
    scope: 'mobile',
    variantNameArr: {
      '1': 'Baseline',
      '2': 'Variability Estimator',
      '3': 'Variant: Add Salary & Benefits',
      '4': 'Variant: New Design',
      '5': 'Variant: New Design with Salary & Benefits',
    },
  },
  fjSearchSalaryFilterExperimentDesktop: {
    id: 'f4640352-5863-4001-be06-4788c70c78c8',
    name: 'FXJ Search Results Salary Benefits All Desktop V1 CVR',
    isJSExperiment: false,
    isVisitor: true,
    logTraits: true,
    conductForOldUsers: false,
    getAfterSwap: false,
    scope: '',
    variantNameArr: {
      '1': 'Baseline',
      '2': 'Variability Estimator',
      '3': 'See Salary and Benefits',
      '4': 'Hide Benefits',
    },
  },
  fjSearchSalaryFilterExperimentMobile: {
    id: 'e94ed073-0b02-4def-a815-75e1a2f2d495',
    name: 'FXJ Search Results Salary Benefits All Mobile V1 CVR',
    isJSExperiment: false,
    isVisitor: true,
    logTraits: true,
    conductForOldUsers: false,
    getAfterSwap: false,
    scope: '',
    variantNameArr: {
      '1': 'Baseline',
      '2': 'Variability Estimator',
      '3': 'See Salary and Benefits',
    },
  },
  fjMostPopularSearchesMobile: {
    id: '6b18cc8b-97eb-449b-8764-bada86394aa1',
    name: 'FXJ Display Most Popular Search All Mobile V1 CVR ',
    isJSExperiment: false,
    isVisitor: true,
    logTraits: true,
    conductForOldUsers: false,
    getAfterSwap: false,
    scope: '',
    variantNameArr: {
      '1': 'Baseline',
      '2': 'Variability Estimator',
      '3': 'v1',
    },
  },
  fjMostPopularSearchesDesktop: {
    id: '204310aa-71e6-4aa5-831d-1ff3f93c1eb5',
    name: 'FXJ Display Most Popular Search All Desktop V1 CVR',
    isJSExperiment: false,
    isVisitor: true,
    logTraits: true,
    conductForOldUsers: false,
    getAfterSwap: false,
    scope: '',
    variantNameArr: {
      '1': 'Baseline',
      '2': 'Variability Estimator',
      '3': 'v1',
    },
  },
};
