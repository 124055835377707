import {
  Colors,
  FontFamily,
  FontSizes,
  base_font,
  lineHeight,
} from '@styles/base/styled';

const FilterBtnStyle = {
  Colors: {
    button: Colors.white,
    buttonHover: Colors.black,
    toggleBtn: Colors.black,
    toggleBtnActive: Colors.white,
    link: Colors.black,
    linkHover: Colors.black,
    crossiconcolor: Colors.gray1,
    filterPlaceholder: Colors.gray1,
  },
  BgColors: {
    button: Colors.black,
    buttonHover: Colors.gray1,
    toggleBtn: Colors.white,
    toggleBtnActive: Colors.black,
    dropdownListWrap: Colors.white,
    linkHover: Colors.gray2,
    flyerwrapbg: Colors.white,
    flyerfooterbg: Colors.white,
    filterBdrAda: Colors.gray1,
  },
  BdrColors: {
    button: Colors.white,
    dropdownListWrap: Colors.gray1,
    toggleBtn: Colors.gray1,
    filterBtnShadow: Colors.shadowyBlack,
    filterBdr: Colors.gray2,
  },
  FontSizes: {
    toggleBtn: base_font * 0.8 + 'rem',
    dropdownList: base_font * 0.8 + 'rem',
    reset: base_font * 0.815 + 'rem',
    iconFontSize: FontSizes.h5,
    resetcrossbtn: FontSizes.h6,
    filtertexthead: FontSizes.h6,
    flyercatlist: FontSizes.h6,
    crossIcon: base_font * 0.45 + 'rem',
  },
  FontWeights: {
    iconFWNormal: 400,
    iconFWSemibold: 600,
    crossIcon: 700,
  },
  FontFamilies: {
    iconFontFamily: FontFamily.fontAwesome,
  },
  LineHeight: {
    toggleBtn: lineHeight.xl,
    dropdownList: lineHeight.xl,
  },
};

export default FilterBtnStyle;
