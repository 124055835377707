import { base_font, Colors } from '@styles/sapphire/styled';

const featureJobStyle = {
  Colors: {
    featuredJobTitleColor: Colors.black,
  },
  BgColors: {
    bannerBg: Colors.gray1,
    featuredJobBg: Colors.gray1,
    featuredJobCardsBg: Colors.gray1,
    particularJobCardsBg: Colors.gray1,
  },
  BdrColors : {
    particularJobBorderColor: Colors.black,
    featuredJobBorderColor: Colors.black,
  },
  FontSizes: {
    featuredJobTitle: (base_font * 2.18).toFixed(1) + 'rem', // 3.5rem
    featuredJobTitleSm: base_font * 1.5 + 'rem', //2rem
  },
  FontWeight: {
    medium: 500,
  },
};

export default featureJobStyle;
