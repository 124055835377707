import { Base_Global } from '@license-admin/boldfjcomponents';
import Theme from '@styles/base/theme';
import Base_Font from './base/font.styled';
import Sapphire_Global from './sapphire/global.styled';
import SapphireTheme from './sapphire/theme';
type ThemeConfig = {
  MainTheme: any;
  GlobalCSS: any;
};
function getThemeConfig(theme: string): ThemeConfig {
  switch (theme) {
    case 'sapphire':
      return { MainTheme: SapphireTheme, GlobalCSS: Sapphire_Global };
    case 'wlb':
      return { MainTheme: Theme, GlobalCSS: Base_Global };
    default:
      return { MainTheme: SapphireTheme, GlobalCSS: Sapphire_Global };
  }
}
export function CreateTheme(activeTheme?: any) {
  const getTheme = activeTheme?.toLowerCase() || 'sapphire';
  const themeConfig = getThemeConfig(getTheme);
  return {
    Base_Font,
    ...themeConfig,
  };
}
