import { getLatestActiveDocument } from 'API/ApiHelpers';
import React, { useEffect, useState } from 'react';
import { isAuthUser } from './common';
import { ILoggedInUser } from './shared.types';

interface IGlobalContextProps {
  isUserLoggedIn: boolean;
  UserId: string;
  eaplID: string;
  getUpdateUserDetail: () => void;
  setIsJobWithoutSalary: Function;
  isJobWithoutSalary: boolean;
}

export const GlobalContext = React.createContext<IGlobalContextProps>({
  isUserLoggedIn: false,
  UserId: '',
  eaplID: '',
  getUpdateUserDetail: Function,
  isJobWithoutSalary: true,
  setIsJobWithoutSalary: Function,
});

export const GlobalContextProvider = (props: any): any => {
  const [userDetails, setUserDetails] = useState<ILoggedInUser>({
    IsUserLoggedIn: false,
    User: {
      AccDisplayName: '',
      CreatedOn: '',
      Role: 0,
      UserId: '',
    },
  });

  const [eaplID, setEaplID] = useState<string>('');
  const [isJobWithoutSalary, setIsJobWithoutSalary] = useState(true);

  useEffect(() => {
    if (!props.isLoggedIn) {
      getUpdateUserDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails.IsUserLoggedIn]);

  useEffect(() => {
    const updateUserAndFetchData = async () => {
      if (!props.isLoggedIn) {
        getUpdateUserDetail();
      }

      // Fetch additional data if user is logged in
      if (userDetails.IsUserLoggedIn) {
        const userId = userDetails.User.UserId;
        let storedEaplID =
          localStorage.getItem(`${userId}_EAPL_documentId`) || '';

        if (!storedEaplID) {
          try {
            const { data: activeUserDoc } = await getLatestActiveDocument(
              userId,
              window.document?.cookie || '',
              'EAPL',
              1,
              false,
              undefined,
              undefined,
              true
            );

            if (Array.isArray(activeUserDoc) && activeUserDoc.length > 0) {
              storedEaplID = activeUserDoc[0]?.id || '';
              localStorage.setItem(`${userId}_EAPL_documentId`, storedEaplID);
            }
          } catch (error) {
            console.error('Error fetching active user document:', error);
          }
        }

        if (storedEaplID) {
          setEaplID(storedEaplID);
        }
      }
    };

    updateUserAndFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails.IsUserLoggedIn]);

  const getUpdateUserDetail = (): boolean => {
    const userStatusCookie = isAuthUser();
    setUserDetails(userStatusCookie);
    return userStatusCookie.IsUserLoggedIn;
  };

  return (
    <GlobalContext.Provider
      value={{
        isUserLoggedIn: userDetails.IsUserLoggedIn,
        UserId: userDetails.User.UserId,
        eaplID: eaplID,
        getUpdateUserDetail: getUpdateUserDetail,
        isJobWithoutSalary,
        setIsJobWithoutSalary,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};
