export const size = {
  xsmall: 424,
  small: 576,
  medium: 768,
  large: 992,
  mlarge: 1024,
  extraLarge: 1200,
};

export const device = {
  mobileXSm: `@media screen and (max-width : ${size.xsmall}px)`,
  mobileSm: `@media screen and (max-width : ${size.small}px)`,
  mobileLg: `@media screen and (max-width : ${size.medium - 1}px)`,
  tabletSm: `@media screen and (min-width : ${
    size.medium
  }px) and (max-width : ${size.large - 1}px)`,
  tabletMd: `@media screen and (max-width : ${size.mlarge}px)`,
  tabletLg: `@media screen and (min-width : ${size.large}px) and (max-width : ${
    size.extraLarge - 1
  }px)`,
  mobileTab: `@media screen and (max-width : ${size.large - 1}px)`,
  mobileTabSm: `@media screen and (min-width : ${
    size.small
  }px) and (max-width : ${size.large - 1}px)`,
  mediumScreen: `@media screen and (min-width : ${size.medium}px)`,
  mediumdesktopView: `@media screen and (min-width : ${
    size.extraLarge + 200
  }px)`,
  smallDesktopView: `@media screen and (min-width : ${size.extraLarge}px)`,
  desktopView: `@media screen and (min-width : ${size.extraLarge + 240}px)`,
  largeDesktopView: `@media screen and (max-width : ${
    size.extraLarge + 239
  }px)`,
};

export const width = {
  tablet: `(max-width:${size.large - 1}px)`,
  tabletSm: `(max-width:1024px)`,
};
