import { Colors, base_font } from '@styles/sapphire/styled';

const RemoteJobChildPageStyle = {
  Colors: {
    BannerContentHead: Colors.black9,
    BannerContentPara: Colors.black10,
    CategoryHead: Colors.black9,
    FAQWrapList: Colors.black,
    FAQWrapListLink: Colors.blue2,
    FAQWrapListStrong: Colors.black9,
    FaqHeading: Colors.black9,
    FindJobHeading: Colors.white,
  },
  BgColors: {
    BannerContentWrap: Colors.gray45,
    FaqWrapper: Colors.gray46,
    FindJobBanner: Colors.blue2,
  },
  BdrColors: {
    FAQWrap: Colors.black10,
  },
  FontSize: {
    BannerContentHead: (base_font * 2.5).toFixed(1) + 'rem',
    BannerBtn: (base_font * 1.25).toFixed(1) + 'rem',
    CategoryHead: (base_font * 1.875).toFixed(1) + 'rem',
    FAQWrap: (base_font * 0.875).toFixed(1) + 'rem',
    FAQWrapList: (base_font * 0.875).toFixed(1) + 'rem',
    FaqHeading: (base_font * 2.5).toFixed(1) + 'rem',
    FindJobBannerBtn: (base_font * 1.5).toFixed(1) + 'rem',
    FindJobHeading: (base_font * 2.75).toFixed(1) + 'rem',
  },
};

export default RemoteJobChildPageStyle;
