import {
  isOnlySavedSearchQueryParam,
  localStorageGetItem,
  localStorageSetItem,
} from '@components/common';
import { generateGUID } from '@license-admin/boldfjcomponents';
import { SearchType, ViewType } from './constant';

export const filterObjData = (obj: any) => {
  return Object.fromEntries(
    Object.entries(obj)?.filter(
      ([key, value]) => value !== null && value !== undefined
    )
  );
};

const removeNullFromLocation = (loc: string) => {
  if (loc.includes(',')) {
    const locArr = loc
      .split(', ')
      .filter((str) => str != 'null' && str != '@@@');

    return locArr.join(', ');
  }
  return loc;
};

export const formatDataByViewType = (key: any, viewType: any) => {
  if (viewType == ViewType.Separate_Tab) {
    return key && (key.length > 0 ? key.map((e: any) => e).join(', ') : key[0]);
  }
  return formatKeyArrayData(key);
};

export const formatKeyArrayData = (value: any) => {
  return value && Array.isArray(value) ? value.join(', ') : value;
};

export const formatLocationData = (key: any) => {
  if (key && Array.isArray(key)) {
    const locationValue = key && key.map(removeNullFromLocation).join(', '); // remove null, @@@ from location string
    return locationValue;
  }
  if (typeof key === 'string' && key.includes(',')) {
    return removeNullFromLocation(key);
  } else return key;
};

export const getOrUpdateGUID = () => {
  let isWindow;
  try {
    isWindow = typeof window !== 'undefined' && window.localStorage;
  } catch (error) {
    // Empty catch block
    // This catch block intentionally left empty
  }

  let toReturn = '';
  if (isWindow) {
    let search_guid = localStorageGetItem('search_guid');

    const currentQueryParams = new URLSearchParams(window.location.search);

    const paramToIgnore = ['page'];
    paramToIgnore?.forEach((paramToIgnore) => {
      if (currentQueryParams.has(paramToIgnore)) {
        currentQueryParams.delete(paramToIgnore);
      }
    });

    const currentQueryString = currentQueryParams.toString();

    const storedUrlObject = localStorageGetItem('url_object'); // getting previous url
    let storedPathname, storedQuery;
    if (storedUrlObject) {
      const parsedUrlObj = JSON.parse(storedUrlObject);
      storedPathname = parsedUrlObj.pathname;
      storedQuery = parsedUrlObj.query;
    }

    // updating url_object for current url
    localStorageSetItem(
      'url_object',
      JSON.stringify({
        pathname: window.location.pathname,
        query: currentQueryString,
      })
    );

    if (
      storedQuery === null ||
      storedQuery !== currentQueryString ||
      storedPathname !== window.location.pathname
    ) {
      toReturn = generateGUID();
      localStorageSetItem('search_guid', toReturn);
    } else if (search_guid) {
      toReturn = search_guid;
    }
  }
  return toReturn;
};

export const getGuidValue = (setGuid?: Function | undefined) => {
  const value = getOrUpdateGUID();
  setGuid && setGuid(value);
  return value;
};

export const queryParamsToObject = (url: string): Record<string, string> => {
  const searchParams = new URLSearchParams(url.split('?')[1]);
  const queryParams: Record<string, string> = {};

  searchParams?.forEach((value, key) => {
    queryParams[key] = value;
  });

  return queryParams;
};

export const getViewType = (isHostedJob: any) => {
  return isHostedJob ? ViewType.Separate_Tab : ViewType.Job_Post_Details;
};

export const getSearchType = () => {
  const regex = /suggestedjobs/i;
  const suggestedJobsPage = regex.test(window.location.pathname);
  if (suggestedJobsPage) return null;

  const currentQueryParams = new URLSearchParams(window.location.search);

  if (isOnlySavedSearchQueryParam()) return SearchType.Saved_Search;

  const paramsToDelete = ['usecLocation', 'Loc.LatLng', 'Loc.Radius', 'page'];
  paramsToDelete?.forEach((param: string) => {
    currentQueryParams.delete(param);
  });

  const searchType = currentQueryParams.toString()
    ? SearchType.Detailed_Search
    : SearchType.Basic_Search;

  return searchType;
};
