import { base_font, Colors } from '@styles/sapphire/styled';

const featureJobStyle = {
  Colors: {
    featuredJobTitleColor: Colors.black9,
  },
  BgColors: {
    bannerBg: Colors.blue27,
    featuredJobBg: Colors.shadowyBlack8,
    featuredJobCardsBg: Colors.white,
    particularJobCardsBg: Colors.white,
    JobCardBg: Colors.white,
  },
  BdrColors : {
    particularJobBorderColor: Colors.gray42,
    featuredJobBorderColor: Colors.gray42,
    divider: Colors.gray42,
  },
  FontSizes: {
    featuredJobTitle: (base_font * 2.18).toFixed(1) + 'rem', // 3.5rem
    featuredJobTitleSm: base_font * 1.5 + 'rem', //2rem
  },
  FontWeight: {
    medium: 500,
  },
};

export default featureJobStyle;
