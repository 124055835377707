export const mapQueryParams = (existingQueryParams: any) => {
  if (!existingQueryParams.searchkeyword && existingQueryParams.search) {
    // ! To manager ROR params
    existingQueryParams.searchkeyword = existingQueryParams.search;
  }

  if (!existingQueryParams.joblocations && existingQueryParams.location) {
    // ! To manager ROR params
    existingQueryParams.joblocations = existingQueryParams.location;
  }

  /**
   * ! NOTE:
   *  'search' and 'location' are the query params from ROR pages and we are maping that query params to our query params
   */

  // Remove empty arrays
  for (let key in existingQueryParams) {
    if (
      Array.isArray(existingQueryParams[key]) &&
      existingQueryParams[key].length === 0
    ) {
      delete existingQueryParams[key];
    }
  }

  // Below searchJobtitle|jobScheduleId are the dyanamic page routes
  // As it get's append in router.query
  existingQueryParams = removeItemsFromFilterObj(
    [
      'searchJobtitle',
      'isparttimeentrylevelcomp',
      'rootpage',
      'jobtypesId',
      'jobScheduleId',
      'category',
      'locationJobs',
      'locationRoot',
      'locationCountry',
      'locationChild',
      'searchPage',
      'search',
      'query',
      'queryval',
      'location',
      'eID',
      'vID',
      'runtest',
      'disabletests',
    ],
    existingQueryParams
  );

  const updatedFilters: any = {};
  Object.entries(existingQueryParams)?.forEach(([key, value = '']) => {
    if (value) {
      updatedFilters[key.toLowerCase()] = Array.isArray(value)
        ? value
        : [value];
    }
  });

  return updatedFilters;
};

export const removeItemsFromFilterObj = (keysToRemove: string[], obj: any) => {
  for (const key of keysToRemove) {
    if (key in obj) {
      delete obj[key];
    }
  }
  return obj;
};

type SingleFilterArrayType = {
  [key: string]: Array<string | boolean>;
};

type SelectedFiltersType = SingleFilterArrayType;

export const queryStringManipulation = (
  tempArr: SelectedFiltersType,
  isForApiCall: boolean = false // In some cases, URL needs to be shown on UI. this should be false then
) => {
  let queryString: string = getFilterQueryString(tempArr, isForApiCall) || '';

  // Below searchJobtitle|jobScheduleId are the dyanamic page routes
  queryString = queryString.replace(
    /(\?|&)(company_name|jobfeed|jobtypesId|searchJobtitle|jobScheduleId|category|locationRoot|locationChild)=[^&]*/g,
    ''
  );

  if (queryString.startsWith('&') || queryString.startsWith('?&')) {
    queryString = queryString.replace(/^\?&+/g, '?').replace(/^&+/g, '?');
  }
  return queryString;
};

export const getFilterQueryString = (
  query: object = {},
  isForApiCall: boolean = false
) => {
  let queryString: string = '?';
  Object.entries(query)?.forEach(([key, value]) => {
    // Length check to avoid [''] values
    if (value && value.length && value[0]) {
      Array.isArray(value)
        ? value.map((item: string) => {
            queryString = queryString + `${key}=${encodeURIComponent(item)}&`;
          })
        : (queryString =
            queryString + `${key}=${encodeURIComponent(value || '')}&`);
    }
  });
  return queryString;
};

export const getCDNFilterQueryString = (
  queryString: string,
  paramsToRemove: string[]
): string => {
  let obj: {
    [key: string]: string;
  } = {};

  if (!queryString) return '';

  // Split the query string and populate the object
  queryString.split('&').forEach((pair) => {
    const [key, value] = pair.split('=');
    obj[decodeURIComponent(key)] = decodeURIComponent(value);
  });

  // Remove specified parameters
  paramsToRemove.forEach((param) => {
    delete obj[param];
  });

  // Reconstruct the query string
  const newQueryString = Object.entries(obj)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join('&');

  return newQueryString ? `?${newQueryString}` : '';
};

export function getAnnaulSalaryFilter(obj: any) {
  let annual = {};
  Object.entries(obj).forEach(([key, value]) => {
    if (key.includes('Annually')) {
      annual = {
        ...annual,
        [key]: value,
      };
    }
  });

  return annual;
}
export function getHourlySalaryFilter(obj: any) {
  let hourly = {};
  Object.entries(obj).forEach(([key, value]) => {
    if (key.includes('Hourly')) {
      hourly = {
        ...hourly,
        [key]: value,
      };
    }
  });

  return hourly;
}

export function getSalaryFilterString(input: string) {
  if (!input) {
    return '';
  }

  // Remove the specified words
  const cleanedString = input.replace(/\b(USD|Hourly|Annually)\b/g, '').trim();

  // Replace 'plus' with +
  const plusReplaced = cleanedString.replace(/plus/gi, '+');

  // Add $ prefix to every number in the string
  // This will prefix every sequence of digits, including those within words
  const withDollarSign = plusReplaced.replace(
    /(\d{1,3}(,\d{3})*(\.\d+)?|\d+(\.\d+)?)/g,
    '$$$1'
  );

  // Clean up any extra spaces
  return withDollarSign.replace(/\s+/g, ' ').trim();
}
