import {
  appendQueryParam,
  getAllCookieNames,
  getCookieValue,
  getStateCodeFromName,
  readCookie,
  sortJobCategories,
} from '@components/common';
import {
  CONFIG_OPTIONS,
  LOCATION_LOOKUP_COUNT,
  _CONSTANTS,
  getAllDocumentFilter,
} from '@components/common/constant';
import {
  ApiEndPoint,
  CompanyByAccoladeType,
  EcomStatus,
  IBoldLoggerInfo,
  IConfig,
  IJob,
  IJobCard,
  ILoggedInUser,
  IPageView,
  ISavedSearchItem,
  IUdpateEmailPrefrenceReq,
  LightWeightDoc,
  PreferenceItem,
  SaveJobNoteBody,
} from '@components/shared.types';
import { countriesListData } from 'src/lib/countriesList';
import ApiStore from './ApiStore';

export type MessageResponse = {
  messages: Array<Message>;
};

export type Message = {
  id: string;
  unread: number;
  event: string;
  subject: string;
  body: string;
  created_At: string;
};

export type Event = {
  id: string;
  title: string;
  imageUrl: string;
};

export const getConfigData = async (
  useBlobURL: boolean = false
): Promise<IConfig> => {
  let url = `/config/fjjobs/${process.env.NEXT_PUBLIC_ENV}/prod.json`;
  if (process.env.NODE_ENV) {
    let configFile = process.env.NEXT_PUBLIC_ENV + '.json';
    if (process.env.NODE_ENV === 'development') {
      configFile = 'qa.json';
    }
    url = `/config/fjjobs/${process.env.NEXT_PUBLIC_ENV}/${configFile}`;
  }
  if (useBlobURL) {
    url = process.env.NEXT_PUBLIC_CONTENT_URL + url;
  }
  let response = await ApiStore.EB.getConfig(url, useBlobURL);
  if (process.env.NODE_ENV == 'development') {
    response.configData = await getConfigForLocal(response.configData);
  }
  return response.configData as IConfig;
};

export const getConfigForLocal = async (config: any) => {
  // try {
  //   config = (await import('../../fjcore/settings/flexjobs/config/fjresumeprofile/qa.json')).default;
  // } catch (error) {
  //   console.log(error);
  // }
  return config;
};

export const getPremiumStatus = async (
  userID: string = '',
  isLoggedIn: boolean = false,
  cookieHeader?: string
): Promise<boolean> => {
  if (!userID) {
    return false;
  }
  if (!isLoggedIn) {
    return false;
  }
  //const config: any = await getConfigData(true);
  const url: string = `v1/users/ispremium/${userID}/88`;
  const response = await ApiStore.EB.get(url, cookieHeader || '');
  if (!response?.data) {
    logErrorServer(
      `FXJ FJ_JOBS error in getPremiumStatus.`,
      `Failed to fetch data from api: ${url} userId: ${userID}, response: ${response}`,
      true,
      cookieHeader
    );
    return false;
  }
  return response.data as boolean;
};

export const saveJobSearchApi = async (params: string) => {
  return ApiStore.JOBS.post<any, any>(`v1/jobs/searches${params}`);
};

export const updateSaveJobSearchApi = async (params: string) => {
  return ApiStore.JOBS.put<any, any>(`v1/jobs/search${params}`);
};

export const getRecommendedJobs = async (
  cookieHeader: string,
  profileId: string | undefined,
  page: string,
  onlyExpertApplyJobs: string,
  jobsCount: number = 50,
  capSize: number = 200,
  variant: number
) => {
  let url = `v1/jobs/recommended?jobsCount=${jobsCount}&page=${page}&capping=${true}&capSize=${capSize}${
    profileId ? `&profileId=${profileId}` : ''
  }${variant ? `&variant=${variant}` : ''}`;
  url = appendQueryParam(
    url,
    'IncludeExpertApplyJob',
    onlyExpertApplyJobs
  ) as ApiEndPoint;
  return ApiStore.JOBS.get<IJobCard>(url, cookieHeader);
};

export const getJobsByIds = async (
  ids: (number | string)[],
  cookieHeader: string
) => {
  if (Array.isArray(ids) && ids.length > 0) {
    let url = `v1/jobs?`;
    ids.forEach((id) => {
      url = url + `jobIds=${id}&`;
    });
    url = url + `count=${ids.length}&page=1`;

    return ApiStore.JOBS.get<any>(url, cookieHeader);
  }
  return { data: [] };
};

export const getSavedSearches = async (cookieHeader: string) => {
  return ApiStore.JOBS.get<any>(`v1/jobs/searches/saved`, cookieHeader);
};

export const getSavedSearchById = async (
  searchId: string,
  cookieHeader: string
) => {
  return ApiStore.JOBS.get<any>(
    `v1/jobs/searches?searchID=${searchId}`,
    cookieHeader
  );
};

export const getFollowedCompaniesById = async (
  companyId: string,
  cookieHeader: string
) => {
  return ApiStore.JOBS.get<any>(
    `v1/jobs/company/follow/${companyId}`,
    cookieHeader
  );
};

export const bulkUpdateSaveSearch = async (
  body: Array<ISavedSearchItem>,
  cookieHeader: string
) => {
  return ApiStore.JOBS.patch<any, any>(
    `v1/jobs/searches/bulk`,
    body,
    cookieHeader
  );
};

export const bulkUpdateCompanyFollow = async (
  body: Array<any>,
  cookieHeader: string
) => {
  return ApiStore.JOBS.put<any, any>(
    `v1/jobs/companyfollow/bulk`,
    body,
    cookieHeader
  );
};

export const deleteSaveSearchService = async (searchId: string) => {
  return ApiStore.JOBS.delete<any>(
    `v1/jobs/searches/delete?searchID=${searchId}`
  );
};

export const updateSaveSearchService = async (
  searchId: string,
  searchName: string,
  emailAlert: boolean
) => {
  return ApiStore.JOBS.patch<any, any>(
    `v1/jobs/searches/${searchId}?Name=${searchName}&EmailAlert=${emailAlert}`
  );
};

export const getAllAppliedJobsWithNotes = async (cookieHeader: string) => {
  const appliedJobsRes = await getAppliedJob(cookieHeader);
  if (appliedJobsRes?.success && appliedJobsRes?.data) {
    const jobsNotesPromise = await appliedJobsRes?.data?.results?.map(
      async (elem: IJob) => await getJobNoteById(elem.id, cookieHeader)
    );

    const jobsWithNotes =
      jobsNotesPromise && (await Promise.all(jobsNotesPromise));

    return appliedJobsRes?.data?.results?.map((elem: any, idx: number) => {
      if (
        jobsWithNotes?.[idx]?.success &&
        jobsWithNotes?.[idx]?.data &&
        jobsWithNotes?.[idx]?.data.jobId === elem?.id
      ) {
        return { ...elem, notes: jobsWithNotes[idx].data };
      } else {
        return elem;
      }
    });
  }
};

export const getJobDetails = (jobId: string) => {
  return ApiStore.JOBS.get<any>(`v1/jobs/${jobId}`);
};

export const getFeaturedJobs = (cookieHeader: string, page: number = 1) => {
  const JOB_COUNT = 25;
  return ApiStore.JOBS.get<any>(
    `v1/jobs/featuredjobs?limit=${JOB_COUNT}&page=${page}`,
    cookieHeader
  );
};

export const getAppliedJob = (
  cookieHeader: string,
  size: number = 100,
  duration: number = 0
) => {
  return ApiStore.JOBS.get<any>(
    `v2/jobs/applied?size=${size}&duration=${duration}`,
    cookieHeader
  );
};

export const applyJob = async (jobID: string, applyJobStatus: string) => {
  return ApiStore.JOBS.post<any, any>(
    `v1/jobs/${jobID}/apply?applyjobstatus=${applyJobStatus}`
  );
};

export const pageView = async (body: IPageView, cookieHeader: string = '') => {
  return ApiStore.FJ.post<any, any>(`v1/pageview`, body, cookieHeader);
};

export const deleteAppliedJob = (jobId: string) => {
  return ApiStore.JOBS.delete<any>(`v1/jobs/${jobId}`);
};

export const getAllJobNotes = (cookieHeader: string) => {
  return ApiStore.JOBS.get<any>(`v1/jobs/notes`, cookieHeader);
};

export const getJobNoteById = (jobId: string, cookieHeader?: string) => {
  return ApiStore.JOBS.get<any>(`v1/jobs/${jobId}/note`, cookieHeader);
};

export const getAllJobNoteByIds = (body: any, cookieHeader?: string) => {
  return ApiStore.JOBS.post<any, any>(`v1/jobs/notes`, body, cookieHeader);
};

export const deleteJobNoteById = (jobId: string) => {
  return ApiStore.JOBS.delete<any>(`v1/jobs/${jobId}/note`);
};

export const saveJobNoteService = (
  body: SaveJobNoteBody,
  cookieHeader?: string
) => {
  return ApiStore.JOBS.post(`v1/jobs/note`, body, cookieHeader);
};

export const updateJobNoteService = (
  body: SaveJobNoteBody,
  cookieHeader?: string
) => {
  return ApiStore.JOBS.put(`v1/jobs/note`, body, cookieHeader);
};

export const postSaveJobs = async (
  jobID: string,
  save: boolean,
  cookieHeader?: string
) => {
  return ApiStore.JOBS.post(`v1/jobs/${jobID}/save?save=${save}`, cookieHeader);
};

export const getSavedJobsV2 = (
  cookieHeader?: string,
  continuationToken?: string,
  size?: number
): Promise<any> => {
  const params = new URLSearchParams();
  size && params.append('size', size.toString());
  continuationToken && params.append('continuationToken', continuationToken);
  return ApiStore.JOBS.get<any>(
    'v2/jobs/saved?' + params.toString(),
    cookieHeader
  );
};

export const getViewedJobsV2 = (
  cookieHeader?: string,
  continuationToken?: string,
  size?: number
): Promise<any> => {
  const params = new URLSearchParams();
  size && params.append('size', size.toString());
  continuationToken && params.append('continuationToken', continuationToken);
  return ApiStore.JOBS.get<any>(
    'v2/jobs/viewed?' + params.toString(),
    cookieHeader
  );
};

export const getCompanyGuideDropDowns = async () => {
  return ApiStore.FJ.get<any>(`v1/company/filter_dropdown_list`);
};

export const getUserAddress = async (userId: string, cookieHeader: any) => {
  if (!userId) return null;
  return await ApiStore.EB.get<any>(
    `v1/users/${userId}/addresses`,
    cookieHeader
  );
};

export const getCompaniesHiringInUserState = async (
  userId: string,
  cookieHeader: string
) => {
  const userAddressRes = await getUserAddress(userId, cookieHeader);

  if (userAddressRes?.data?.[0]?.state_province) {
    const stateCode = getStateCodeFromName(
      userAddressRes?.data?.[0]?.state_province
    );
    if (stateCode) {
      return ApiStore.FJ.get<any>(
        `v1/user/hiring-companies?stateCode=${stateCode}`,
        cookieHeader
      );
    }
  }
  return {
    success: false,
    data: {
      state: null,
      stateSlug: null,
      companyShortInfo: [],
    },
  };
};

export const getJobSubcategories = async (slug: any, cookieHeader: string) => {
  return ApiStore.FJ.get<any>(`v1/jobs/subcategory?slug=${slug}`, cookieHeader);
};

export const getJobTitle = async (searchString: string, size: number = 10) => {
  return ApiStore.JOBS.get<any>(
    `v1/jobs/titles/autocomplete?search=${searchString}&size=${size}`
  );
};
export const getCompanyName = async (
  searchString: string,
  size: number = 10
) => {
  return ApiStore.JOBS.get<any>(
    `v1/companies/autocomplete?searchTerm=${searchString}&count=${size}`
  );
};

export const getCategorySuccessStories = async (
  slug: any,
  cookieHeader: string,
  count: number = 3,
  slugType: string = 'Category'
) => {
  return ApiStore.FJ.getWithServerCache<any>(
    `v1/content/success_stories?slug=${slug}&slugType=${slugType}&count=${count}`,
    cookieHeader
  );
};

export const getCompnayDetails = (slug: string) => {
  return ApiStore.JOBS.get<any>(`v1/company/${slug}`);
};

export const reportJob = async (jobId: string, jobObject: any) => {
  return ApiStore.JOBS.post<any, any>(`v1/jobs/${jobId}/flag`, jobObject);
};

export const getAllLocations = async () => {
  return ApiStore.FJ.getWithServerCache<any>(`v1/jobs/locations`);
};

export const getLocation = async (email?: string): Promise<any> => {
  return ApiStore.EB.fetchAndCacheWithExpiration(
    'v1/user/countryclaims',
    email
  );
};

export const getLocationDescriptionBySlug = async (slug: any) => {
  return ApiStore.FJ.get<any>(
    `v1/jobs/location/description?locationSlug=${slug}`
  );
};

export const getAllDocuments = (
  userId: string,
  cookieHeader: string,
  filter: string = getAllDocumentFilter
) => {
  return ApiStore.EB.get<LightWeightDoc[]>(
    `v1/documents/getall?userId=${userId}&portalCD=${CONFIG_OPTIONS.portalCD}&filterResponse=${filter}`,
    cookieHeader
  );
};

export const getLatestActiveDocument = async (
  userID: string,
  cookieHeader: string,
  documentType: string = 'jPFL',
  count: number = 1,
  showDocFiles: boolean = true,
  isActiveDocs: boolean = true,
  sortBy: string = 'dateModified',
  onlyId: boolean = false
) => {
  let url = `/v1/documents/getpaginated?userId=${userID}&portalCD=${CONFIG_OPTIONS.portalCD}&documentType=${documentType}&showDocFiles=${showDocFiles}&isActiveDocs=${isActiveDocs}&sortBy=${sortBy}`;
  if (onlyId) url = url + '&dataShapingFields=id';
  return ApiStore.EB.get<any>(
    // /v1/documents/getpaginated?userId=7a636d1d-208f-4e54-8da0-9d402d7b6b59&portalCD=FXJ&documentType=jPFL&showDocFiles=true&sortBy=dateModified&isActiveDocs=true
    url,
    cookieHeader
  );
};

export const getCountryListService = async (): Promise<any> => {
  let data: any = countriesListData;
  return data ? data : [];
};

export const getJobCategories = async (cookieHeader: string) => {
  const jobCategories = await ApiStore.FJ.getWithServerCache<any>(
    `v1/jobs/categories`,
    cookieHeader
  );
  if (jobCategories.success) {
    sortJobCategories(jobCategories);
  }
  return jobCategories;
};

export const getIgnoredJobs = async (
  memberId: string,
  cookieHeader: string
) => {
  const ignoredJobs = await ApiStore.FJ.get<any>(
    `v1/jobs/ignore?memberId=${memberId}`,
    cookieHeader
  );
  return ignoredJobs;
};

export const ignoreJob = async (
  memberId: string,
  jobId: string,
  cookieHeader: string
) => {
  return ApiStore.FJ.post<any, any>(
    `v1/jobs/ignore?memberId=${memberId}&jobId=${jobId}`,
    undefined,
    cookieHeader
  );
};

export const getLocationLookup = async (searchKeyword: string = '') => {
  return ApiStore.JOBS.get<any>(
    `v1/location/search/${searchKeyword}?take=${LOCATION_LOOKUP_COUNT}`
  );
};

export const logError = async (
  errorMessage: string,
  errorDescription: string,
  logAsInfo: boolean
) => {
  let userStatus = readCookie(_CONSTANTS.USER_STATUS);
  let errorObj = {
    errorMessage: errorMessage,
    componentStack: errorDescription,
    logAsInfo: logAsInfo,
    userStatus: userStatus,
    currentUrl: window.location.href,
    sourceAppCd: process.env.NEXT_PUBLIC_APP_CD,
    productCD: 'FJS',
    portalCD: 'FXJ',
  };
  console.error(errorObj);
  ApiStore.EB.post<any, any>('v2/errors/log', errorObj);
};
export const logErrorServer = async (
  errorMessage: string,
  errorDescription: string,
  logAsInfo: boolean,
  cookie: string = '',
  rawError: string = ''
) => {
  let userStatus = readCookieAPI(_CONSTANTS.USER_STATUS, cookie);
  let errorObj = {
    errorMessage: rawError
      ? `${errorMessage} : rawError ---> ${rawError}`
      : errorMessage,
    componentStack: errorDescription,
    logAsInfo: logAsInfo,
    userStatus: userStatus,
    sourceAppCd: process.env.NEXT_PUBLIC_APP_CD,
    productCD: 'FJS',
    portalCD: 'FXJ',
  };
  process.env.NEXT_PUBLIC_ENV !== 'prod' && console.error(errorObj);
  ApiStore.EB.post<any, any>('v2/errors/log', errorObj);
};

const readCookieAPI = function (name: string, cookie: string) {
  let nameEQ = name + '=';
  if (!cookie && typeof document !== 'undefined') {
    cookie = document.cookie;
  }
  let ca = cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const getUserByEmail = (
  emailAddess: string,
  portalCd: string = '',
  cookieHeader: string = ''
) => {
  const encodedEmail = btoa(emailAddess);
  return ApiStore.EB.get<any>(
    `v1/users/${encodedEmail}/portals/${portalCd}`,
    cookieHeader
  );
};

export const getSavedSearch = (
  savedsearch: string,
  cookieHeader?: string,
  page: number = 1,
  size: number = 50
): Promise<any> => {
  return ApiStore.JOBS.get<any>(
    `v1/jobs/search/${savedsearch}?page=${page}&size=${size}`,
    cookieHeader
  );
};

export const unFollowCompany = (companyId: string): Promise<any> => {
  return ApiStore.JOBS.delete<any>(`/v1/jobs/company/${companyId}/unfollow`);
};

export const getAllFollowedCompany = (
  cookieHeader?: string,
  size: number = 50,
  duration: number = -1,
  continuationToken?: string | null
): Promise<any> => {
  let url = `/v2/jobs/company/follow?size=${size}`;
  if (duration > 0) url += `&duration=${duration}`;
  return ApiStore.JOBS.get<any>(url, cookieHeader);
};

export const checkIfCompanyIsfollowed = async (
  companyId: string,
  cookieHeader?: string
): Promise<any> => {
  return ApiStore.JOBS.get<any>(
    `/v1/jobs/company/${companyId}/follow/status`,
    cookieHeader
  );
};

export const validatePartnerGiftCard = (
  couponCode: string,
  cookieHeader: string = ''
) => {
  return ApiStore.FJ.get<any>(
    `v1/user/getvalidategiftcertificate?code=${couponCode}&isPartner=true`,
    cookieHeader
  );
};

export const agreementCall = async (
  userId: string,
  agreementData: Array<object>,
  portalCD: string
) => {
  if (userId === '00000000-0000-0000-0000-000000000000') {
    throw new Error('Invalid User ID');
  }
  return ApiStore.EB.post<any, any>(
    `v1/user/${userId}/agreement?portalCD=${portalCD}`,
    agreementData
  );
};

export const getAccolades = async () => {
  return ApiStore.JOBS.get<any>(`v1/jobs/accolades/groups`);
};

export const getCompaniesByAccolade = async (id: string) => {
  return ApiStore.FJ.get<CompanyByAccoladeType>(`v1/accolades/${id}/companies`);
};

export const udpateEmailPrefrence = async (req: IUdpateEmailPrefrenceReq) => {
  return ApiStore.EB.post<any, any>(
    `v2/user/optins/${req.userId}/?portalCD=${CONFIG_OPTIONS.portalCD}`,
    req.prefrences
  );
};

export const getEcomFlowData = async (userId: string) => {
  return ApiStore.EB.get<EcomStatus>(
    `v1/user/ecomflowdata?useruId=${userId}&portalId=${CONFIG_OPTIONS.portalID}`
  );
};

export const getRedirectionSlug = async (slug: string) => {
  return ApiStore.FJ.get<any>(`v1/company/slug?from_name=${slug}`);
};

export const updateRORUserOptins = async (memberid: string, data: any = {}) => {
  return ApiStore.FJ.post(`v1/user/notifications?memberId=${memberid}`, data);
};

export const updateUserPreferences = (
  userId: string,
  preferenceCd: string,
  value: string
) => {
  const body = {
    code: preferenceCd,
    value: value,
    created_on: new Date(),
  };
  return ApiStore.EB.post(
    `v1/userpreferences?user_uid=${userId}&portalCd=${CONFIG_OPTIONS.portalCD}`,
    body
  );
};

export const getUserPreferences = async (
  userId: string,
  cookieHeader?: string
) => {
  const response = await ApiStore.EB.get<PreferenceItem[]>(
    `v1/userpreferences/${userId}`,
    cookieHeader || ''
  );
  return response;
};

export const boldLoggerServerSide = async (
  url: string,
  cookieHeader: string,
  payload?: any
): Promise<void> => {
  if (typeof window === 'undefined') {
    try {
      const configData: IConfig = await getConfigData();

      if (
        configData &&
        configData?.boldLoggerServerSide &&
        typeof window === 'undefined'
      ) {
        const infoLogger: IBoldLoggerInfo = {
          url: url,
          payload: payload || '',
          userIp: '',
          cookieNames: getAllCookieNames(cookieHeader),
          visitId: '',
          userId: '',
        };

        const userStatus: ILoggedInUser = getCookieValue(
          _CONSTANTS.USER_STATUS,
          cookieHeader
        );
        const visiorId: string = getCookieValue(_CONSTANTS.VSTR, cookieHeader);

        if (userStatus && userStatus?.User?.UserId) {
          infoLogger.userId = userStatus.User.UserId;
        }
        if (visiorId) {
          infoLogger.visitId = visiorId;
        }
      }
    } catch (error) {
      console.log('Error - ', error);
    }
  }
};

// Data Fallbacks ----------------------------------------------------------
export const jobsV2Fallback = {
  results: [],
  continuationToken: null,
  totalCount: null,
};

// Expert apply endpoints //

export const autoFillExpertApply = (
  expertApplyProfileId: string,
  cookieHeader: string,
  body: any
) => {
  return ApiStore.EA.post<any, any>(
    `v1/expert-apply/profiles/${expertApplyProfileId}/jobs/autofill`,
    body,
    cookieHeader
  );
};

export const getExpertApplyJobStatus = (
  expertApplyProfileId: string,
  cookieHeader: string,
  jobsId: string
) => {
  return ApiStore.EA.get<any>(
    `v1/expert-apply/profiles/${expertApplyProfileId}/jobs/${jobsId}/applications/detail`,
    cookieHeader
  );
};

export const getAllJobsExpertApplyStatus = (
  expertApplyProfileId: string,
  cookieHeader?: string // Optional parameter
) => {
  const cookies =
    cookieHeader ||
    (typeof window !== 'undefined' ? window.document.cookie : '');

  return ApiStore.EA.get<any>(
    `v1/expert-apply/profiles/${expertApplyProfileId}/jobs/applications/detail`,
    cookies
  );
};

// ecom api endpoints //
export const getUserCredits = (userId: string, cookieHeader: string) => {
  return ApiStore.ECOM.get<any>(
    `v1/credits?userUID=${userId}&productCD=EAP`,
    cookieHeader
  );
};

// get backend conducted experiment
export const getUserExperiments = async (
  userId: string,
  experimentId: string,
  cookieHeader?: any
) => {
  return await ApiStore.EB.get<any>(
    `v2/users/${userId}/experiment/${experimentId}`,
    cookieHeader
  );
};
