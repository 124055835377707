import { Colors, base_font, base_font_mb } from '@styles/sapphire/styled';

const FormGroupStyle = {
  BgColors: {
    label: Colors.gray13,
    ErrorMsgWrap: Colors.red2,
  },
  Colors: {
    input: Colors.black,
    floatingLabel: Colors.blue25,
    animateLabel: Colors.blue22,
    passwordMask: Colors.blue2,
    errortext: Colors.red5,
    astrix: Colors.orange5,
    ErrorMsgWrap: Colors.red1,
    labelColor: Colors.black1,
  },
  FontSizes: {
    typeTwoError: base_font_mb + 'rem',
    floatingLabel: base_font_mb + 'rem',
    animateLabel: (base_font * 0.625).toFixed(1) + 'rem',
    passwordMaskText: base_font_mb + 'rem',
  },
  BdrColors: {
    error: Colors.red5,
    label: Colors.black,
    input: Colors.gray1,
    checkboxFocus: Colors.blue12,
    inputReq: Colors.black,
    ErrorMsgWrap: Colors.red3,
  },
};

export default FormGroupStyle;
