import { Colors, base_font } from '@styles/base/styled';

const RegisterUserVariantStyle = {
  Colors: {
    formWrapperLabel: Colors.black,
    formWrapperInput: Colors.gray1,
    formWrapperPwdIcon: Colors.gray1,
    existingUser: Colors.black,
    mobFormHeading: Colors.black,
    signUpDesc: Colors.black,
  },
  BgColors: {
    otherOptWrap: Colors.gray1,
    optionTxt: Colors.white,
  },
  FontSize: {
    formWrapperLabel: (base_font * 0.875).toFixed(1) + 'rem',
    mobFormHeading: (base_font * 1.125).toFixed(1) + 'rem',
    formFooterWrap: (base_font * 1.125).toFixed(1) + 'rem',
  },
};

export default RegisterUserVariantStyle;
