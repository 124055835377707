import {
  getNumFormatter,
  localStorageGetItem,
  localStorageSetItem,
} from '@components/common';
import { getCompaniesHiringCount } from 'API/jobs';
import { jobPostedDateFormatted } from './dateFormatter';

export const getTotalJobsCount = async (jobsCount: number) => {
  const numFormat = getNumFormatter();
  const defaultCount = numFormat.format(1140);
  try {
    if (!jobsCount) return defaultCount;
    if (jobsCount >= 200) return numFormat.format(jobsCount);
    // get the total open positions
    const hiringCount = await getCompaniesHiringCount();
    return numFormat.format(hiringCount.jobsCount);
  } catch (error) {
    return defaultCount;
  }
};

export const addJobWizardCookie = (
  jobWizardCookie: string,
  key: string,
  data: any,
  commute?: string
): string => {
  const parsedCookie = jobWizardCookie ? JSON.parse(jobWizardCookie) : {};
  parsedCookie[key] = data;
  if (commute) {
    parsedCookie['commute'] = commute;
  }
  let secure = ';secure';
  document.cookie =
    'job_wizard' + '=' + JSON.stringify(parsedCookie) + secure + '; path=/;';
  return JSON.stringify(parsedCookie);
};

// export const updateLocalStorageForExperiment = (
//   categoryDetails: any,
//   locationDetails: any
// ) => {
//   const updateObj = {
//     id: categoryDetails.id,
//     name: categoryDetails.categoryTitle,
//     slug: categoryDetails.slug,
//   };
//   let jobsToWizardExperimentObj =
//     parseJSON(
//       localStorageGetItem('jobsToWizardExperiment'),
//       logErrorServer,
//       false,
//       'updateLocalStorageForExperiment',
//       'FJ_JOBS'
//     ) || {};
//   jobsToWizardExperimentObj = {
//     ...jobsToWizardExperimentObj,
//     ...updateObj,
//   };
//   localStorageSetItem(
//     'jobsToWizardExperiment',
//     JSON.stringify(jobsToWizardExperimentObj)
//   );
// };

export const updateJobIdsInLocalStorage = (userId: string, jobId: string) => {
  const localStorageKey = `${userId}_expert_apply_jobIds`;

  const existingJobIds = JSON.parse(
    localStorageGetItem(localStorageKey) || '[]'
  );

  if (!existingJobIds.includes(jobId)) {
    existingJobIds.push(jobId);

    localStorageSetItem(localStorageKey, JSON.stringify(existingJobIds));
  }
};

export const fetchExpertApplyJobStatus = (obj: any = {}) => {
  let response = '';

  // Possible statuses: FAIL, ReadyForReview, ReadyForQuickApply, preparing
  if (obj.success && obj.data) {
    if (obj.data.status === 404) {
      response = ''; // No status found
    } else {
      return obj.data.jobApplicationStatus?.toUpperCase();
    }
  }

  return response;
};

export const transformData = (data: any = {}) => {
  return data?.results?.reduce((acc: any, item: any) => {
    acc[item.jobId] = {
      id: item.id,
      status: item?.jobApplicationStatus?.toUpperCase(),
      appliedDate: jobPostedDateFormatted(item?.createdAt),
    };
    return acc;
  }, {});
};

function convertJobArrayToObject(jobs: any) {
  return jobs?.reduce((acc: any, job: any) => {
    acc[job.id] = {
      jobID: job?.id || '',
      matchID: job?.matchID || '',
      score: job?.score || '',
    };
    return acc;
  }, {});
}

export const convertJobArrayToObjectMatchId = (jobs: any) => {
  return jobs?.reduce((acc: any, job: any) => {
    acc[job?.id] = {
      jobID: job?.id || '',
      matchID: job?.matchID || '',
      score: job?.score || '',
    };
    return acc;
  }, {});
};

export const getJobApplicationStatus = (data: any) => {
  if (
    data &&
    data.createdJobApplicationItems &&
    data.createdJobApplicationItems.length > 0 &&
    data.createdJobApplicationItems[0].createdJobApplication
  ) {
    return data.createdJobApplicationItems[0].createdJobApplication.jobApplicationStatus.toUpperCase();
  }
  return ''; // Return null or a default value if status is not found
};

export const getCDNExpVariantFromCookie = (
  expId: string,
  inputString: string
): string | null => {
  if (!expId || !inputString) {
    return null;
  }

  const entries: string[] = inputString.split(',');

  const matchingEntry = entries.find((entry: string) =>
    entry.startsWith(expId)
  );

  if (!matchingEntry) {
    return null;
  }

  const parts: string[] = matchingEntry.split('_');

  return parts[1] || null;
};

export const findMatchId: any = (jobs = []) => {
  return Array.isArray(jobs)
    ? jobs.find(
        (it: any) =>
          it && typeof it === 'object' && 'matchID' in it && it.matchID
      )
    : {};
};

export const findMatchIdFromRouter: any = (router: any) => {
  return {
    matchID: router?.query?.matchId,
    score: router?.query?.score,
  };
};

export const getExpertApplyValueforApi = (
  expertApplyFilterString: string,
  expertApplyFilterBoolean: boolean
) => {
  // first parameter is the value from query string //
  // second parameter is the value from saves search //
  const onlyExpertApplyJobFilterSelected = expertApplyFilterString === 'true';

  const result = onlyExpertApplyJobFilterSelected || expertApplyFilterBoolean;

  return String(result);
};
