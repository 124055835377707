import { Colors, base_font } from '@styles/base/styled';

const RemoteJobChildPageStyle = {
  Colors: {
    BannerContentHead: Colors.black,
    BannerContentPara: Colors.black,
    CategoryHead: Colors.black,
    FAQWrapList: Colors.black,
    FAQWrapListLink: Colors.black,
    FAQWrapListStrong: Colors.black,
    FaqHeading: Colors.black,
    FindJobHeading: Colors.white,
  },
  BgColors: {
    BannerContentWrap: Colors.gray1,
    FaqWrapper: Colors.gray1,
    FindJobBanner: Colors.black,
  },
  BdrColors: {
    FAQWrap: Colors.black,
  },
  FontSize: {
    BannerContentHead: (base_font * 2.5).toFixed(1) + 'rem',
    BannerBtn: (base_font * 1.25).toFixed(1) + 'rem',
    CategoryHead: (base_font * 1.875).toFixed(1) + 'rem',
    FAQWrap: (base_font * 0.875).toFixed(1) + 'rem',
    FAQWrapList: (base_font * 0.875).toFixed(1) + 'rem',
    FaqHeading: (base_font * 2.5).toFixed(1) + 'rem',
    FindJobBannerBtn: (base_font * 1.5).toFixed(1) + 'rem',
    FindJobHeading: (base_font * 2.75).toFixed(1) + 'rem',
  },
};

export default RemoteJobChildPageStyle;
