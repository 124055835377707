import { AdvanceData, ExperimentViewedEvent } from '@components/shared.types';
import { generateGUID, parseJSON } from '@license-admin/boldfjcomponents';
import { track } from '@utils/eventTrack';
import { logErrorServer } from 'API/ApiHelpers';
import {
  filterObjData,
  formatDataByViewType,
  formatKeyArrayData,
  formatLocationData,
} from 'helper/eventTrackingUtils';
import {
  Action,
  REGISTRATION_EVENTS,
  SearchType,
  TrackingEvents,
  USER_TRAITS_JOBS_COUNT,
  ViewType,
} from 'helper/eventTrackingUtils/constant';
import {
  formatDateFromISO,
  getClientIdSessionId,
  getISO8601DatesDiff,
  localStorageGetItem,
  localStorageSetItem,
  localStorageSetItemExpiry,
  mapIdTONameOfCategories,
  removeNoEducation,
  replaceUSNational,
} from '..';
import { _CONSTANTS, CONFIG_OPTIONS } from '../constant';
import { IJobSearchImpressionsReq } from './eventInterface';

export const addOrGetGUID = (
  guid: string | null | undefined,
  cookieKey: string
): string => {
  const searchCookie = cookieKey;

  let searchIdentifierGUID = generateGUID();

  if (guid) {
    // * if guid -> add to localStorage and pass to return
    searchIdentifierGUID = guid;
    localStorageSetItemExpiry(searchCookie, guid, 3);
  } else {
    const searchIdentifier = localStorageGetItem(searchCookie);
    let searchIdentifierValue = searchIdentifier
      ? JSON.parse(searchIdentifier).value
      : null;

    // * check if we have a localstorageValue
    // ** if not, add generated guid into storage
    if (searchIdentifierValue) {
      searchIdentifierGUID = searchIdentifierValue;
    } else {
      localStorageSetItemExpiry(searchCookie, searchIdentifierGUID, 3);
    }
  }
  return searchIdentifierGUID;
};

const commonProperties = (
  guid: string | null | undefined,
  searchType: string | null,
  queryParams: any,
  searchParamTxt: any,
  dataResultCount: any,
  jobsListWithPagination: any,
  viewType: string | null
) => {
  const searchIdentifierGUID = addOrGetGUID(guid, 'search_identifier');
  const getTotalCount = (jobsListWithPagination: any): string => {
    return jobsListWithPagination ? jobsListWithPagination.totalCount : '0';
  };

  const getDataResultCount = (
    dataResultCount: number,
    jobsListWithPagination: any
  ): string | number => {
    return dataResultCount > 0
      ? dataResultCount
      : getTotalCount(jobsListWithPagination) || '0';
  };
  const searchResultCount = () => {
    if (viewType === ViewType.Separate_Tab) return null;
    if (jobsListWithPagination?.noSearchResult) return '0';
    return getDataResultCount(dataResultCount, jobsListWithPagination);
  };

  const formatValue = (key: string) => formatKeyArrayData(queryParams?.[key]);
  return {
    'search type': searchType,
    keyword: searchParamTxt ? searchParamTxt : queryParams?.searchkeyword,
    location: queryParams && formatLocationData(queryParams.joblocations),
    'remote work level': formatValue('remoteoptions'),
    'job type': formatValue('jobtypes'),
    'work schedule':
      queryParams && formatKeyArrayData(queryParams.jobschedules),
    'career level': queryParams && formatKeyArrayData(queryParams.careerlevel),
    education: formatValue('educations'),
    company: formatValue('companies'),
    accolades: queryParams && formatKeyArrayData(queryParams.accolades),
    categories: formatValue('categories'),
    travel: queryParams && formatKeyArrayData(queryParams.travels),
    title: formatValue('jobtitles'),
    'search results count': searchResultCount(),
    'search identifier': searchIdentifierGUID,
  };
};

export const jobSearchPerformedTrackEvent = (
  searchType: string | null,
  queryParams: { [key: string]: Array<string> | string | undefined },
  searchParamTxt: string | null,
  dataResultCount: number | null,
  guid: string,
  numberOfExpertApplyJobResult?: string | number,
  expertApplyOnlyFilter?: boolean
) => {
  let eventProps: any = {
    ...commonProperties(
      guid,
      searchType,
      queryParams,
      searchParamTxt,
      dataResultCount,
      null,
      null
    ),
    'expert apply only filter': expertApplyOnlyFilter ? 'TRUE' : 'FALSE',
  };

  if (expertApplyOnlyFilter) {
    eventProps['number of expert apply search results'] = dataResultCount;
  }
  if (queryParams.salaryrange) {
    let salaryAmount = Array.isArray(queryParams.salaryrange)
      ? queryParams.salaryrange.join(', ')
      : queryParams.salaryrange;
    eventProps['salary amount'] = salaryAmount.replace(/plus/gi, '+');
    eventProps['salary type'] = calculateSalaryType(
      Array.isArray(queryParams.salaryrange)
        ? queryParams.salaryrange
        : [queryParams.salaryrange]
    );
  }
  if (queryParams.jobswithoutsalary) {
    let jobswithoutsalary = queryParams.jobswithoutsalary;
    if (Array.isArray(queryParams.jobswithoutsalary)) {
      jobswithoutsalary = queryParams.jobswithoutsalary?.[0] || '';
    }
    eventProps['include jobs without salary'] = jobswithoutsalary || '';
  }
  if (queryParams.boostbenefits) {
    eventProps['benefits'] = Array.isArray(queryParams.boostbenefits)
      ? queryParams.boostbenefits.join(', ')
      : queryParams.boostbenefits;
  }

  const eventName = TrackingEvents.Job_Search_Performed;
  if (
    window &&
    window?.document &&
    !window?.document?.referrer.includes('searchOptions')
  ) {
    const event = track(eventName, filterObjData(eventProps));
    return event;
  }
};
const calculateSalaryType = (salaryRange: string[]): string => {
  const hasAnnually = salaryRange.some((item) => item.includes('Annually'));
  const hasHourly = salaryRange.some((item) => item.includes('Hourly'));

  if (hasAnnually && hasHourly) {
    return 'annually, hourly';
  } else if (hasAnnually) {
    return 'annually';
  } else if (hasHourly) {
    return 'hourly';
  }

  return '';
};

export const jobSearchResultTrackEvent = async (
  action: string | null,
  searchType: string | null,
  viewType: string,
  jobDetailsObj: any,
  jobsDataObj?: any,
  queryParams?: any,
  clickOption?: string | null,
  jobNumber?: number | null,
  guid?: string | null
) => {
  let jobDetailsObjCopy = parseJSON(
    JSON.stringify(jobDetailsObj),
    logErrorServer,
    true,
    'jobSearchResultTrackEvent',
    CONFIG_OPTIONS.portalCD
  );
  let jobsDataObjCopy;
  if (jobsDataObj) {
    jobsDataObjCopy = parseJSON(
      JSON.stringify(jobsDataObj),
      logErrorServer,
      true,
      'jobSearchResultTrackEvent',
      CONFIG_OPTIONS.portalCD
    );
  }

  const jobDetails = structuredClone(jobDetailsObjCopy);
  const jobsData = structuredClone(jobsDataObjCopy);
  const jobsListWithPagination = jobsData?.jobs;
  const jobPostCategories = (jobDetails: any) => {
    const categories =
      jobDetails?.categories || jobDetails?.jobCategories || [];
    if (categories.length > 0)
      return categories.map((item: any) => item.name).join(', ');
    return;
  };

  if (queryParams?.categories) {
    queryParams.categories = mapIdTONameOfCategories(
      jobsData,
      Array.isArray(queryParams.categories)
        ? queryParams.categories
        : [queryParams.categories]
    );
  }

  const currentJob = jobsListWithPagination?.results?.filter(
    (job: any) => job.id === jobDetails.id
  )?.[0];

  const obj: any = {
    ...commonProperties(
      guid,
      searchType,
      queryParams,
      null,
      null,
      jobsListWithPagination,
      viewType
    ),
    action: action,
    'view type': viewType,
    'click option': clickOption,
    'screen name': 'job search results',
    'job post id': jobDetails?.id,
    'job post title': jobDetails?.title,
    'job post salary': jobDetails?.salaryRange,
    'job post benefits': formatKeyArrayData(jobDetails?.jobBenefits),
    'job post categories': jobPostCategories(jobDetails),
    'job post company': currentJob?.company?.name,
    'job post company id': currentJob?.company?.companyId,
    'job post job type': formatDataByViewType(jobDetails?.jobTypes, viewType),
    'job post remote work level': formatDataByViewType(
      jobDetails?.remoteOptions,
      viewType
    ),
    'job post location':
      jobDetails?.jobLocations &&
      formatDataByViewType(
        replaceUSNational(jobDetails.jobLocations),
        viewType
      ),
    'job post work schedule': formatDataByViewType(
      jobDetails?.jobSchedules,
      viewType
    ),
    'job post career level': formatDataByViewType(
      jobDetails?.careerLevel,
      viewType
    ),
    'job post education':
      jobDetails?.educationLevels &&
      formatDataByViewType(
        removeNoEducation(jobDetails.educationLevels),
        viewType
      ),
    'job post travel': jobDetails?.travelRequired,
    'job post day ago':
      jobDetails?.postedDate && `${getISO8601DatesDiff(jobDetails.postedDate)}`,
    'job post date':
      jobDetails?.postedDate && formatDateFromISO(jobDetails.postedDate),
    'job post page number': jobsListWithPagination?.currentPage,
    'job post page position': jobsListWithPagination && jobNumber,
    'job post actual position':
      jobsListWithPagination &&
      jobNumber &&
      (jobsListWithPagination.currentPage - 1) *
        jobsListWithPagination.resultPerPage +
        jobNumber,
    'expert apply eligible': jobDetails?.eligibleForExpertApply
      ? 'TRUE'
      : 'FALSE',
  };

  if (currentJob?.score !== undefined && currentJob?.score !== null) {
    obj['solr score'] = `${currentJob.score}`;
  }

  const eventName = TrackingEvents.Job_Search_Result;
  const event = track(eventName, filterObjData(obj));
  return event;
};

export const jobSearchResultsInteractions = (
  action: string,
  click_option?: string,
  save_search_name?: string,
  guid?: string
) => {
  const obj: any = {
    action: action,
    'screen name': 'job search results',
  };
  if (action === 'save search') {
    obj['save search name'] = save_search_name;
    obj['save search identifier'] = guid;
  } else {
    obj['click option'] = click_option;
  }

  const eventName = TrackingEvents.Job_Search_Result_Interactions;
  const event = track(eventName, obj);
  return event;
};

export const jobSearchImpressionsEvent = (item: IJobSearchImpressionsReq) => {
  const eventName = TrackingEvents.Job_Search_Impression;

  const event = track(eventName, item);
  return event;
};

export const exitPopUpJoinNow = (page: string) => {
  const { client_id, session_id } = getClientIdSessionId();

  track('join', {
    event_category: 'Join Now Exit Pop Up',
    event_action: 'Join click',
    event_label: page,
    client_id,
    ga4_id: session_id,
  });
};

export const closePopUpEvent = (eventCategory: string) => {
  const { client_id, session_id } = getClientIdSessionId();

  track('close', {
    event_category: eventCategory,
    client_id,
    ga4_id: session_id,
  });
};

export const genericClickEvent = (event: string, properties: any) => {
  track(event, properties);
};

export const registrationClickEvent = (clickOptions: string) => {
  track(REGISTRATION_EVENTS.event_name, {
    action: 'clicked',
    'click option': clickOptions,
  });
};
type reg_event_details_object = {
  action: string;
  popup_name: string;
  screen_name: string;
  click_option?: string;
  isFromWizardPage?: boolean;
};

export const searchRegModalTrackEvent = (
  event_details: reg_event_details_object
) => {
  if (event_details.isFromWizardPage) {
    track('popup', {
      action: event_details.action,
      'popup name': event_details.popup_name,
      'screen name': event_details.screen_name,
      'click option': event_details.click_option,
    });
  }
};

export const navClickEvents = (
  headerType: string,
  headerName: string,
  subHeaderName: string | null = null
) => {
  track('header nav bar clicks', {
    'header type': headerType,
    header: headerName,
    'sub header': subHeaderName,
  });
};

export const errorEvent = (statusCode: number, fromPage?: string) => {
  let props: any = {};
  if (fromPage) {
    props.fromPage = fromPage;
  }
  track(`${statusCode} error`, props);
};

export const companyPageTrackEvent = (
  company_name: string,
  current_jobs: number
) => {
  const eventProps = {
    action: Action.Viewed,
    'company name': company_name,
    'current jobs': current_jobs,
  };
  track('company page', eventProps);
};

export const experimentViewedEvent: ExperimentViewedEvent = (
  expId,
  expName,
  expVariantName,
  referrer
) => {
  const obj = {
    'experiment id': expId,
    'experiment name': expName,
    'experiment variant': expVariantName,
    referrer: referrer,
  };
  const eventName = TrackingEvents.FJ_Exp_Viewed;
  const event = track(eventName, obj);
  return event;
};

export const wizardDrawerPopupViewedEvent = (screenName: string) => {
  const obj = {
    'popup name': 'wizard drawer',
    action: 'viewed',
    'screen name': screenName,
  };
  const eventName = TrackingEvents.FJ_Pop_Up_Viewed;
  const event = track(eventName, obj);
  return event;
};
export const wizardDrawerPopupClickedEvent = (
  screenName: string,
  clickOption?: string
) => {
  const obj = {
    'popup name': 'wizard drawer',
    action: 'clicked',
    'screen name': screenName,
    'click option': clickOption,
  };
  const eventName = TrackingEvents.FJ_Pop_Up_Clicked;
  const event = track(eventName, obj);
  return event;
};

export const clickEventCTAHeader = () => {
  const obj = {
    'click option': 'find your next remote job',
    'click location': 'top',
  };
  const eventName = REGISTRATION_EVENTS.event_page_click;
  const event = track(eventName, obj);
  return event;
};

export const addJobsCountTraits = (jobsKey: string, increment: number = 1) => {
  const jobCountString = localStorageGetItem(_CONSTANTS.JOBS_COUNT);
  if (jobCountString) {
    const jobCountObj = JSON.parse(jobCountString);

    if (
      jobsKey === USER_TRAITS_JOBS_COUNT.VIEWED_JOBS &&
      jobCountObj[jobsKey] === '100'
    ) {
      // do nothing in case viewed jobs count is 100
    } else if (jobsKey in jobCountObj && !isNaN(jobCountObj[jobsKey])) {
      jobCountObj[jobsKey] = `${parseInt(jobCountObj[jobsKey]) + increment}`;
      localStorageSetItem(_CONSTANTS.JOBS_COUNT, JSON.stringify(jobCountObj));
      track('identify', {
        [jobsKey]: jobCountObj[jobsKey],
      });
    }
  }
};

type TrackEventProp = { [key: string]: any };

const addIfExists = (obj: TrackEventProp, key: string, value: any) => {
  if (value) {
    obj[key] = value;
  }
};
export const advancedSearchEvent = (advanceData: AdvanceData) => {
  let locationArr = [];
  if (advanceData?.USLocation) locationArr.push(advanceData.USLocation);
  if (advanceData?.interNationalLocation)
    locationArr.push(advanceData.interNationalLocation);

  let trackEventProp: TrackEventProp = {
    'search type': SearchType.Advanced_Search,
  };

  addIfExists(trackEventProp, 'keyword', advanceData?.searchkeyword);
  addIfExists(
    trackEventProp,
    'remote work level',
    advanceData?.remoteoptions?.join(', ')
  );
  addIfExists(trackEventProp, 'USLocation', advanceData?.USLocation);
  addIfExists(
    trackEventProp,
    'interNationalLocation',
    advanceData?.interNationalLocation
  );
  addIfExists(trackEventProp, 'job type', advanceData?.jobtypes?.join(', '));
  addIfExists(
    trackEventProp,
    'work schedule',
    advanceData?.jobschedules?.join(', ')
  );
  addIfExists(
    trackEventProp,
    'career level',
    advanceData?.careerlevel?.join(', ')
  );
  addIfExists(trackEventProp, 'education', advanceData?.educations);
  addIfExists(trackEventProp, 'accolades', advanceData?.accolades);
  addIfExists(
    trackEventProp,
    'categories',
    advanceData?.categories?.join(', ')
  );
  addIfExists(trackEventProp, 'travel', advanceData?.travels?.join(', '));
  addIfExists(trackEventProp, 'exclusion words', advanceData?.excludeWords);
  addIfExists(trackEventProp, 'anywhereInUS', advanceData?.anywhereInUS);
  addIfExists(trackEventProp, 'anywhereInWorld', advanceData?.anywhereInWorld);
  addIfExists(trackEventProp, 'location', locationArr.join(', '));

  track(TrackingEvents.Job_Search_Performed, trackEventProp);
};
