const { createGlobalStyle } = require('styled-components');

const blob_path = process.env.NEXT_PUBLIC_CONTENT_URL;
const Base_Font = createGlobalStyle`
    @font-face {
        font-family: halcyon;
        src: url(${blob_path}/fonts/halcyon-regular-webfont.woff2) format('woff2');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: halcyon;
        src: url(${blob_path}/fonts/halcyon-medium-webfont.woff2) format('woff2');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: halcyon;
        src: url(${blob_path}/fonts/halcyon-semibold-webfont.woff2) format('woff2');
        font-weight: 600;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: halcyon;
        src: url(${blob_path}/fonts/halcyon-bold-webfont.woff2) format('woff2');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
    }
`;

export default Base_Font;
