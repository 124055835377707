import {
  Colors,
  FontWeight,
  base_font,
  base_font_mb,
} from '@styles/sapphire/styled';

const JobDetailsStyle = {
  Colors: {
    btn: Colors.black,
    linkActive: Colors.blue2,
    link: Colors.gray6,
    linkDark: Colors.black,
    linkBtn: Colors.white,
    list: Colors.black,
    icon: Colors.orange2,
    iconblue: Colors.blue1,
    approvedHover: Colors.blue3,
    approvedText: Colors.gray25,
    jobDate: Colors.blue22,
    aboutTitle: Colors.blue1,
    previousText: Colors.blue2,
    verifiedInfo: Colors.blue1,
    authour: Colors.black,
    textHighlight: Colors.orange1,
    bannerQuote: Colors.blue1,
    jobTime: Colors.blue22,
    compName: Colors.black1,
  },
  BgColors: {
    wrapper: Colors.white,
    previousText: Colors.white,
    linkBtn: Colors.orange1,
    card: Colors.gray40,
    stickyWrap: Colors.white,
    quoteBox: Colors.blue18,
  },
  BdrColors: {
    wrapper: Colors.gray4,
    tabsWrapper: Colors.gray2,
    link: Colors.blue2,
    imageCard: Colors.gray24,
    wrapperActive: Colors.blue2,
    card: Colors.gray41,
  },
  BoxShadow: {
    card: Colors.gray2,
    cardHover: Colors.gray15,
    previousText: Colors.black5,
    stickyWrapShadow: Colors.gray15,
  },
  FontSize: {
    linkBtn: base_font + 'rem',
    approvedText: (base_font * 0.685).toFixed(1) + 'rem',
    tabfontsm: base_font + 'rem',
    tabfontmd: (base_font * 1.1875).toFixed(1) + 'rem',
    tabfontlg: (base_font * 1.5).toFixed(1) + 'rem',
    jobDate: (base_font * 0.75).toFixed(1) + 'rem',
    jobTitleSm: (base_font * 1.25).toFixed(1) + 'rem',
    aboutTitle: (base_font * 1.12).toFixed(1) + 'rem',
    unlockBannerTitleSm: (base_font * 1.12).toFixed(1) + 'rem',
    previousText: base_font_mb + 'rem',
    verfiedInfo: (base_font * 0.75).toFixed(1) + 'rem',
    authour: (base_font * 0.75).toFixed(1) + 'rem',
    bannerQuote: (base_font * 0.875).toFixed(1) + 'rem',
    jobTime: (base_font * 0.875).toFixed(1) + 'rem',
    saveJobBtn: (base_font * 0.875).toFixed(1) + 'rem',
    unlockBannerTitle: (base_font * 1.625).toFixed(1) + 'rem',
    stickyButtonWrapper: (base_font * 1.125).toFixed(1) + 'rem',
    stickyButtonNewWrapper: (base_font * 1.125).toFixed(1) + 'rem',
    IconTick: (base_font * 0.75).toFixed(1) + 'rem',
    IconTickSm: (base_font * 0.625).toFixed(1) + 'rem',
  },
  FontWeight: {
    linkBtn: FontWeight.medium,
    icon: FontWeight.strong,
    verifiedInfo: 600,
    highlightText: 600,
    quote: 500,
  },
};

export default JobDetailsStyle;
