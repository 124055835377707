import { Colors, FontWeight, base_font } from '@styles/sapphire/styled';

const JobCardStyle = {
  Colors: {
    wrapper: Colors.black,
    iconWrap: Colors.gray9,
    featured: Colors.orange4,
    newJob: Colors.black,
    wrapperActive: Colors.white,
    jobAge: Colors.orange3,
    jobStar: Colors.orange1,
    saveJob: Colors.blue2,
    jobCompany: Colors.blue22,
    mutedtext: Colors.gray22,
    Headquartertxt: Colors.gray6,
    WriteText: Colors.blue2,
    StickyIcon: Colors.blue2,
    applyDate: Colors.blue2,
    trashCan: Colors.blue2,
    stickyIcon: Colors.shadowyBlack3,
    stickySelected: Colors.blue2,
    appDate: Colors.gray6,
    deleteIcon: Colors.blue2,
    notehead: Colors.blue1,
    NoteHead: Colors.black2,
    TextArea: Colors.black,
    colorDark: Colors.black1,
    NoteLabel: Colors.blue2,
    accIcon: Colors.gray39,
    PopUpwrapper: Colors.blue1,
    promoHead: Colors.blue1,
    promoHeadSub: Colors.black2,
    locationSpan: Colors.blue22,
    disabledLink: Colors.blue2,
  },
  BgColors: {
    tagName: Colors.gray38,
    notesWrap: Colors.blue10,
    accIcon: Colors.gray38,
    promoRocketCta: Colors.gray21,
  },
  FontSizes: {
    title: base_font * 1.25 + 'rem',
    titleSm: base_font + 'rem',
    jobAge: (base_font * 0.875).toFixed(1) + 'rem',
    middleWrap: base_font * 0.875 + 'rem',
    companyWrapper: base_font * 0.875 + 'rem',
    trashIcon: (base_font * 0.8125).toFixed(1) + 'rem',
    deleteIcon: (base_font * 0.8125).toFixed(1) + 'rem',
    stickyIcon: (base_font * 1.75).toFixed(1) + 'rem',
    timeIcon: base_font * 0.875 + 'rem',
    editText: (base_font * 0.875).toFixed(1) + 'rem',
    appliedOnText: (base_font * 0.875).toFixed(1) + 'rem',
    accoladeName: (base_font * 0.875).toFixed(1) + 'rem',
    PopUpwrapper: (base_font * 1.375).toFixed(1) + 'rem',
    PopUpwrapperLarge: (base_font * 1.5).toFixed(1) + 'rem',
    jobCompany: (base_font * 1.125).toFixed(1) + 'rem',
    promoContent: (base_font * 0.875).toFixed(1) + 'rem',
    promoHead: (base_font * 1.0625).toFixed(1) + 'rem',
    promoHeadSub: (base_font * 0.875).toFixed(1) + 'rem',
    locationSpan: (base_font * 0.75).toFixed(1) + 'rem',
    shortDescription: (base_font * 0.75).toFixed(1) + 'rem',
    jobCompanyVariant: (base_font * 0.875).toFixed(1) + 'rem',
    tagList: (base_font * 0.75).toFixed(1) + 'rem',
    featured: (base_font * 0.75).toFixed(1) + 'rem',
    newTag: (base_font * 0.75).toFixed(1) + 'rem',
  },
  FontWeight: {
    medium: 500,
    title: FontWeight.semibold,
    jobAge: FontWeight.semibold,
    notesHeader: FontWeight.medium,
    normal: 400,
    bold: 700,
    accoladeName: 500,
  },
  BdrColors: {
    wrapperFeatured: Colors.orange4,
    wrapper: Colors.gray37,
    companyWrapper: Colors.gray3,
    wrapperInActive: Colors.blue2,
    wrapperActive: Colors.blue2,
    borderNotes: Colors.gray3,
    textAreaWrap: Colors.gray3,
    jobListingWrapper: Colors.gray2,
    accoladeImg: Colors.gray37,
    accIcon: Colors.gray37,
    promoRocketCta: Colors.gray37,
    jobStarBorder: Colors.orange1,
  },
};

export default JobCardStyle;
