import {
  BorderRadius,
  Colors,
  FontSizes,
  base_font,
  fontWeight,
} from '@styles/base/styled';

const RegisterUserStyle = {
  Colors: {
    btn: Colors.white,
    subHeading: Colors.gray1,
    mobFormHeading: Colors.black,
  },
  BgColors: {
    bgWhite: Colors.white,
    btn: Colors.black,
    form: Colors.gray2,
  },
  FontSize: {
    btn: FontSizes.h3,
    mobilebtn: FontSizes.h6,
    mobFormHeading: base_font + 'rem',
  },
  FontWeight: {
    label: fontWeight.normal,
    subHeading: fontWeight.medium,
    btn: fontWeight.medium,
  },
  LineHeight: {
    subHeading: base_font * 1.75 + 'rem',
    btn: base_font * 1.75 + 'rem',
  },
  BorderRadius: {
    wrapperBrdr: Colors.shadowyBlack3,
    btn: BorderRadius.small,
  },
  BdrColors: {
    iconWrapBrdr: Colors.gray2,
  },
};

export default RegisterUserStyle;
