import { appendQueryParam, readCookie } from '@components/common';
import {
  ApiBaseUrl,
  ApiVersion,
  CONFIG_OPTIONS,
  Regex,
  _CONSTANTS,
  filterDropOrder,
  getAllDocumentFilter,
} from '@components/common/constant';
import { rorAuthKeys } from '@components/common/constants/ror-api-keys';
import {
  Accolade,
  AccoladeCompany,
  AccoladeWithCompanyList,
  ApiEndPoint,
  CategoryProps,
  CompaniesListByName,
  Company,
  CompanyCategory,
  CompanyDetailRespone,
  CompanyDetailsList,
  FilterType,
  IConfig,
  IJob,
  JobSearchResult,
  SuccessStoriesType,
  SuccessStorySlugType,
} from '@components/shared.types';
import { parseJSON } from '@license-admin/boldfjcomponents';
import { fetchExpertApplyJobStatus } from '@utils/jobsCount';
import {
  getCookieValue,
  sortByPostedDateCdnExpRunning,
} from 'helper/experiments/experimentActions';
import { get } from 'lodash';
import pick from 'lodash/pick';
import cache from 'memory-cache';
import APIRequest from 'src/lib/baseAPI';
import {
  getExpertApplyJobStatus,
  getSavedJobsV2,
  logErrorServer,
} from './ApiHelpers';

const apiRequest = APIRequest.getInstance();

export const getLocalizationFunction = async (
  section: any,
  useBlobURL: boolean = false
): Promise<any> => {
  let url = `/resources/fjjobs/${process.env.NEXT_PUBLIC_ENV}/${
    process.env.NEXT_PUBLIC_PSEUDO_LOCAL == 'true'
      ? 'pseudo_localization.json'
      : 'localization.json'
  }`;
  if (useBlobURL) {
    url = process.env.NEXT_PUBLIC_CONTENT_URL + url;
  }
  const response = await apiRequest.getConfig(url, useBlobURL);
  if (process.env.NODE_ENV == 'development') {
    response.configData = await getLocalizationForLocal(response.configData);
  }
  let localization = response.configData;
  if (section && section != '*') {
    localization = pick(response.configData, section);
  }
  return localization;
};

export const getConfigData = async (
  useBlobURL: boolean = false
): Promise<IConfig> => {
  let url = `/config/fjjobs/${process.env.NEXT_PUBLIC_ENV}/prod.json`;
  if (process.env.NODE_ENV) {
    let configFile = process.env.NEXT_PUBLIC_ENV + '.json';
    if (process.env.NODE_ENV === 'development') {
      configFile = 'qa.json';
    }
    url = `/config/fjjobs/${process.env.NEXT_PUBLIC_ENV}/${configFile}`;
  }
  if (useBlobURL) {
    url = process.env.NEXT_PUBLIC_CONTENT_URL + url;
  }
  let response = await apiRequest.getConfig(url, useBlobURL);
  if (process.env.NODE_ENV == 'development') {
    response.configData = await getConfigForLocal(response.configData);
  }
  return response.configData as IConfig;
};

export const getLocalizationAndConfig = async (
  localizationKey: string | string[] = '*',
  useBlobURL: boolean = false
) => {
  // If localizationKey is not passed, then get all localization
  let [localization, config] = await Promise.all([
    getLocalizationFunction(localizationKey, useBlobURL),
    getConfigData(useBlobURL),
  ]);
  if (localizationKey != '*') {
    localization = get(localization, localizationKey);
  }
  return [localization, config];
};

export const getJobDetails: any = async (
  searchParms: string,
  cookieHeader?: string,
  SortByPostedDate?: boolean,
  customAge: boolean = false,
  reqHeaderObj?: any,
  isSearchPage?: boolean,
  onlyExpertApplyJob: string = 'false',
  getJobStatus: boolean = false,
  isFromWizardPage: boolean = false,
  referralURL?: string,
  jobsWithoutSalary?: string
) => {
  // ! This will work for both GetJobs and GetJobDetails if `search` is present in searchParams it's a search API call
  let apiURL: ApiEndPoint = `/jobs/${searchParms}`;

  apiURL = appendQueryParam(
    apiURL,
    'IncludeExpertApplyJob',
    onlyExpertApplyJob
  ) as ApiEndPoint;

  if (SortByPostedDate) {
    apiURL = appendQueryParam(
      apiURL,
      'SortByPostedDate',
      'true'
    ) as ApiEndPoint;
  }
  if (customAge) {
    apiURL = appendQueryParam(apiURL, 'Age', 14) as ApiEndPoint;
  }

  if (isSearchPage) {
    apiURL = appendQueryParam(apiURL, 'isp', 'true') as ApiEndPoint;
  }
  
  if (jobsWithoutSalary) {
    apiURL = appendQueryParam(
      apiURL,
      'jobsWithoutSalary',
      jobsWithoutSalary
    ) as ApiEndPoint;
  }

  if (getJobStatus) {
    apiURL = appendQueryParam(apiURL, 'jobStatus', 'true') as ApiEndPoint;
  }
  if (isSearchPage) {
    apiURL = isFromWizardPage
      ? (appendQueryParam(apiURL, 'isWizard', 'true') as ApiEndPoint)
      : apiURL;

    apiURL = referralURL
      ? (appendQueryParam(apiURL, 'referralURL', referralURL) as ApiEndPoint)
      : apiURL;
  }

  if (
    process.env.NEXT_PUBLIC_ENV === 'qa' ||
    process.env.NEXT_PUBLIC_ENV === 'reg'
  ) {
    const debugCookie = readCookie('debug_getJobDetails_Api', cookieHeader);
    if (debugCookie !== '') {
      logErrorServer(
        `FXJ FJ_JOBS Client info`,
        `getJobDetails_Api, ${ApiBaseUrl.BASE_URL_RJ}/${ApiVersion.V1}${apiURL}`,
        true
      );
    }
  }
  let response = await apiRequest.get<any>(
    apiURL,
    cookieHeader,
    ApiBaseUrl.BASE_URL_RJ,
    ApiVersion.V1,
    reqHeaderObj
  );
  if (response.statusCode === 500) {
    return {
      statusCode: 500,
      data: {
        jobFilters: [],
        jobs: {
          resultPerPage: 0,
          totalCount: 0,
          currentPage: 1,
          totalPages: 0,
          results: [],
        },
      },
      error: null,
      message: null,
    };
  }
  const currentPage: number = parseInt(response?.data?.jobs?.currentPage);
  const totalPages: number = parseInt(response?.data?.jobs?.totalPages);
  if (!isNaN(currentPage) && !isNaN(totalPages) && currentPage > totalPages) {
    const match = searchParms.match(Regex.getPage);
    if (Array.isArray(match) && match[0] != null) {
      const updatedParams = searchParms?.replace(
        match[0],
        `page=${totalPages}`
      );
      return getJobDetails(updatedParams, cookieHeader);
    }
  }
  // ! add hardCoded categories
  if (response?.data?.jobFilters) {
    const filterDropOrderClean = JSON.parse(JSON.stringify(filterDropOrder));
    delete filterDropOrderClean.Category;
    response.data.jobFilters = [
      ...Object.keys(filterDropOrderClean).map((key) => {
        return {
          name: key,
          categoryWithCount: filterDropOrderClean[key],
        };
      }),
      ...response.data.jobFilters,
    ];
  }

  return response;
};

export const getJobDetailsV2: any = async (
  searchParms: string,
  cookieHeader?: string,
  SortByPostedDate?: boolean,
  customAge: boolean = false,
  reqHeaderObj?: any,
  query?: any,
  deviceType?: string,
  sortByPostedDateExpVariant?: any,
  isSearchPage?: boolean,
  onlyExpertApplyJob?: string,
  getJobStatus: boolean = false,
  isFromWizardPage: boolean = false,
  referralURL?: string,
  jobsWithoutSalary?: string
) => {
  let expObj = getCookieValue(cookieHeader, _CONSTANTS.CONDUCT_EXPERIMENT);
  if (expObj) expObj = expObj ? JSON.parse(expObj) : '';
  let sortByPostedDatCdnExpRunning = false;
  // ! This will work for both GetJobs and GetJobDetails if `search` is present in searchParams it's a search API call
  if (
    !query.sortbyrelevance &&
    sortByPostedDateCdnExpRunning(
      deviceType,
      expObj,
      query,
      sortByPostedDateExpVariant
    )
  ) {
    sortByPostedDatCdnExpRunning = true;
  }
  if (sortByPostedDatCdnExpRunning) {
    SortByPostedDate = true;
  }

  return getJobDetails(
    searchParms,
    cookieHeader,
    SortByPostedDate,
    customAge,
    reqHeaderObj,
    isSearchPage,
    onlyExpertApplyJob,
    getJobStatus,
    isFromWizardPage,
    referralURL,
    jobsWithoutSalary
  );
};

export const getJobDetailsApiUrl: any = (
  searchParms: string,
  cookieHeader?: string,
  SortByPostedDate?: boolean,
  customAge: boolean = false,
  reqHeaderObj?: any,
  isSearchPage?: boolean,
  isFromWizardPage: boolean = false,
  referralURL?: string
) => {
  let apiURL: ApiEndPoint = `/jobs/${searchParms}`;
  apiURL = SortByPostedDate
    ? (appendQueryParam(apiURL, 'SortByPostedDate', 'true') as ApiEndPoint)
    : apiURL;
  apiURL = customAge
    ? (appendQueryParam(apiURL, 'Age', 14) as ApiEndPoint)
    : apiURL;
  apiURL = isSearchPage
    ? (appendQueryParam(apiURL, 'isp', 'true') as ApiEndPoint)
    : apiURL;
  apiURL = isFromWizardPage
    ? (appendQueryParam(apiURL, 'isWizard', 'true') as ApiEndPoint)
    : apiURL;
  apiURL = referralURL
    ? (appendQueryParam(apiURL, 'referralURL', referralURL) as ApiEndPoint)
    : apiURL;

  return apiURL;
};

export const getSavedJobsId = async (
  isPremium: boolean = false,
  cookieHeader: string,
  size?: number
) => {
  if (!isPremium) {
    return [];
  }
  const { data } = await getSavedJobsV2(cookieHeader, undefined, size);
  const bookmarkedJobs = data?.results?.map((e: any) => e.id) || [];
  return bookmarkedJobs;
};

export const getCompanyDetails = async (
  companyParam: string,
  cookieHeader?: any
) => {
  const apiURL: ApiEndPoint = `/company/${companyParam}`;
  const response = await apiRequest.get<any>(
    apiURL,
    cookieHeader,
    ApiBaseUrl.BASE_URL_RJ
  );
  return response;
};

export const getCompanyFlexibility = async (
  companyParam: string,
  cookieHeader: any
) => {
  const apiURL: ApiEndPoint = `/company/${companyParam}/flexiblity-offered`;
  const response = await apiRequest.get<any>(
    apiURL,
    cookieHeader,
    ApiBaseUrl.BASE_URL_CORE
  );
  return response;
};

export const getNews = async () => {
  const apiURL: ApiEndPoint = '/news/sidebar';
  const response = await apiRequest.getWithServerCache<any>(
    apiURL,
    '',
    ApiBaseUrl.BASE_URL_CORE
  );
  return response;
};

export const getSimilarJobs = async (jobId: string | string[]) => {
  const apiURL: ApiEndPoint = `/jobs/simillar/${jobId}`;
  const response = await apiRequest.get<any>(
    apiURL,
    '',
    ApiBaseUrl.BASE_URL_RJ
  );
  return response;
};

export const getUpcomingEvents = async (count: number = 5) => {
  const apiURL: ApiEndPoint = `/news/upcoming_events?eventsCount=${count}`;
  const response = await apiRequest.getWithServerCache<any>(
    apiURL,
    '',
    ApiBaseUrl.BASE_URL_CORE
  );
  return response;
};

export const searchJobsByCompany = async (
  companies: Array<string>,
  searchKeyword?: string,
  page?: any,
  cookieHeader?: string
) => {
  let apiURL = `/jobs/search?`;
  companies.forEach((company) => {
    apiURL += `CompaniesSlug=${company}&`;
  });
  if (searchKeyword)
    apiURL += `SearchKeyword=${searchKeyword}&page=${page ? page : '1'}`;
  else apiURL = `${apiURL}page=${page ? page : '1'}`;

  const response = await apiRequest.get<any>(
    apiURL as ApiEndPoint,
    cookieHeader
  );
  return response;
};

export const getAwards = async () => {
  const apiURL: ApiEndPoint = `/awards`;
  const response = await apiRequest.get<any>(apiURL);
  return response;
};

export const postJobDetails = async (jobID: string, body: any) => {
  const apiURL: ApiEndPoint = `/jobs/${jobID}`;
  const postBody = body;
  const response = await apiRequest.post<any>(apiURL, postBody);
  return response;
};

export const getSavedJobs = async (cookieHeader: string) => {
  const apiURL: ApiEndPoint = `/jobs/saved`;
  const response = await apiRequest.get<any>(
    apiURL,
    cookieHeader,
    ApiBaseUrl.BASE_URL_RJ,
    ApiVersion.V2
  );
  return response;
};

export const saveJobApi = async (
  jobID: string,
  status: boolean,
  body: any = {}
) => {
  const apiURL: ApiEndPoint = `/jobs/${jobID}/save?save=${status}`;
  const postBody = body;
  const response = await apiRequest.post<any>(
    apiURL,
    postBody,
    ApiBaseUrl.BASE_URL_RJ
  );
  return response;
};

export const getJobsByCategories = async (size: number, body: any) => {
  const apiURL: ApiEndPoint = `/categories/jobs?size=${size}`;
  const postBody = body;
  const response = await apiRequest.post<any>(
    apiURL,
    postBody,
    ApiBaseUrl.BASE_URL_RJ
  );
  return response;
};

export const applyJob = async (
  jobID: string,
  applyJobStatus: string,
  body: any = {}
) => {
  const apiURL: ApiEndPoint = `/jobs/${jobID}/apply?applyjobstatus=${applyJobStatus}`;
  const postBody = body;
  const response = await apiRequest.post<any>(apiURL, postBody);
  return response;
};

export const getAppliedJob = async () => {
  const apiURL: ApiEndPoint = `/jobs/applied`;
  const response = await apiRequest.get<any>(apiURL);
  return response;
};

export const getJobsSuggestion = async (searchKeyword: string) => {
  const apiURL: ApiEndPoint = `/jobs/suggest/${searchKeyword}`;
  const response = await apiRequest.get<any>(apiURL);
  return response;
};

export const getJobs = async (apiURL: ApiEndPoint) => {
  const response = await apiRequest.get<any>(apiURL);
  return response;
};

interface Category {
  imgSrc: string;
  name: string;
  slug: string;
}

type LatestCategories = {
  categories: Category[];
};

export const getAllLatestCategories = async (userID: string) => {
  const apiURL: ApiEndPoint = `/jobs/profile/${userID}/categories/latest`;
  const response = await apiRequest.get<LatestCategories>(apiURL);
  return response;
};

// ! Please use API\ApiHelpers.ts\getAllDocuments
export const getAllDocument = async (userID: string) => {
  const apiURL: ApiEndPoint = `/documents/getall?userId=${userID}&portalCD=${CONFIG_OPTIONS.portalCD}&filterResponse=${getAllDocumentFilter}`;
  const response = await apiRequest.get<any>(
    apiURL,
    '',
    ApiBaseUrl.BASE_URL_EB
  );
  return response;
};

export const searchCompanies = async (
  filters: Array<FilterType>,
  page: any,
  cookieHeader: string
) => {
  let keyValueQueryString = filters
    .map((filter) => filter.name + '=' + encodeURIComponent(filter.value) + '&')
    .join('');
  keyValueQueryString = keyValueQueryString.slice(
    0,
    keyValueQueryString.length - 1
  );
  const apiURL: ApiEndPoint = `/companies/search?${keyValueQueryString}${
    page ? `&page=${page}` : ''
  }`;

  const response = await apiRequest.get<any>(apiURL, cookieHeader);
  return response;
};

// ! shifted to localization
// export const getTopCategories = async (cookieHeader?: string) => {
//   const apiURL: ApiEndPoint = `/jobs/categories/top`;
//   const response = await apiRequest.get<any>(apiURL, cookieHeader);
//   return response;
// };

export const flagJob = async (
  jobID: string,
  reason?: string,
  note?: string
) => {
  const apiURL: ApiEndPoint = `/jobs/${jobID}/flag`;
  const postBody = {
    reason: reason,
    note: note,
  };
  const response = await apiRequest.post<any>(apiURL, postBody);
  return response;
};

export const saveJobSearchService = async (params: string) => {
  const apiURL: ApiEndPoint = `/jobs/searches/${params}`;
  const response = await apiRequest.post<any>(apiURL);
  return response;
};

export const jobViewed = async (jobId: string) => {
  const apiURL: ApiEndPoint = `/jobs/${jobId}/view`;
  const response = await apiRequest.post<any>(apiURL, {});
  return response;
};

export const updateUserAddress = async (userId: string, data: any = {}) => {
  const apiURL: ApiEndPoint = `/user/${userId}/address`;
  const response = apiRequest.post<any>(apiURL, data);
  return response;
};

export const getGigCompanyCategories = async (cookieHeader?: string) => {
  const apiURL: ApiEndPoint = `/gigplatform/categories`;
  const response = await apiRequest.get<Array<CompanyCategory>>(
    apiURL,
    cookieHeader,
    ApiBaseUrl.BASE_URL_CORE
  );
  return response;
};

export const getGigCompanies = async (cookieHeader?: string) => {
  const apiURL: ApiEndPoint = `/gigplatform/featured`;
  const response = await apiRequest.get<Array<Company>>(
    apiURL,
    cookieHeader,
    ApiBaseUrl.BASE_URL_CORE
  );
  return response;
};

export const getCompaniesByCategories = async (
  category: any,
  cookieHeader?: string
) => {
  const apiURL: ApiEndPoint = `/gigplatform/category/${category}`;
  const response = await apiRequest.get<Array<Company>>(
    apiURL,
    cookieHeader,
    ApiBaseUrl.BASE_URL_CORE
  );
  return response;
};

export const getGigCompanyDetails = async (
  companyName: any,
  cookieHeader?: string
) => {
  const apiURL: ApiEndPoint = `/gigplatform/${companyName}`;
  const response = await apiRequest.get<CompanyDetailRespone>(
    apiURL,
    cookieHeader,
    ApiBaseUrl.BASE_URL_CORE
  );
  return response;
};

export const getSuccessStories = async (cookieHeader: string) => {
  const apiURL: ApiEndPoint = '/content/sidebar/testimonials';
  const response = await apiRequest.getWithServerCache<
    Array<SuccessStoriesType>
  >(apiURL, cookieHeader, ApiBaseUrl.BASE_URL_CORE);
  Array.isArray(response.data) && (response.data = response.data.slice(0, 7));
  return response;
};

export const getCompanyAccolades = async (
  companySlug: string
): Promise<Accolade[]> => {
  const accolades = await apiRequest.get<Accolade[]>(
    `/accolades/${companySlug}`,
    undefined,
    ApiBaseUrl.BASE_URL_CORE
  );
  return accolades.data;
};

export const getAllAccolades = async (): Promise<AccoladeWithCompanyList[]> => {
  const accolades = await apiRequest.getWithServerCache<
    AccoladeWithCompanyList[]
  >('/accolades', undefined, ApiBaseUrl.BASE_URL_CORE);
  return accolades.data;
};

export const getAccoladesWithCompanyList = async (): Promise<
  AccoladeWithCompanyList[]
> => {
  const accolades = await getAllAccolades();
  const accoladeCompanyPromises = accolades.map((accolade) =>
    apiRequest.getWithServerCache<AccoladeCompany[]>(
      `/accolades/${accolade.id.toString()}/companies`,
      undefined,
      ApiBaseUrl.BASE_URL_CORE
    )
  );
  const allAccoladesCompaniesResponse = await Promise.all(
    accoladeCompanyPromises
  );
  const allAccoladesCompanies = allAccoladesCompaniesResponse.map(
    (a) => a.data
  );
  allAccoladesCompanies.forEach((accoladeCompanies, i) => {
    accolades[i].companies = accoladeCompanies;
  });
  return accolades;
};

export const getCategoryDetails = async (
  slug: any,
  cookieHeader: string = ''
) => {
  const categoryInfo = await apiRequest.get<any>(
    `/jobs/categories/${slug}`,
    cookieHeader,
    ApiBaseUrl.BASE_URL_CORE
  );

  const categoryJobs: CategoryProps = {
    categoryDescription: categoryInfo?.data?.overview,
    categoryTitle: categoryInfo?.data?.title,
    slug: slug,
    id: categoryInfo?.data?.id,
  };
  return categoryJobs;
};

export const getLocationDetails = async (query: any, cookieHeader: string) => {
  let queryString;
  if (query.category && query.locationRoot && query.locationChild) {
    queryString = `?locationSlug=${query.locationChild}&stateCode=${query.locationRoot}&`;
  } else if (query.category.toLowerCase() === 'world') {
    queryString = `?locationSlug=${query.locationRoot}&isnonUSAState=true`;
  } else if (query.category && query.locationRoot) {
    queryString = `?locationSlug=${query.locationChild ?? query.locationRoot}&`;
  }

  const locationInfo = await apiRequest.get<any>(
    `/jobs/location/description${queryString}`,
    cookieHeader,
    ApiBaseUrl.BASE_URL_CORE
  );

  const categoryJobs: any = {
    categoryDescription: locationInfo?.data?.description ?? null,
    categoryTitle: locationInfo?.data?.slug
      ? locationInfo?.data?.slug?.replace(/\./g, '')
      : null,
    slug:
      locationInfo?.data?.slug ??
      query.locationChild ??
      query.locationRoot ??
      null,
  };
  return categoryJobs;
};

export const getCategoryDetailsBySlug = async (
  slug: any,
  cookieHeader: string
) => {
  const details = await apiRequest.get<any>(
    `/jobs/categories/${slug}`,
    cookieHeader,
    ApiBaseUrl.BASE_URL_CORE
  );
  return details.data;
};

export const getLocalizationForLocal = async (localization: any) => {
  // try {
  //   localization = (
  //     await import(
  //       '../../fjcore/settings/flexjobs/resources/fjjobs/localization.json'
  //     )
  //   ).default;
  // } catch (error) {
  //   console.log(error);
  // }
  return localization;
};

export const getConfigForLocal = async (config: any) => {
  // try {
  //   config = (await import('../../fjcore/settings/flexjobs/config/fjjobs/qa.json')).default;
  // } catch (error) {
  //   console.log(error);
  // }
  return config;
};

// ! Please use API\ApiHelpers.ts\getLatestResume
export const getLastUpdatesresume = async (
  cookieHeader: string,
  userId: string
): Promise<any> => {
  const configData: IConfig = await getConfigData();
  const apiResult = await apiRequest.get<JobSearchResult>(
    `/documents/lastupdatedresume?userId=${userId}&portalCD=${configData.portalCD}&isSignUpFlow=false&documentType=jPFL`,
    cookieHeader,
    process.env.NEXT_PUBLIC_EB_URL
  );
  return apiResult;
};

export const getPremiumStatus = async (
  userID: string,
  isLoggedIn: boolean = false,
  cookieHeader?: string
): Promise<any> => {
  if (!isLoggedIn) {
    return false;
  }

  try {
    //const config: any = await getConfigData(true);
    const url: ApiEndPoint = `/users/ispremium/${userID}/88`;
    const response = await apiRequest.get(
      url,
      cookieHeader || '',
      ApiBaseUrl.BASE_URL_EB
    );

    if (!response?.data) {
      logErrorServer(
        `FXJ FJ_JOBS error in getPremiumStatus.`,
        `Failed to fetch data from api: ${url} userId: ${userID}`,
        true,
        cookieHeader
      );
      return false;
    }
    return response.data;
  } catch (error: any) {
    logErrorServer(
      `FXJ FJ_JOBS error in getPremiumStatus.`,
      error.message,
      true,
      cookieHeader
    );
  }
};

export const getSuccessStoriesTab = async (
  slug: string,
  type: SuccessStorySlugType,
  count: number = 3
): Promise<any> => {
  const successStories = await apiRequest.getWithServerCache<any>(
    `/content/success_stories?slug=${slug}&slugType=${type}&count=${count.toString()}`,
    undefined,
    ApiBaseUrl.BASE_URL_CORE
  );
  return successStories.data;
};

export const getCompaniesHiringCount = async (): Promise<any> => {
  try {
    let cachedJobsCount = cache.get('JOBS_COUNT');
    if (cachedJobsCount) {
      return parseJSON(
        cachedJobsCount,
        logErrorServer,
        true,
        'getCompaniesHiringCount',
        'FJ_JOBS'
      );
    }
    const companiesHiringInfo = await apiRequest.get<any>(`/jobs/count`);
    if (
      companiesHiringInfo?.data?.jobsCount &&
      companiesHiringInfo?.data?.companiesCount
    ) {
      cache.put(
        'JOBS_COUNT',
        JSON.stringify(companiesHiringInfo.data),
        60 * 60 * 1000
      );
    }
    return companiesHiringInfo.data;
  } catch (err) {
    return {
      jobsCount: 36017,
      companiesCount: 5356,
    };
  }
};

type CompanyDataType = {
  id: number;
  name: string;
  slug: string;
  logo: string;
  emailAlert: boolean;
  followName: string;
};

export const followCompany = async (companyData: CompanyDataType) => {
  const response = await apiRequest.post<any>(
    `/jobs/company/follow`,
    companyData,
    ApiBaseUrl.BASE_URL_RJ
  );
  return response.data;
};

export const getFollowedCompaniesByMemberId = async (
  cookieHeader: string,
  memebrId: number,
  count: number = 5
) => {
  const followedComapnies = await apiRequest.get<any>(
    `/user/followed-companies?memebrId=${memebrId}&count=${count}`,
    cookieHeader,
    ApiBaseUrl.BASE_URL_CORE
  );
  return followedComapnies.data;
};

export const getRelatedArticles = async (
  count: number = 5,
  companySlug?: string
) => {
  const relatedArticles = await apiRequest.get<any>(
    `/blog/related-articles?${
      companySlug ? `companySlug=${companySlug}&` : ' '
    }count=${count}`,
    undefined,
    ApiBaseUrl.BASE_URL_CORE
  );
  return relatedArticles.data;
};

export const getMembersCompanyPageData = async (
  cookieHeader: string,
  categorySlug: string
) => {
  const membersCompanyPageData = await apiRequest.get<any>(
    `/company/research_companies?categorySlug=${categorySlug}`,
    cookieHeader,
    ApiBaseUrl.BASE_URL_CORE
  );
  return membersCompanyPageData.data;
};

export const getCompaniesByKeyword = async (
  payload: {
    filterParam: string;
    pageNumber: number;
    pageSize: number;
    isFreeText: boolean;
    isPremium: boolean;
    isRegistered: boolean;
  },
  filterBy: string = 'Unknown',
  clearCookie: boolean = false
): Promise<any> => {
  const companies: any = await apiRequest.postWithCache(
    `/company/search?filterBy=${filterBy}`,
    payload,
    ApiBaseUrl.BASE_URL_CORE,
    ApiVersion.V1,
    clearCookie
  );
  const currentPage = companies?.data?.currentPage;
  const totalPages = companies?.data?.totalPages;
  if (!isNaN(currentPage) && !isNaN(totalPages) && currentPage > totalPages) {
    payload.pageNumber = totalPages;
    return getCompaniesByKeyword(payload, filterBy);
  }

  return companies.data;
};

export const getAllCompaniesByName = async (
  slugs: CompaniesListByName[],
  cookieHeader: string,
  allowNumCompanies?: boolean,
  count?: number,
  isBrowseCompanies?: boolean
): Promise<any> => {
  let apiURL: ApiEndPoint = `/company/companiesforeachalphabet`;
  if (count) {
    apiURL = appendQueryParam(apiURL, 'count', count) as ApiEndPoint;
  }

  if (allowNumCompanies) {
    apiURL = appendQueryParam(
      apiURL,
      'includeCompaniesStartingWithNumber',
      'true'
    ) as ApiEndPoint;
  }
  const companies = await apiRequest.get<any>(
    apiURL,
    cookieHeader,
    ApiBaseUrl.BASE_URL_CORE
  );

  type CompanyType = {
    id: number;
    logo?: string;
    name: string;
    slug: string;
  };
  return slugs.map((slug) => {
    slug.subCategory = [];
    companies.data[slug.category.keyword]?.map((r: CompanyType) => {
      slug.category.slug = isBrowseCompanies
        ? `/browse-companies/${slug.category.relativeSlug}`
        : `/jobs/telecommuting-company-jobs/${slug.category.relativeSlug}`;
      slug.subCategory?.push({
        name: r.name,
        slug: `/remote-jobs/company/${r.slug}`,
      });
    });
    if (isBrowseCompanies) {
      slug.subCategory.push({
        name: 'More...',
        slug: `/browse-companies/${slug.category.relativeSlug}`,
      });
    }
    return slug;
  });
};

export const getSuggestedJobs = async (
  profileId: string,
  member_id: string,
  page: number = 1
) => {
  const response = await apiRequest.get<any>(
    `/get_suggested_jobs?member_id=${member_id}&setprofile=${profileId}&page=${page}`,
    undefined,
    process.env.NEXT_PUBLIC_ROR_URL,
    ApiVersion.V1,
    rorAuthKeys
  );
  if (response.error) return [];

  return response.data;
};

export const getCompanyListByAccolades = async (
  accoladeId: string,
  count: number = 100
): Promise<CompanyDetailsList[]> => {
  const companies = await apiRequest.get<CompanyDetailsList[]>(
    `/accolades/${accoladeId}/companies?count=${count}`,
    undefined,
    ApiBaseUrl.BASE_URL_CORE
  );
  return companies.data;
};

export const getFirstJobDetails = async (
  firstJob: IJob,
  cookieHeader: string,
  isPremiumUser: boolean
) => {
  if (!isPremiumUser) {
    return {
      firstJobDetails: {},
      firstJobComDetails: {},
    };
  }
  const firstJobID = firstJob?.id;
  const firstCompanySlug = firstJob?.company?.slug;

  if (firstJobID && firstCompanySlug != '' && firstCompanySlug != null) {
    const [jobDetailResp, companyDetailResp] = await Promise.all([
      getJobDetails(firstJobID, cookieHeader),
      getCompanyDetails(firstCompanySlug, cookieHeader),
    ]);
    return {
      firstJobDetails: jobDetailResp?.data || {},
      firstJobComDetails: companyDetailResp?.data || {},
    };
  } else {
    return {
      firstJobDetails: {},
      firstJobComDetails: {},
    };
  }
};

export const getFirstJobDetailsExpertApply = async (
  firstJob: IJob,
  cookieHeader: string,
  isPremiumUser: boolean,
  expertApplyObj: any
) => {
  if (!isPremiumUser) {
    return {
      firstJobDetails: {},
      firstJobComDetails: {},
    };
  }
  const firstJobID = firstJob?.id;
  const firstCompanySlug = firstJob?.company?.slug;
  let expertApplyJobStatus = '';

  if (firstJobID && firstCompanySlug != '' && firstCompanySlug != null) {
    const [jobDetailResp, companyDetailResp] = await Promise.all([
      getJobDetails(firstJobID, cookieHeader),
      getCompanyDetails(firstCompanySlug, cookieHeader),
    ]);
    if (
      expertApplyObj.isExpertApplyExperimentRunning &&
      firstJob.eligibleForExpertApply
    ) {
      // check the expert apply status for the given job id //
      const response = await getExpertApplyJobStatus(
        expertApplyObj.eaplID,
        cookieHeader,
        firstJobID
      );
      expertApplyJobStatus = fetchExpertApplyJobStatus(response);
    }
    return {
      firstJobDetails: {
        ...(jobDetailResp?.data || {}),
        expertApplyJobStatus,
        matchID: firstJob.matchID || '',
        score: firstJob.score,
      },
      firstJobComDetails: companyDetailResp?.data || {},
    };
  } else {
    return {
      firstJobDetails: {},
      firstJobComDetails: {},
    };
  }
};

export const getNewsAndSuccessStories = async (cookieHeader: string) => {
  const apiURL: ApiEndPoint = '/content/sidebar';
  const response = await apiRequest.getWithServerCache<any>(
    apiURL,
    cookieHeader,
    ApiBaseUrl.BASE_URL_CORE
  );

  const newsData = response?.data?.news || {};
  const successStoriesList = Array.isArray(response?.data?.testimonials)
    ? response.data.testimonials.slice(0, 7)
    : [];

  return { newsData, successStoriesList };
};
