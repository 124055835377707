import { device } from '@styles/device.styled';
import { createGlobalStyle } from 'styled-components';
import {
  Colors,
  FilterGrayScale,
  FontSizes,
  base_font,
  base_font_mb,
} from './styled';

const Sapphire_Global = createGlobalStyle<{ cookie_banner?: boolean }>`
    *{
        box-sizing: border-box;
    }

    html {
        font-size: 62.5%;
    }

    html,body{
        scroll-behavior: smooth;
        
    }

    body,
    button,
    input,
    select{
        font-family: halcyon,"Open Sans","Helvetica Neue", Helvetica, Arial, sans-serif;
    }

   

    body,
    select{
        margin: 0;
        width: 100%;
        font-size: ${base_font + 'rem'};
        line-height: 1.5;
        font-weight: 400;
        ${device.tabletMd} {
          font-size: ${base_font_mb + 'rem'};
         }
    }

    input[type='text'],
    input[type='password']{
      margin: 0;
      width: 100%;
      font-size: ${base_font + 'rem'};
      line-height: 1.5;
      font-weight: 400;
        
    }

    input, select {
      background-color: ${Colors.white};
      border-radius: 0;

      ${device.smallDesktopView}{
        &:focus{
          outline: 0.2rem solid ${Colors.blue2};
          outline-offset: -0.2rem;
        }
      }
      
      ${device.mobileLg}{
        &:focus-visible{
          outline: none;
        }
      }
    }

    input::-ms-reveal, input::-ms-clear {
      display: none;
    }

    input[type='checkbox']{
      accent-color: ${Colors.blue2};

      ${device.smallDesktopView}{
        &:focus{
          outline-offset: 0.2rem;
        }
      }
    }

    p{
        margin: 0 0 1.2rem;
    }

    img{
        filter: grayscale(${FilterGrayScale.inActive});
        max-width: 100%;
        height: auto;
        vertical-align: middle;
    }

    strong{
        font-weight: 600;
    }

    a{
      color: ${Colors.blue2};
      text-decoration: none;

      ${device.smallDesktopView}{
        &:hover{
          color: ${Colors.blue3};
          text-decoration: underline;
        }

        &:focus{
          outline: 0.2rem dashed ${Colors.blue2};
          outline-offset: 0.1rem;
          text-decoration: underline;
        }
      }

      ${device.mobileLg}{
        &:focus-visible{
          outline: none;
        }
      }

      
    }

    button{
      background: transparent;
      border: none;
      cursor: pointer;
      
      ${device.smallDesktopView}{
        &:focus{
          outline: 0.2rem dashed ${Colors.blue2};
          outline-offset: 0.2rem;
        }
      }

      ${device.mobileLg}{
        &:focus-visible{
          outline: none;
        }
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6{
        color: ${Colors.blue1};
        font-weight: 500;
        line-height: 1.2;
        margin: 0 0 0.8rem;
    }

    h1{
      font-size: ${FontSizes.h1};
    }

    h2{
      font-size: ${FontSizes.h2};
    }

    h3{
      font-size: ${FontSizes.h3};
    }

    h4{
      font-size: ${FontSizes.h4};
    }

    h5{
      font-size: ${FontSizes.h5};
    }

    h6{
      font-size: ${FontSizes.h6};
    }

    .modal-open {
      overflow: hidden;
    }

    small{
      font-size: ${(base_font * 0.875).toFixed(1) + 'rem'};
    }

    body .embeddedServiceHelpButton .helpButton .uiButton {
      background-color: ${Colors.blue2};
      font-family: halcyon,"Open Sans",sans-serif;
      border-radius: 3.5rem;
      min-width: 15.7rem;
    }

    body .embeddedServiceHelpButton .helpButton{
      right:1rem;
      bottom: ${({ cookie_banner }) => (cookie_banner ? '6.1rem' : '0.8rem')};
    }

    body .embeddedServiceHelpButton.embeddedServiceBottomTabBar .helpButton{
      ${device.mobileLg}{
        right: 2rem;
        bottom: ${({ cookie_banner }) => (cookie_banner ? '6.5rem' : '0.8rem')};
        padding: 0;
      }
    }
`;

export default Sapphire_Global;
