import { Colors, base_font } from '@styles/sapphire/styled';
const StepBannerStyle = {
  Colors: {
    stepTitle: Colors.black9,
    paragraph: Colors.black10,
  },
  FontSizes: {
    stepTitle: (base_font * 1.375).toFixed(2) + 'rem',
    paragraph: base_font + 'rem',
  },
  FontWeight: {
    stepTitle: 600,
  },
};

export default StepBannerStyle;
