import {
  localStorageGetItem,
  localStorageRemoveItem,
  localStorageSetItem,
} from '@components/common';

const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
const TOOLTIP_KEY = 'expertApplyTooltipShown';
const TOOLTIP_TIMESTAMP_KEY = 'expertApplyTooltipTimestamp';

export const DateDifference = (targetDate: string) => {
  const currentDate = new Date();
  const targetDateStr = new Date(targetDate);

  // Calculate the time difference in milliseconds
  const timeDifference = targetDateStr.getTime() - currentDate.getTime();

  // Convert the time difference to days
  const daysDifference = Math.abs(
    Math.floor(timeDifference / (1000 * 60 * 60 * 24))
  );

  let differenceResp = '';
  if (daysDifference > 30) {
    differenceResp = '30+ days ago';
  } else if (daysDifference > 14) {
    differenceResp = '2 weeks ago';
  } else if (daysDifference === 1 || daysDifference === 0) {
    differenceResp = 'Today';
  } else if (daysDifference === 2) {
    differenceResp = 'Yesterday';
  } else {
    differenceResp = `${daysDifference} days ago`;
  }

  return {
    differenceResp,
    daysDifference,
  };
};

export const jobPostedDateFormatted = (value: string): string => {
  const newDate: Date = new Date(value);
  const month: string | number =
    newDate.getMonth() > 8
      ? newDate.getMonth() + 1
      : '0' + (newDate.getMonth() + 1);
  const date: string | number =
    newDate.getDate() > 9 ? newDate.getDate() : '0' + newDate.getDate();
  const year: string = newDate
    .getFullYear()
    .toString()
    .substring(
      newDate.getFullYear().toString().length - 2,
      newDate.getFullYear().toString().length
    );
  return `${month}/${date}/${year}`;
};

const formatDateToHourMinutes = (date: Date, showAmPm: boolean = true) => {
  // Get hours and minutes
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Format hours and minutes with leading zeros
  const formattedHours = String(hours % 12 || 12).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');

  // Determine AM/PM
  const amPm = hours >= 12 ? 'pm' : 'am';

  // Create the formatted time string
  const formattedTime = `${formattedHours}:${formattedMinutes} ${
    showAmPm ? amPm : ''
  }`;

  return formattedTime;
};

export const getEventDateFormat = (
  startDate: string,
  endDate: string,
  timeZone: string = 'UTC'
): string => {
  try {
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (isNaN(start.getTime()) || isNaN(end.getTime())) {
      console.error('Invalid start or end date:', { startDate, endDate });
      return '';
    }

    const options: Intl.DateTimeFormatOptions = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone,
    };

    const formatter = new Intl.DateTimeFormat('en-US', options);
    const resStartTime = formatter.format(start);
    const resEndTime = formatter.format(end);

    const timeZoneName =
      new Intl.DateTimeFormat('en-US', { timeZone, timeZoneName: 'short' })
        .formatToParts(start)
        .find((part) => part.type === 'timeZoneName')?.value || 'UTC';

    return `${resStartTime} - ${resEndTime} ${timeZoneName}`;
  } catch (error) {
    console.error('Error formatting event date:', error);
    return '';
  }
};

export const getISO8601DatesDiff = (date: string) => {
  const currentDate = new Date();
  const previousDate = new Date(date);
  const timeDifferenceMs = Math.abs(
    currentDate.getTime() - previousDate.getTime()
  );
  const days = Math.floor(timeDifferenceMs / (1000 * 60 * 60 * 24));
  return days;
};

export function jobsToWizardExperimentVariantDesktop(query: any): String {
  const experimentRunning = query.eID == '15a74333-62b9-4cca-95e5-8712b9e7650a';
  if (experimentRunning) {
    return query?.vID;
  }
  return '0';
}

export function jobsToWizardExperimentVariantMobile(query: any): String {
  const experimentRunning = query.eID == '4d5cd7d5-aa1f-44fd-a57d-b828007f7462';
  if (experimentRunning) {
    return query?.vID;
  }
  return '0';
}

export const hasTooltipBeenShown = (): boolean => {
  const shown = localStorageGetItem(TOOLTIP_KEY);
  const timestamp = localStorageGetItem(TOOLTIP_TIMESTAMP_KEY);

  if (
    !timestamp ||
    Date.now() - parseInt(timestamp, 10) > 24 * 60 * 60 * 1000
  ) {
    // Reset tooltip if more than 24 hours have passed
    localStorageRemoveItem(TOOLTIP_KEY);
    localStorageRemoveItem(TOOLTIP_TIMESTAMP_KEY);
    return false;
  }

  return shown === 'true';
};

export const setTooltipShown = () => {
  localStorageSetItem(TOOLTIP_KEY, 'true');
  localStorageSetItem(TOOLTIP_TIMESTAMP_KEY, Date.now().toString());
};
