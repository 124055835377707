import {
  basePathRewrite,
  breadcrumbSchema,
  createCookie,
  isCurrentPageInList,
  loadJs,
  readCookie,
  setPaymentAndSubscriptionStatus,
} from '@components/common';
import {
  CONFIG_OPTIONS,
  Debuger_Script_URLS,
  ECOM_WIDGET_URLS,
  SALESFORCE_CHAT_URLS,
  SKIP_VISIT_URLS,
  _CONSTANTS,
} from '@components/common/constant';
import '@components/common/polyfill';
import { GlobalContextProvider } from '@components/globalContext';
import { IDeviceType } from '@components/shared.types';
import {
  ThemeWrapper,
  deviceTypeByUserAgent,
} from '@license-admin/boldfjcomponents';
import { CreateTheme } from '@styles/styles';
import { trackPageView } from '@utils/eventTrack';
import { getExpertApplyExperimentObject } from '@utils/experimentUtil';
import { getPremiumStatus } from 'API/ApiHelpers';
import { ExperimentProvider } from 'contexts/ExperimentContext';
import { PremiumUserProvider } from 'contexts/PremiumUserContext';
import {
  ConductExperiment,
  getCookieValue,
  getUserID,
  getUserIDServerSide,
  getUsersAllExperimentAndUpdateCookie,
} from 'helper/experiments/experimentActions';
import {
  ABtestData,
  FJPortalExperiments,
} from 'helper/experiments/experimentConstants';
import App, { AppContext } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect, useState } from 'react';
import { URLSearchParams as urlSearchParams } from 'url';

const ErrorBoundary = dynamic(() => import('@components/errorBoundry'));
const Layout = dynamic(() =>
  import('@components/layout').then((mod) => mod.Layout)
);
declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    TS: any;
    APP: any;
    sendBannerEvent: any;
    URLSearchParams: urlSearchParams;
    PS?: any;
    InitializeMetricsForPage?: (
      _portalCD: string,
      _productCD: string,
      _options?: { MuCfg?: { enableWebVitals: boolean } }
    ) => void;
  }
}
function JobsApp({
  Component,
  pageProps,
  isPremiumUser,
  experimentCookies,
  deviceType,
  query,
  initialTheme,
  domain,
  expertApplyExperimentObj,
  popularKeywordsExpVariantObj,
}: any) {
  const router = useRouter();
  const [isDOMLoaded, setIsDOMLoaded] = useState(false);

  // Get the stored theme from cookies (default to wlb theme if not set)
  const [theme, setTheme] = useState(CreateTheme(initialTheme));
  const [themeName, setThemeName] = useState(initialTheme);
  const blob_font_path = `${process.env.NEXT_PUBLIC_CONTENT_URL}/fonts`;

  useEffect(() => {
    if (
      pageProps.deviceType === 'mobile' &&
      isCurrentPageInList(router, Debuger_Script_URLS)
    ) {
      loadJs(`https://cdn.debugbear.com/Ey5GNaVl1Fiv.js`);
    }
    if (isCurrentPageInList(router, ECOM_WIDGET_URLS)) {
      loadJs(`/payment/scripts/fxj/sellPrefetchScript.js`);
    }
  }, []);

  useEffect(function () {
    if (typeof window !== 'undefined' && window.document) {
      const handleLoad = () => setIsDOMLoaded(true);

      if (document.readyState === 'complete') {
        handleLoad();
      } else {
        window.addEventListener('load', handleLoad);
      }

      return () => {
        window.removeEventListener('load', handleLoad);
      };
    }
  }, []);

  function visitorLoaded() {
    trackPageView();
    var includeUserAgent = false;
    var includeGeolocation = true;
    var isRewriteConfigured = false;
    var cookiePath = '/';
    var isRewriteConfiguredWithPath = false;
    try {
      if (
        window.TS &&
        window.TS.Track &&
        !isCurrentPageInList(router, SKIP_VISIT_URLS)
      ) {
        window.TS.Track(
          CONFIG_OPTIONS.productCD,
          CONFIG_OPTIONS.portalCD,
          includeUserAgent,
          includeGeolocation,
          isRewriteConfigured,
          cookiePath,
          isRewriteConfiguredWithPath
        );
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(function () {
    setPaymentAndSubscriptionStatus();
    const userID = getUserID();
    if (userID !== null) {
      getUsersAllExperimentAndUpdateCookie(userID, document.cookie);
    }
    // Cleanup event listener on component unmount
  }, []);

  // Ensure the theme cookie is set if it doesn't already exist
  useEffect(() => {
    if (
      !readCookie('theme') &&
      ['qa', 'reg'].indexOf(process.env.NEXT_PUBLIC_ENV || '') > -1
    ) {
      createCookie('theme', 'sapphire', 365); // Default to wlb theme if not set
      setThemeName('sapphire');
    }
  }, []);

  // Handle theme change from select dropdown
  const handleThemeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newTheme = event.target.value; // Get the selected theme from the dropdown
    const updatedTheme = CreateTheme(newTheme); // Create the new theme based on selected option
    setTheme(updatedTheme); // Update the state with the new theme
    createCookie('theme', newTheme, 365); // Save the theme preference in cookies
    setThemeName(newTheme);
  };
  const seoSchemaHTML =
    pageProps.breadCrumbName && pageProps.seoSchemaTitle
      ? `{
      "@context": "https://schema.org",
      "@graph": [
        {
          "@type": "WebPage",
          "@id": "${pageProps.url}#webpage",
          "url": "${pageProps.url}",
          "name": "${pageProps.seoSchemaTitle}",
          "isPartOf": {
            "@id": "https://www.flexjobs.com#website"
          },
          "breadcrumb":{"@id": "${pageProps.url}#breadcrumb"},
          "description": "${pageProps.seoSchemaDescription}",
          "inLanguage": "en-US",
          "speakable": {
            "@type": "SpeakableSpecification",
            "xpath": [
              "/html/head/title",
              "/html/head/meta[@name='description']/@content"
            ]
          },
          "potentialAction": {
            "@type": "ReadAction",
            "target": "${pageProps.url}"
          },
          "publisher": {
            "@type": "Organization",
            "name": "FlexJobs",
            "logo": {
              "@type": "ImageObject",
              "url": "https://www.flexjobs.com/images/logo-flexjobs.png"
            },
            "sameAs": [
              "https://www.facebook.com/flexjobs",
              "https://www.linkedin.com/company/flexjobs",
              "https://www.instagram.com/flexjobs"
            ]
          }
        },
        {
          "@type": "BreadcrumbList",
          "@id": "${pageProps.url}#breadcrumb",
          "itemListElement": ${JSON.stringify(
            pageProps.breadCrumbName
              ? breadcrumbSchema(
                  pageProps.breadCrumbName,
                  pageProps.breadCrumbNameValue
                )
              : []
          )}
        },
        {
          "@type": "WebSite",
          "@id": "https://www.flexjobs.com#website",
          "url": "https://www.flexjobs.com",
          "name": "FlexJobs",
          "description": "The leading job search site for flexible and remote jobs, offering expert resources and guidance to help job seekers find flexible careers.",
          "publisher": {
            "@type": "Organization",
            "name": "FlexJobs"
          },
          "inLanguage": "en-US"
        }
      ]
    }`
      : `{
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebPage",
        "@id": "${pageProps.url}#webpage",
        "url": "${pageProps.url}",
        "name": "${pageProps.seoSchemaTitle}",
        "isPartOf": {
          "@id": "https://www.flexjobs.com#website"
        },
        "description": "${pageProps.seoSchemaDescription}",
        "speakable": {
          "@type": "SpeakableSpecification",
          "xpath": [
            "/html/head/title",
            "/html/head/meta[@name='description']/@content"
          ]
        },
        "potentialAction": {
          "@type": "ReadAction",
          "target": "${pageProps.url}"
        },
        "publisher": {
          "@type": "Organization",
          "name": "FlexJobs",
          "logo": {
            "@type": "ImageObject",
            "url": "https://www.flexjobs.com/images/logo-flexjobs.png"
          },
          "sameAs": [
            "https://www.facebook.com/flexjobs",
            "https://www.linkedin.com/company/flexjobs",
            "https://www.instagram.com/flexjobs"
          ]
        }
      },
      {
        "@type": "WebSite",
        "@id": "https://www.flexjobs.com#website",
        "url": "https://www.flexjobs.com",
        "name": "FlexJobs",
        "description": "The leading job search site for flexible and remote jobs, offering expert resources and guidance to help job seekers find flexible careers.",
        "publisher": {
          "@type": "Organization",
          "name": "FlexJobs"
        },
        "inLanguage": "en-US"
      }
    ]
  }`;
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {!pageProps.isPremiumUser &&
          pageProps.seoSchemaTitle?.length > 0 &&
          pageProps.seoSchemaDescription?.length > 0 && (
            <script
              type="application/ld+json"
              dangerouslySetInnerHTML={{
                __html: seoSchemaHTML,
              }}
            ></script>
          )}
      </Head>
      <ThemeWrapper setThemeType={themeName} getTheme={theme.MainTheme}>
        {/* <ThemeProvider theme={theme.SapphireTheme}> */}
        {/* {pageProps.deviceType !== 'mobile' && <theme.Base_Font />} */}
        <theme.GlobalCSS />
        <GlobalContextProvider>
          <ErrorBoundary>
            <PremiumUserProvider>
              <ExperimentProvider
                experimentCookies={experimentCookies}
                deviceType={deviceType}
                queryParams={query}
                domain={domain}
                expertApplyExperimentObj={expertApplyExperimentObj}
                popularKeywordsExpVariantObj={popularKeywordsExpVariantObj}
              >
                <Layout
                  {...pageProps}
                  handleThemeChange={handleThemeChange}
                  currentTheme={themeName}
                  popularKeywordsExpVariantObj={popularKeywordsExpVariantObj}
                >
                  <Component {...pageProps} />
                </Layout>
              </ExperimentProvider>
            </PremiumUserProvider>
          </ErrorBoundary>
        </GlobalContextProvider>
        {/* </ThemeProvider> */}
      </ThemeWrapper>
      {isDOMLoaded && (
        <>
          <Script
            src={'/visitor/tracking-v8.js'}
            strategy="lazyOnload"
            onLoad={visitorLoaded}
          />
          <Script id="ps-corejs-fxj" strategy="afterInteractive">
            {`window.skipGTMCookieConsentLoad=true; window.PS = window.PS || {}; PS.COREJS_PRODUCT_NAME='${process.env.NEXT_PUBLIC_COREJS_PRODUCT_NAME}';`}
          </Script>
          <Script
            src={basePathRewrite('/common/js/core.js')}
            type="text/javascript"
            strategy="afterInteractive"
            defer
          />
          {isCurrentPageInList(router, SALESFORCE_CHAT_URLS) && (
            <Script
              src={`${process.env.NEXT_PUBLIC_CONTENT_URL}/externals/salesforce.js`}
              strategy="afterInteractive"
              id="salesforce-js"
            />
          )}
          {!(
            pageProps.deviceType == 'mobile' &&
            router.pathname.includes('/remote-jobs/company')
          ) && (
            <Script
              strategy="afterInteractive"
              id="font-awesome"
              dangerouslySetInnerHTML={{
                __html: `
                function loadStylesheet (href) {
                  const link = document.createElement('link');
                  link.rel = 'stylesheet';
                  link.href = href;
                  document.head.appendChild(link);
                };

                // Load Font Awesome stylesheet
                loadStylesheet('${blob_font_path}/fontawesome.min.css');

              `,
              }}
            />
          )}
        </>
      )}
    </>
  );
}

JobsApp.getInitialProps = async (appContext: AppContext) => {
  // Fetch your server-side prop here
  // Call the page's `getInitialProps` if it exists
  const appProps = await App.getInitialProps(appContext);
  const { req, query } = appContext.ctx;

  const userAgent = req?.headers['user-agent'] || '';
  const deviceType: IDeviceType = deviceTypeByUserAgent(userAgent);
  const domain = req?.headers?.host || '';
  const experimentCookies = {
    [_CONSTANTS.CONDUCT_EXPERIMENT]: getCookieValue(
      req?.headers.cookie,
      _CONSTANTS.CONDUCT_EXPERIMENT
    ),
    [ABtestData.RUNTEST]: getCookieValue(
      req?.headers.cookie,
      ABtestData.RUNTEST
    ),
    [ABtestData.DISABLE_TESTS]: getCookieValue(
      req?.headers.cookie,
      ABtestData.DISABLE_TESTS
    ),
  };

  const queryStringExperiment = query[ABtestData.RUNTEST];
  const queryStringDisableTest = query[ABtestData.DISABLE_TESTS];

  if (typeof queryStringDisableTest === 'string') {
    experimentCookies[ABtestData.DISABLE_TESTS] = queryStringDisableTest;
  }
  if (typeof queryStringExperiment === 'string') {
    experimentCookies[ABtestData.RUNTEST] = queryStringExperiment;
  }
  let initialTheme;
  if (['qa', 'reg'].indexOf(process.env.NEXT_PUBLIC_ENV || '') > -1) {
    initialTheme = req?.headers?.cookie
      ? readCookie('theme', req.headers.cookie)
      : 'sapphire'; // Default to 'sapphire' if not found
  }

  let isPremiumUser = false;
  const userId = getUserIDServerSide(req?.headers.cookie || '') || '';
  if (userId) {
    isPremiumUser = await getPremiumStatus(userId, true, req?.headers.cookie);
  }

  // expert apply changes starts //
  let expertApplyExperimentObj: any = {};
  if (isPremiumUser) {
    expertApplyExperimentObj = await getExpertApplyExperimentObject(
      userId,
      req?.headers.cookie || '',
      '',
      deviceType
    );
  }
  // expert apply changes ends //
  // popular search exp changes start //
  let popularKeywordsExpVariantObj: {
    experimentId: string;
    variant: number;
    variantname?: string;
    name?: string;
    isConducted: boolean;
  } | null = { experimentId: '', variant: 0, isConducted: false };
  const vstr = readCookie('vstr', req?.headers.cookie);
  const popularKeywordsExpObj =
    deviceType === 'mobile'
      ? FJPortalExperiments.fjMostPopularSearchesMobile
      : FJPortalExperiments.fjMostPopularSearchesDesktop;
  if (vstr && !isPremiumUser) {
    const { data } = await ConductExperiment(
      vstr,
      popularKeywordsExpObj,
      '',
      deviceType,
      req?.headers.cookie
    );
    popularKeywordsExpVariantObj = data;
  }

  //popular search exp changes ends

  return {
    ...appProps,
    experimentCookies,
    deviceType,
    query,
    initialTheme,
    domain,
    expertApplyExperimentObj,
    popularKeywordsExpVariantObj: popularKeywordsExpVariantObj,
  };
};
export default JobsApp;
