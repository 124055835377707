import { base_font, Colors } from '@styles/sapphire/styled';

const SuccessStoryVariantStyle = {
  Colors: {
    storyHeading: Colors.black9,
    common: Colors.blue22,
  },
  BgColors: {
    SuccessStoriesList: Colors.gray47,
  },
  BdrColors: {},
  FontSizes: {
    storyHeading: (base_font * 2.5).toFixed(1) + 'rem',
    storyHeadingMobile: (base_font * 1.375).toFixed(1) + 'rem',
    common: (base_font * 0.875).toFixed(1) + 'rem',
    authorName: (base_font * 0.875).toFixed(1) + 'rem',
    highlightTxt: (base_font * 0.875).toFixed(1) + 'rem',
  },
  FontWeight: {},
};

export default SuccessStoryVariantStyle;
