import { Colors, FontFamily, base_font } from '@styles/base/styled';

const SearchFilterStyle = {
  Colors: {
    searchButton: Colors.white,
    suggestHead: Colors.gray1,
    suggestLink: Colors.black,
    suggestLinkHover: Colors.white,
    resultHighlight: Colors.white,
    searchHead: Colors.gray1,
    searchWrap: Colors.gray1,
  },
  BgColors: {
    search: Colors.white,
    mbSearch: Colors.white,
    searchResultWrap: Colors.white,
    transButton: Colors.black,
    searchButton: Colors.black,
    suggestMix: Colors.white,
    suggestLink: Colors.black,
    resultHighlight: Colors.black,
  },
  BdrColors: {
    searchWrap: Colors.gray1,
    searchBtnWrap: Colors.gray1,
    transSearch: Colors.gray1,
    suggestMix: Colors.gray1,
    suggestHead: Colors.gray1,
    searchResultWrapBrdr: Colors.gray1,
    staticListBrdr: Colors.gray1,
    searchHeadBrdr: Colors.gray1,
    searchWrapShadow: Colors.shadowSharp,
  },
  FontFamily: {
    suggestHead: FontFamily.fontAwesome,
  },
  FontSizes: {
    transButton: base_font * 1.1 + 'rem',
    searchButton: base_font * 1.3 + 'rem',
  },
};
export default SearchFilterStyle;
