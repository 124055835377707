import {
  Colors,
  FontFamily,
  FontSizes,
  LineHeight,
  base_font,
} from '@styles/sapphire/styled';

const FilterBtnStyle = {
  Colors: {
    button: Colors.white,
    buttonHover: Colors.black,
    toggleBtn: Colors.black,
    toggleBtnActive: Colors.white,
    link: Colors.blue2,
    linkHover: Colors.blue1,
    crossiconcolor: Colors.gray6,
    selectedCrossIcon: Colors.white,
    filterPlaceholder: Colors.blue26,
  },
  BgColors: {
    button: Colors.black,
    buttonHover: Colors.gray1,
    toggleBtn: Colors.white,
    toggleBtnActive: Colors.blue2,
    dropdownListWrap: Colors.white,
    linkHover: Colors.gray8,
    flyerwrapbg: Colors.white,
    flyerfooterbg: Colors.white,
  },
  BdrColors: {
    button: Colors.white,
    dropdownListWrap: Colors.gray1,
    toggleBtn: Colors.gray1,
    filterBtnShadow: Colors.shadowyBlack,
    filterBdr: Colors.gray27,
    filterBdrAda: Colors.blue25,
  },
  FontSizes: {
    toggleBtn: FontSizes.h5,
    dropdownList: base_font * 0.8 + 'rem',
    reset: FontSizes.h5,
    iconFontSize: FontSizes.h5,
    resetcrossbtn: FontSizes.h6,
    filtertexthead: FontSizes.h6,
    flyercatlist: FontSizes.h6,
    selectedCrossIcon: base_font + 'rem',
    crossIcon: base_font * 0.45 + 'rem',
  },
  FontFamilies: {
    iconFontFamily: FontFamily.fontAwesome,
  },
  FontWeights: {
    iconFWNormal: 400,
    iconFWSemibold: 600,
    crossIcon: 700,
  },
  LineHeight: {
    toggleBtn: LineHeight.xl,
    dropdownList: LineHeight.xl,
  },
};

export default FilterBtnStyle;
