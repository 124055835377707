import { Colors, base_font } from '@styles/sapphire/styled';
const JobVariantDrawerStyle = {
  Colors: {
    title: Colors.black9,
    subtitle: Colors.blue22,
    contentData: Colors.black9,
    subContent: Colors.blue22,
    testimonial: Colors.blue1,
    testimonialHighlight: Colors.orange1,
    footer: Colors.blue22,
  },
  FontSizes: {
    title: (base_font * 1.75).toFixed(1) + 'rem',
    subtitle: (base_font * 1.5).toFixed(1) + 'rem',
    contentData: (base_font * 1.5).toFixed(1) + 'rem',
    subContent: (base_font * 1.125).toFixed(1) + 'rem',
    button: (base_font * 1.25).toFixed(1) + 'rem',
    testimonial: base_font.toFixed(1) + 'rem',
  },
  FontWeight: {
    title: 500,
    subtitle: 400,
    contentData: 500,
    subContent: 400,
    testimonial: 600,
    footer: 300,
    footerSubText: 600,
  },
};

export default JobVariantDrawerStyle;
