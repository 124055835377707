import {
  IJobType,
  IReportJob,
  JobsTabs,
  UserTabs,
} from '@components/shared.types';

export const JOBS_COUNT_PATH: string = `${process.env.NEXT_PUBLIC_JOBS_URL_RJ}/v1/jobs/search?page=1&size=50`;

export const agreementDataForUser = [
  {
    agreementCD: 'PRYPCY', // Privacy Policy
    isAgreed: 'true',
  },
  {
    agreementCD: 'TMOUSR', // Terms of Use
    isAgreed: 'true',
  },
];
export const agreementDataForPartner = [
  {
    agreementCD: 'SHACTV', // Partner Shared Data
    isAgreed: 'true',
  },
];

export const LOCAL_STORAGE_CONSTS: Record<string, string> = {
  userPreferences: 'userPreferences',
};

export const USER_PREFERENCES_CODES: Record<string, string> = {
  Big_Interview_Pref: 'BINT',
  ISOLATION_LAYER: 'ISOL',
};

export const selectedFiltersOrder = [
  'searchkeyword',
  'remoteoptions',
  'jobtypes',
  'jobschedules',
  'careerlevel',
  'educations',
  'travels',
  'categories',
  'accolades',
  'companies',
  'jobtitles',
  'salaryrange',
  'boostbenefits',
  // 'jobswithoutsalary'
  // 'onlyexpertapplyjobs',
];

export const searchStaticList = [
  {
    icon: 'fa fa-location-dot',
    name: 'Current Location',
    border: true,
    href: '/search',
  },
  {
    icon: 'fa fa-flag light-font',
    name: 'Work from Anywhere in U.S.',
    extraTopSpace: true,
    href: '/remote-jobs/usa/us-national',
  },
  {
    icon: 'fa fa-earth-americas',
    name: 'Work from Anywhere in World',
    border: true,
    extraBottomSpace: true,
    href: '/remote-jobs/world/anywhere',
  },
];

export const UnAuthNav = [
  {
    name: 'For Employers',
    route: '/employers-post-remote-jobs',
    buttonType: false,
  },
  {
    name: 'Log In',
    route: '/login',
    buttonType: false,
  },
  {
    name: 'Sign Up',
    route: '/registration/signup',
    buttonType: true,
  },
];

export const EmployerUnAuthNav = [
  {
    name: 'Job Seeker?',
    route: '/',
    buttonType: false,
  },
  {
    name: 'Log In',
    route: '/login?emp=1',
    buttonType: false,
  },
  {
    name: 'Hire Now',
    route: '/members/employers/signup',
    buttonType: true,
  },
];

export const NavChild = [
  {
    name: 'Find Remote, Hybrid, & Flexible Jobs',
    route: '/remote-jobs',
    children: [
      {
        name: 'New Jobs',
        route: '/remote-jobs/legitimate-work-from-home-jobs-hiring-now',
      },
      {
        name: 'Research Companies',
        route: '/company-guide',
      },
      {
        name: 'Jobs by Location',
        route: '/searchlocation',
      },
      {
        name: 'Featured Employer Jobs',
        route: '/jobs/featured',
      },
      // {
      //   name: '100 Surprising Jobs',
      //   route: '/jobs/surprising-jobs',
      // },
    ],
  },
  {
    name: 'How FlexJobs Works',
    route: '/about.aspx',
  },
  {
    name: 'Career Advice',
    route: '/job-search-career-advice',
  },
  {
    name: 'Events & Webinars',
    route: '/events?time=upcoming',
    customAttributes: {
      rel: 'nofollow',
    },
  },
  {
    name: 'Job Search Articles',
    route: '/blog',
  },
];

export const AuthNavChild = [
  {
    name: 'My Dashboard',
    route: '/members',
    aliseRoute: '/members/dashboard',
    children: [
      {
        name: 'Suggested Jobs',
        route: '/members/suggestedjobs',
      },
      {
        name: 'Saved Searches',
        route: '/members/savedsearches',
      },
      {
        name: 'Saved Jobs',
        route: '/members/savedjobs',
      },
      {
        name: 'Job Applications',
        route: '/members/jobapplications',
      },
      {
        name: 'My Resumes',
        route: '/members/myResumes',
      },
      {
        name: 'My Preferences',
        route: '/members/myPreferences',
      },
    ],
  },
  {
    name: 'Find Jobs',
    route: '/remote-jobs',
    children: [
      {
        name: 'New Jobs',
        route: '/remote-jobs/legitimate-work-from-home-jobs-hiring-now',
      },
      {
        name: 'Research Companies',
        route: '/members/companies',
      },
      {
        name: 'Jobs by Location',
        route: '/searchlocation',
      },
      {
        name: 'Featured Employer Jobs',
        route: '/jobs/featured',
      },
    ],
  },
  {
    name: 'Articles',
    route: '/blog',
  },
  {
    name: 'Learning Center',
    route: '/learning',
  },
  {
    name: 'Career Advice',
    route: '/job-search-career-advice',
  },
  {
    name: 'Events & Webinars',
    route: '/events?time=upcoming',
    customAttributes: {
      rel: 'nofollow',
    },
  },
];
export const ExpertApplyAuthNavChild = [
  {
    name: 'My Dashboard',
    route: '/members',
    aliseRoute: '/members/dashboard',
    children: [
      {
        name: 'Suggested Jobs',
        route: '/members/suggestedjobs',
      },
      {
        name: 'My ExpertApply',
        route: '/expertapply/applications',
      },
      {
        name: 'My Applications',
        route: '/members/jobapplications',
      },
      {
        name: 'My Resumes',
        route: '/members/myResumes',
      },
    ],
  },
  {
    name: 'Find Jobs',
    route: '/remote-jobs',
    children: [
      {
        name: 'New Jobs',
        route: '/remote-jobs/legitimate-work-from-home-jobs-hiring-now',
      },
      {
        name: 'Research Companies',
        route: '/members/companies',
      },
      {
        name: 'Jobs by Location',
        route: '/searchlocation',
      },
      {
        name: 'Featured Employer Jobs',
        route: '/jobs/featured',
      },
    ],
  },
  {
    name: 'Articles',
    route: '/blog',
  },
  {
    name: 'Learning Center',
    route: '/learning',
  },
  {
    name: 'Career Advice',
    route: '/job-search-career-advice',
  },
  {
    name: 'Events & Webinars',
    route: '/events?time=upcoming',
    customAttributes: {
      rel: 'nofollow',
    },
  },
];

export const EmployerNav = [
  {
    name: 'How FlexJobs Works',
    route: 'https://www.flexjobs.com/employer-about',
  },
  {
    name: 'How We Can Help',
    route: 'https://www.flexjobs.com/employer-benefits',
  },
  {
    name: 'Case Studies & Testimonials',
    route: 'https://www.flexjobs.com/employer-success-stories',
  },
  {
    name: 'Employer Articles',
    route: 'https://www.flexjobs.com/employer-blog',
  },
  {
    name: 'Guide to Hybrid Remote Work',
    route: 'https://www.flexjobs.com/guide-to-hybrid-remote-work-success',
  },
];

export type MbNavProp = {
  name: string;
  route?: string;
  children?: MbNavProp[];
};

export const MbNav = [
  {
    name: 'Find Remote, Hybrid, & Flexible Jobs',
    children: [
      {
        name: 'Job Categories',
        route: '/remote-jobs',
      },
      {
        name: 'New Jobs',
        route: '/remote-jobs/legitimate-work-from-home-jobs-hiring-now',
      },
      {
        name: 'Research Companies',
        route: '/company-guide',
      },
      {
        name: 'Jobs by Location',
        route: '/searchlocation',
      },
      {
        name: 'Featured Employer Jobs',
        route: '/jobs/featured',
      },
      // {
      //   name: '100 Surprising Jobs',
      //   route: '/jobs/surprising-jobs',
      // },
    ],
  },
  {
    name: 'How FlexJobs Works',
    children: [
      {
        name: 'Overview',
        route: '/about.aspx',
      },
      {
        name: 'Benefits',
        route: '/Benefits.aspx',
        customAttributes: {
          rel: 'nofollow',
        },
      },
      {
        name: 'Success Stories',
        route: '/reviews-success-stories',
      },
      {
        name: 'Job Research All-Stars',
        route: '/research-team',
        customAttributes: {
          rel: 'nofollow',
        },
      },
      {
        name: 'Our Team',
        route: '/FlexJobsTeam.aspx',
      },
      {
        name: 'Press & Awards',
        route: '/pressReviews.aspx',
      },
      {
        name: 'FAQ',
        route: '/faq',
      },
      {
        name: 'Tour',
        route: '/tour',
      },
    ],
  },
  {
    name: 'Career Advice',
    route: '/job-search-career-advice',
  },
  {
    name: 'Events & Webinars',
    route: '/events?time=upcoming',
    customAttributes: {
      rel: 'nofollow',
    },
  },
  {
    name: 'Job Search Articles',
    route: '/blog',
  },
];

export const AuthMbNav = [
  {
    name: 'My Dashboard',
    children: [
      { name: 'My Dashboard', route: '/members' },
      { name: 'Suggested Jobs', route: '/members/suggestedjobs' },
      { name: 'Saved Searches', route: '/members/savedsearches' },
      { name: 'Saved Jobs', route: '/members/savedjobs' },
      { name: 'Job Applications', route: '/members/jobapplications' },
      {
        name: 'My Resumes',
        route: '/members/myResumes',
      },
      {
        name: 'My Preferences',
        route: '/members/myPreferences',
      },
    ],
  },
  {
    name: 'Find Jobs',
    children: [
      { name: 'Job Categories', route: '/remote-jobs' },
      {
        name: 'New Jobs',
        route: '/remote-jobs/legitimate-work-from-home-jobs-hiring-now',
      },
      { name: 'Research Companies', route: '/members/companies' },
      { name: 'Jobs by Location', route: '/searchlocation' },
      { name: 'Featured Employer Jobs', route: '/jobs/featured' },
    ],
  },
  { name: 'Articles', route: '/blog' },
  {
    name: 'Learning Center',
    children: [
      { name: 'Learning Center', route: '/learning' },
      { name: 'Job Search Checklist', route: '/members/tips' },
      {
        name: 'LinkedIn Learning Video Courses',
        route: '/learning#learning-center-courses-anchor',
      },
      { name: 'Webinars', route: '/learning#learning-center-webinars-anchor' },
      {
        name: 'Downloadable Guides',
        route: '/learning#learning-center-guides-anchor',
      },
    ],
  },
  { name: 'Career Advice', route: '/job-search-career-advice' },
  {
    name: 'Events & Webinars',
    route: '/events?time=upcoming',
    customAttributes: {
      rel: 'nofollow',
    },
  },
];
export const AuthMbExpertApplyNav = [
  {
    name: 'My Dashboard',
    children: [
      { name: 'My Dashboard', route: '/members' },
      { name: 'Suggested Jobs', route: '/members/suggestedjobs' },
      { name: 'My ExpertApply ', route: '/expertapply/applications' },
      { name: 'My Applications', route: '/members/jobapplications' },
      { name: 'My Resumes', route: '/members/myResumes' },
    ],
  },
  {
    name: 'Find Jobs',
    children: [
      { name: 'Job Categories', route: '/remote-jobs' },
      {
        name: 'New Jobs',
        route: '/remote-jobs/legitimate-work-from-home-jobs-hiring-now',
      },
      { name: 'Research Companies', route: '/members/companies' },
      { name: 'Jobs by Location', route: '/searchlocation' },
      { name: 'Featured Employer Jobs', route: '/jobs/featured' },
    ],
  },
  { name: 'Articles', route: '/blog' },
  {
    name: 'Learning Center',
    children: [
      { name: 'Learning Center', route: '/learning' },
      { name: 'Job Search Checklist', route: '/members/tips' },
      {
        name: 'LinkedIn Learning Video Courses',
        route: '/learning#learning-center-courses-anchor',
      },
      { name: 'Webinars', route: '/learning#learning-center-webinars-anchor' },
      {
        name: 'Downloadable Guides',
        route: '/learning#learning-center-guides-anchor',
      },
    ],
  },
  { name: 'Career Advice', route: '/job-search-career-advice' },
  {
    name: 'Events & Webinars',
    route: '/events?time=upcoming',
    customAttributes: {
      rel: 'nofollow',
    },
  },
];

export const JOB_TYPES: { [key: string]: IJobType } = {
  PAST: 'past',
  CURRENT: 'current',
};

export const JOBS_TABS: JobsTabs = [
  {
    id: 'current',
    title: 'Current Jobs',
  },
  // {
  //   id: 'past',
  //   title: 'Past Jobs',
  // },
];

export const USER_TABS: UserTabs = [
  {
    id: 'getStarted',
    title: 'Get Started',
  },
  {
    id: 'logIn',
    title: 'Log In',
  },
];

export const Regex = {
  email: /^\w+([-+.']\w+)*@\w+([-.']\w+)*\.\w+([-.]\w+)*$/,
  emailDomainRegex: /@(gmail|me|mac|yahoo|hotmail|aol)\.com/,
  comapnayWebsite: /^https:\/\//,
  getPage: /page=(\d+)/,
  // allowed first and last word with only aA-zZ and space, hypen and single inverted comma in between
  name: /^[a-zA-Z]?[- 'a-zA-Z]+$/,
  capitilizeAfterSpace: /(^\w{1})|(\s+\w{1})/g,
  testLogoPattern: /^\/assets\/images\/storage\/.*$/,
};

type IAllowedSectionsToHide = {
  CATEGORY_TABS: string;
  SEARCH_FILTER: string;
  SURPRISING_JOBS_FILTER: string;
  WIDGETS: string;
};

export const allowedSectionsToHide: IAllowedSectionsToHide = {
  CATEGORY_TABS: 'categoryTabs',
  SEARCH_FILTER: 'searchFilter',
  SURPRISING_JOBS_FILTER: 'suprisingJobsFilter',
  WIDGETS: 'widgets',
} as const;

type CompanyTabs = {
  id: string;
  title: string;
  icon: string;
}[];

export const savedJobsField = 'saved-jobs';

export const visibleDescriptionCharCount = 200;

export const COMPANY_TABS: CompanyTabs = [
  {
    id: 'job-summary',
    title: 'Job Summary',
    icon: 'fas fa-list',
  },
  {
    id: 'company',
    title: 'Company',
    icon: 'fa-regular fa-building fa-fw',
  },
];

export const HOSTED_JOBS_TABS: CompanyTabs = [
  {
    id: 'job-summary',
    title: 'Job',
    icon: 'fa fa-briefcase',
  },
  {
    id: 'company',
    title: 'Company',
    icon: 'fa-regular fa-building fa-fw',
  },
];

export const ApplyJobStatus = {
  applied: 'applied',
  sentFollowUp: 'sentfollowup',
  interviewSchedule: 'interviewschedule',
  interviewCompleted: 'interviewcompleted',
  offerAccepted: 'offeraccepted',
  offerDeclined: 'offerdeclined',
};

export const FlexibleJobs = [
  {
    name: 'Find Remote Work From Home & Flexible Jobs',
    children: [
      {
        name: 'New Remote Jobs Hiring Now',
        route: '/remote-jobs/legitimate-work-from-home-jobs-hiring-now',
      },
      {
        name: 'Remote Jobs Near Me',
        route: '/searchlocation',
      },
      {
        name: 'Part-Time Remote Jobs',
        route: '/remote-jobs/part-time',
      },
      {
        name: 'Entry Level Remote Jobs',
        route: '/remote-jobs/entry-level',
      },
      {
        name: 'Freelance Remote Jobs',
        route: '/remote-jobs/freelance',
      },
      {
        name: 'Browse Remote Jobs by Category',
        route: '/remote-jobs',
      },
      {
        name: 'Browse Top Work from Home Jobs',
        route: '/jobs/top-searched-remote-work-from-home-jobs',
      },
      {
        name: 'Full-Time Remote Jobs',
        route: '/remote-jobs/full-time',
      },
      {
        name: 'Temporary Remote Jobs',
        route: '/remote-jobs/temp',
      },
      {
        name: 'Work from Anywhere Jobs',
        route: '/remote-jobs/world/anywhere',
      },
    ],
  },
][0];

export const AboutJobs = [
  {
    name: 'About FlexJobs',
    children: [
      {
        name: 'FlexJobs Reviews',
        route: '/reviews-success-stories',
      },
      {
        name: 'How FlexJobs Works',
        route: '/about.aspx',
      },
      {
        name: 'Press & Awards',
        route: '/pressReviews.aspx',
      },
      {
        name: 'Careers at FlexJobs',
        route: 'https://www.bold.com/bold-jobs/',
      },
      {
        name: 'FlexJobs App',
        route: '/job-search-app',
      },
      {
        name: 'Affiliate Program',
        route: '/affiliate-program',
      },
      {
        name: 'Do Not Sell or Share My Personal Information',
        route: '/dnss-form',
      },
      {
        name: 'Contact Us',
        route: '/contact',
      },
      {
        name: 'Fraud Awareness',
        route: '/fraud-awareness',
      },
      // {
      //   name: 'CCPA/GDPR',
      //   route: '/personal-data',
      // },
    ],
  },
][0];

export const AboutJobsAuth = [
  {
    name: 'About FlexJobs',
    children: [
      {
        name: 'FlexJobs Reviews',
        route: '/reviews-success-stories',
      },
      {
        name: 'How FlexJobs Works',
        route: '/about.aspx',
      },
      {
        name: 'Press & Awards',
        route: '/pressReviews.aspx',
      },
      {
        name: 'Careers at FlexJobs',
        route: 'https://www.bold.com/bold-jobs/',
      },
      {
        name: 'FlexJobs App',
        route: '/job-search-app',
      },
      {
        name: 'Affiliate Program',
        route: '/affiliate-program',
      },
      {
        name: 'Do Not Sell or Share My Personal Information',
        route: '/dnss-form',
      },
      {
        name: 'Contact Us',
        route: '/contact',
      },
      {
        name: 'Fraud Awareness',
        route: '/fraud-awareness',
      },
      {
        name: 'CCPA/GDPR',
        route: '/personal-data',
      },
    ],
  },
][0];

// export const AboutJobsAuthReg = [
//   {
//     name: 'About FlexJobs',
//     children: [
//       {
//         name: 'How FlexJobs Works',
//         route: '/About.aspx',
//       },
//       {
//         name: 'What Is A Remote Job?',
//         route: '/blog/post/what-is-a-remote-job/',
//       },
//       {
//         name: 'Benefits of Working Remotely',
//         route: '/blog/post/benefits-of-remote-work/',
//       },
//       {
//         name: 'Finding Remote Jobs',
//         route: '/blog/post/how-to-find-a-remote-job-v2/',
//       },
//       {
//         name: 'Remote Work Statistics',
//         route: '/blog/post/remote-work-statistics/',
//       },
//       {
//         name: 'Press & Awards',
//         route: '/PressReviews.aspx',
//       },
//       {
//         name: 'The FlexJobs Team',
//         route: '/FlexJobsTeam.aspx',
//       },
//       {
//         name: 'Careers at FlexJobs',
//         route: 'https://www.bold.com/bold-jobs/',
//       },
//       {
//         name: 'FlexJobs App',
//         route: '/job-search-app',
//       },
//       {
//         name: 'FlexJobs FAQ',
//         route: '/faq',
//       },
//       {
//         name: 'Affiliate Program',
//         route: '/affiliate-program',
//       },
//       {
//         name: 'Do Not Sell or Share My Personal Information',
//         route: '/dnss-form',
//       },
//       {
//         name: 'Contact Us',
//         route: '/contact',
//       },
//       {
//         name: 'Fraud Awareness',
//         route: '/fraud-awareness',
//       },
//       {
//         name: 'FlexJobs Reviews',
//         route: '/reviews-success-stories',
//       },
//       {
//         name: 'CCPA/GDPR',
//         route: '/personal-data',
//       },
//     ],
//   },
// ][0];

export const JobSearch = [
  {
    name: 'Job Search Resources',
    children: [
      {
        name: 'Work from Home Jobs No Experience',
        route: '/blog/post/how-to-make-money-from-home-no-work-experience',
      },
      {
        name: 'How To Make Money Online',
        route: '/blog/post/real-ways-to-make-money-from-home',
      },
      {
        name: 'Weekend Jobs',
        route: '/blog/post/top-weekend-jobs-and-companies-hiring-now',
      },
      {
        name: 'Side Hustle Jobs from Home',
        route: '/blog/post/remote-side-jobs-done-from-home-hiring-now-2',
      },
      {
        name: 'High Paying Remote Jobs',
        route: '/blog/post/top-paying-remote-jobs-v2',
      },
      {
        name: 'Best Remote Companies to Work For',
        route: '/company-guide',
      },
      {
        name: 'Informational Guides ',
        route: '/resources',
      },
      {
        name: 'Online Resume Builder',
        route: 'https://www.myperfectresume.com/resume/builder',
      },
    ],
  },
][0];

export const AboutFlexJobs = [
  {
    name: 'About FlexJobs',
    children: [
      { name: 'How FlexJobs Works', route: '/employer-about' },
      { name: 'How We Can Help', route: '/employer-benefits' },
      {
        name: 'Case Studies & Testimonials',
        route: '/employer-success-stories',
      },
      { name: 'Contact Us', route: '/employer-contact' },
    ],
  },
][0];

export const FindCandidates = [
  {
    name: 'Find Candidates',
    children: [
      {
        name: 'Post Remote & Flexible Jobs',
        route: '/Members/Employers/Register.aspx',
      },
      {
        name: 'Employer Membership',
        route: '/employer-about',
      },
    ],
  },
][0];

export const Resources = [
  {
    name: 'Resources',
    children: [
      {
        name: 'FlexJobs Employer Blog',
        route: '/employer-blog',
      },
      {
        name: 'Economic Development',
        route: '/employer-hiring-remote-workers-rural-economic-development',
      },
      // {
      //   name: 'Telecommuting Stats',
      //   route: '/2017-State-of-Telecommuting-US',
      // },
      {
        name: 'Guide to Hybrid Remote Work Success',
        route: '/guide-to-hybrid-remote-work-success',
      },
      {
        name: 'Work Flexibility for Military Families',
        route: '/military',
      },
    ],
  },
][0];

export const footerExternalLink = {
  bbbLogo:
    process.env.NEXT_PUBLIC_CONTENT_URL +
    '/images/online-arating-horizontal-blue.jpg',
  bbbLink:
    'https://www.bbb.org/us/co/boulder/profile/employment-agencies/flexjobs-1296-90091095/customer-reviews',
  appStoreLogo: process.env.NEXT_PUBLIC_CONTENT_URL + '/images/app-store.svg',
  appStoreLink:
    'https://apps.apple.com/us/app/flexjobs-remote-job-search/id800818884?itsct=apps_box&itscg=30200',
  playStoreLogo:
    process.env.NEXT_PUBLIC_CONTENT_URL + '/images/Google_Play_Store_Logo.svg',
  playStoreLink:
    'https://play.google.com/store/apps/details?id=com.bold.flexjobs',
  remoteCo: process.env.NEXT_PUBLIC_CONTENT_URL + '/images/remote-co-logo.svg',
  jobHunt: process.env.NEXT_PUBLIC_CONTENT_URL + '/images/job-hunt-logo.svg',
  bottomText: `© 2007-${new Date().getFullYear()} FlexJobs <span>All Rights Reserved</span>`,
  manageCookiesLabel: 'Manage Cookies',
  manageCookiesLink: '/cookie_preferences',
  touLabel: 'Terms of Use',
  touLink: '/termsofuse.aspx',
  ppLabel: 'Privacy Policy',
  ppLink: '/privacypolicy.aspx',
};

export const CONFIG_OPTIONS = {
  productCD: 'FJS',
  portalCD: 'FXJ',
  templateID: '2005',
  portalID: 219,
  cookieDomain: '.fj-staging.com',
  portalName: 'fj-staging.com',
  appCD_QA: 'FJDSB_SND_W_COR',
  appCD_REG: 'FJDSB_REG_W_COR',
  sourceAppCD: 'RJIUS',
  clientCode: 'FXJBS',
  authProvider: 'FXJ-COR',
  authCookieName_QA: 'Auth_QA',
  authCookieName_REG: 'Auth_REG',
  authCookieName: 'Auth',
  temaplateID: '2005',
  docTypeCd: 'JPFL',
  cultureCd: 'en-US',
  defaultSkinCd: 'FLJ1',
};

export const preferenceCd = {
  IncompleteFields: 'INCF',
  CompletionScore: 'CMSC',
};

export const getAllDocumentFilter =
  'id,legacyDocId,documentTypeCD,templateID,partyID,userId,skinCD,name,publicName,docStatusTypeCD,userStageID,dateCreated,dateModified,migrationDate,portalID,preferences,country,productCD,version';

export const baseConfig = {
  hideFilters: [''],
  // There order should not be changed
  filtersOrder: [
    'remote',
    'location',
    'salary',
    'jobtype',
    'dateposted',
    'careerlevel',
    'category',
    'benefits',
    'schedule',
    'company',
  ],
  // There order should not be changed
  filtersQueryParamKeys: {
    remote: 'remoteoptions',
    location: 'joblocations',
    salary: 'salaryrange',
    jobtype: 'jobtypes',
    dateposted: 'age',
    careerlevel: 'careerlevel',
    category: 'categories',
    benefits: 'jobbenefits',
    schedule: 'jobschedules',
    company: 'companies',
  },
};

export const ReportJobReason: IReportJob = {
  noLongerAvailable: {
    label: 'This job is no longer available',
    showTextArea: false,
    childText: '',
    showSubmit: true,
  },
  incorrectOrMissing: {
    label: 'Incorrect or missing information',
    showTextArea: true,
    childText: 'Please provide additional details:',
    showSubmit: true,
  },
  troubleApplying: {
    label: 'Trouble applying',
    showTextArea: false,
    childText:
      "Thank you for bringing this to our attention! It's possible the employer site was experiencing issues or downtime when you tried to apply earlier. Please try again, perhaps from a different browser or computer. If you continue to have trouble, you may need to follow up with the employer to troubleshoot the issue with their application form.",
    showSubmit: false,
  },
  jobNotRemote: {
    label: 'Job is not remote',
    showTextArea: false,
    childText:
      'FlexJobs offers more than just remote jobs. Many jobs have different types of flexibility. We provide access to jobs offering various levels of work flexibility, including remote, part-time, freelance, temporary, alternative schedule, etc. If you are specifically interested in remote jobs, try doing a site search and filtering on your results. You can select "100% Remote Work" in the "Remote Work Level" filter on the left-hand side.',
    showSubmit: false,
  },
  locationRequirement: {
    label: 'Location requirement',
    showTextArea: false,
    childText:
      'Many employers, even with 100% remote positions, prefer a person within a particular city or state. Employers may be limited to specific locations due to local tax requirements, training, or certifications. Use keyword search and location search to find jobs in your area along with US National and Worldwide jobs. Search results can then be filtered for different options, like schedule or remote work level.',
    showSubmit: false,
  },
  other: {
    label: 'Other',
    showTextArea: true,
    childText: 'Please describe:',
    showSubmit: true,
  },
};

export const _CONSTANTS = {
  LAST_SEARCH_TERM: 'lastSearchTerm',
  SESSION_ID: '_session_id',
  USER_STATUS: 'UserStatus',
  USER_AUTH_SUCCESS: 'SUCCESS',
  USER_AUTH_USER_SWAP: 'USER_SWAP',
  VSUID: 'vsuid',
  COMMON: 'common',
  CLAIMS: 'claims',
  SHOW_SAVE_SEARCH_BANNER: 'SHOW_SAVE_SEARCH_BANNER',
  SET_SHOW_ALL_DROP_FILTERS: 'showAllDropFilters',
  EXIT_POP_DISMISSED_KEY: 'fjLSflexer-exit-pop-dismissed',
  FLEXJOBS_DIFFERENT_POP_UP_KEY: 'fj_different_pop_dismissed',
  CCPA_STATUS: 'CCPA_STATUS',
  TEST_COUNTRY: 'testcountry',
  CLEAR_COOKIE: 'clearCookie',
  CONDUCT_EXPERIMENT: 'c_expt',
  LAYER_ID: 'e_layer',
  SOFT_REG_POPUP_DISPLAYED: 'softRegPopupDisplayed',
  VSTR: 'vstr',
  JOBS_COUNT: 'jobs_type_count',
  BIG_INTERVIEW: 'big_interview',
  COMPANY_PAGE_REDESIGN_VARIANT: 'companyPageRedesignVariant',
  INVALID_PASSWORD: 'INVALID_PASSWORD',
  SUBSCRIPTION_STATUS: 'subscriptionStatus',
  ISOLATION_LAYER: 'iso_layer',
};
export const company_mapping: any = {
  'act,_inc.': 'act-american_college_testing',
  'agren,_inc.': 'land_olakes',
  'alliancetek_inc.': 'alliancetek_inc',
  'anthem,_inc.': 'elevance_health',
  'ascent_processing,_inc.': 'ascent_processing_inc',
  'bit9_+_carbon_black': 'vmware',
  'daysplan,_inc.': 'daysplan_inc',
  'footsteps2brilliance,_inc.': 'footsteps2brilliance_inc',
  'hitachi,_ltd.': 'hitachi',
  'levi_strauss_&_co.': 'levi_strauss_co',
  'object_technology_solutions,_inc.': 'object_technology_solutions_inc',
  'perception_strategies,_inc.': 'perception_strategies_inc',
  'pharmaceutical_product_development_inc.':
    'thermo_fisher_scientific-ppd-pharmaceutical_product_development',
  'snap_inc.': 'snap_inc',
  'talent_inc.': 'talent_inc',
  'thrive_technologies_inc.': 'thrive_technologies_inc',
  "o'melveny_&_myers": 'omelveny_myers',
  'o’melveny_&_myers': 'omelveny_myers',
};
export const ApiBaseUrl: Record<string, string> = {
  BASE_URL_EB: process.env.NEXT_PUBLIC_EB_URL || '',
  BASE_URL_RJ: process.env.NEXT_PUBLIC_JOBS_URL_RJ || '',
  BASE_URL_CORE: process.env.NEXT_PUBLIC_FJ_URL || '',
};

export const ApiBaseUrlServer: Record<string, string> = {
  BASE_URL_EB: process.env.NEXT_PUBLIC_EB_URL_SERVER || '',
  BASE_URL_RJ: process.env.NEXT_PUBLIC_JOBS_URL_RJ_SERVER || '',
  BASE_URL_CORE: process.env.NEXT_PUBLIC_FJ_URL_SERVER || '',
};

export const WIDGETS_NAME: {
  NEWS: string;
  SUCCESS_STORIES: string;
  PROMO_BANNER: string;
  NEWS_LETTER: string;
  CURRENTLY_HIRING: string;
  RELATED_ARTICLES: string;
  FOLLOWED_COMPANIES: string;
  COMPANY_HIRING: string;
  RELATED_COMPANY_SEARCH: string;
  MPR: string;
  SIMILAR_JOBS: string;
  UPCOMING_EVENT: string;
  CURRENTLY_HIRING_TYPE: string;
} = {
  NEWS: 'news',
  SUCCESS_STORIES: 'success-stories',
  PROMO_BANNER: 'promo-banner',
  NEWS_LETTER: 'news-letter',
  CURRENTLY_HIRING: 'currently-hiring',
  RELATED_ARTICLES: 'related-articles',
  FOLLOWED_COMPANIES: 'followed-companies',
  COMPANY_HIRING: 'company-hiring',
  RELATED_COMPANY_SEARCH: 'related-company-search',
  MPR: 'my-perfect-resume',
  SIMILAR_JOBS: 'similar-jobs',
  UPCOMING_EVENT: 'upcoming-event',
  CURRENTLY_HIRING_TYPE: 'currently-hiring-type',
};

export const enum ApiVersion {
  V1 = 'v1',
  V2 = 'v2',
  V3 = 'v3',
  NO_VERSION = '',
}

export const TESTIMONIALS = [
  {
    quote:
      'Thank you, FlexJobs, for assisting me in finding this amazing employment opportunity!',
    by: 'Rebecca T., Brigham City, UT',
    detail:
      'Inbound Customer Service Representative at Discover Financial Services',
    date: 'Mar 13, 2023',
  },
  {
    quote:
      'I was out of a job for a while and wanted to break into the work-from-home job market, and FlexJobs worked wonders!',
    by: 'Christine A., Middletown, MD',
    detail: 'Call Center Agent at Working Solutions',
    date: 'Mar 8, 2023',
  },
  {
    quote:
      'Thank you, FlexJobs, for assisting me in finding this amazing employment opportunity!',
    by: 'Rebecca T., Brigham City, UT',
    detail:
      'Inbound Customer Service Representative at Discover Financial Services',
    date: 'Mar 13, 2023',
  },
  {
    quote:
      'I was out of a job for a while and wanted to break into the work-from-home job market, and FlexJobs worked wonders!',
    by: 'Christine A., Middletown, MD',
    detail: 'Call Center Agent at Working Solutions',
    date: 'Mar 8, 2023',
  },
  {
    quote:
      'Thank you, FlexJobs, for assisting me in finding this amazing employment opportunity!',
    by: 'Rebecca T., Brigham City, UT',
    detail:
      'Inbound Customer Service Representative at Discover Financial Services',
    date: 'Mar 13, 2023',
  },
  {
    quote:
      'I was out of a job for a while and wanted to break into the work-from-home job market, and FlexJobs worked wonders!',
    by: 'Christine A., Middletown, MD',
    detail: 'Call Center Agent at Working Solutions',
    date: 'Mar 8, 2023',
  },
  {
    quote:
      'Thank you, FlexJobs, for assisting me in finding this amazing employment opportunity!',
    by: 'Rebecca T., Brigham City, UT',
    detail:
      'Inbound Customer Service Representative at Discover Financial Services',
    date: 'Mar 13, 2023',
  },
];

export const Revalidate = Number(process.env.REVALIDATE) || 7200;

export const relatedArticles = [
  {
    name: 'Top 30 Companies That Hire for Work-From-Anywhere Jobs',
    slug: '/blog/post/top-companies-work-from-anywhere-remote-jobs',
    imgSrc:
      'https://fjwp.s3.amazonaws.com/blog/wp-content/uploads/2023/04/18072055/Top-30-Companies-That-Hire-for-Work-From-Anywhere-Jobs2.jpg',
  },
  {
    name: ' 10 Remote, Work-from-Home Jobs With No Experience Needed',
    slug: '/blog/post/how-to-make-money-from-home-no-work-experience',
    imgSrc:
      'https://fjwp.s3.amazonaws.com/blog/wp-content/uploads/2021/02/25083908/How-to-Find-Online-Jobs-Work-From-Home-Jobs-That-Require-Little-or-No-Work-Experience.jpg',
  },
];

export const followedCompnies = [
  {
    imgSrc: 'https://fjdsas.s3.amazonaws.com/wa4ex6h5xiil7kn4zcxeiadn4pml',
    slug: '/remote-jobs/company/lumen_technologies',
    title: 'Lumen Technologies',
  },
  {
    imgSrc: 'https://fjdsas.s3.amazonaws.com/8qd0okdpbprz7ytcf1fsnisoux1j',
    slug: '/remote-jobs/company/faegre_drinker',
    title: 'Faegre Drinker',
  },
];

export const defaultCompanyLogo =
  '/images/icons/streamline-thin/streamline-icon-real-estate-building-person.svg';

export const commonRegex = {
  htmlTags: /<\/?[^>]*>/g,
};

export const PreSelectedFilterPage = {
  'full-time': {
    heading: 'Full Time Remote',
    title: 'Full Time Remote Jobs',
    description: 'Remote Full Time Jobs',
    scheduleType: 'Full-Time',
    selecteFilter: {
      queryVal: 'Full-Time',
      jobschedules: 'Full-Time',
    },
  },
  'part-time': {
    heading: 'Remote Part Time',
    title: 'Part Time Jobs',
    description: 'Remote Part Time Jobs',
    scheduleType: 'Part-Time',
    selecteFilter: {
      queryVal: 'Part-Time',
      jobschedules: 'Part-Time',
    },
  },
  freelance: {
    heading: 'Freelance Remote',
    title: 'Freelance Jobs',
    description: 'Remote Freelance Jobs',
    scheduleType: 'Freelance',
    selecteFilter: {
      queryVal: 'freelance',
      jobtypes: 'Freelance',
    },
  },
  temp: {
    heading: 'Remote Temp',
    title: 'Temp Jobs',
    description: 'Remote Temporary Jobs',
    scheduleType: 'Temporary',
    selecteFilter: {
      queryVal: 'temporary',
      jobtypes: 'Temporary',
    },
  },
  'entry-level': {
    heading: 'Entry Level Remote',
    title: 'Temp Jobs',
    description: 'Entry Level Remote Jobs',
    scheduleType: 'Entry Level',
    selecteFilter: {
      queryVal: 'entry level',
      jobtypes: 'Entry Level',
    },
  },
};

export const EducationLevelText = {
  noEduSpecified: 'No Education Specified',
  sorryTxt:
    "We're sorry, the employer did not include education information for this job.",
};

export const CATEGORY_ICON_SLUG: any = {
  'account-management': {
    icon: 'streamline-icon-accounting-calculator.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'online-client-services': {
    icon: 'streamline-icon-accounting-calculator.svg',
    isSubCategory: true,
    parentCategory: { name: 'Account Management', slug: 'account-management' },
  },
  accounting: {
    icon: 'streamline-icon-saving-bag-increase.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  bookkeeping: {
    icon: 'streamline-icon-saving-bag-increase.svg',
    isSubCategory: true,
    parentCategory: { name: 'Accounting & Finance', slug: 'accounting' },
  },
  auditing: {
    icon: 'streamline-icon-saving-bag-increase.svg',
    isSubCategory: true,
    parentCategory: { name: 'Accounting & Finance', slug: 'accounting' },
  },
  banking: {
    icon: 'streamline-icon-saving-bag-increase.svg',
    isSubCategory: true,
    parentCategory: { name: 'Accounting & Finance', slug: 'accounting' },
  },
  'administration-support-services': {
    icon: 'streamline-icon-administrative.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'online-collections': {
    icon: 'streamline-icon-administrative.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Administrative',
      slug: 'administration-support-services',
    },
  },
  'online-virtual-admin': {
    icon: 'streamline-icon-administrative.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Administrative',
      slug: 'administration-support-services',
    },
  },
  'online-appointment-setting': {
    icon: 'streamline-icon-administrative.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Administrative',
      slug: 'administration-support-services',
    },
  },
  'advertising-pr': {
    icon: 'streamline-icon-messages-people-user-star.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'online-advertising': {
    icon: 'streamline-icon-messages-people-user-star.svg',
    isSubCategory: true,
    parentCategory: { name: 'Advertising & PR', slug: 'advertising-pr' },
  },
  'animals-wildlife': {
    icon: 'streamline-icon-pets-paw-heart.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'art-products-services': {
    icon: 'streamline-icon-color-tube-painting.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'photography-art': {
    icon: 'streamline-icon-color-tube-painting.svg',
    isSubCategory: true,
    parentCategory: { name: 'Art & Creative', slug: 'art-products-services' },
  },
  'online-music': {
    icon: 'streamline-icon-color-tube-painting.svg',
    isSubCategory: true,
    parentCategory: { name: 'Art & Creative', slug: 'art-products-services' },
  },
  theater: {
    icon: 'streamline-icon-color-tube-painting.svg',
    isSubCategory: true,
    parentCategory: { name: 'Art & Creative', slug: 'art-products-services' },
  },
  bilingual: {
    icon: 'streamline-icon-passport.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'online-spanish': {
    icon: 'streamline-icon-passport.svg',
    isSubCategory: true,
    parentCategory: { name: 'Bilingual', slug: 'bilingual' },
  },
  'online-french': {
    icon: 'streamline-icon-passport.svg',
    isSubCategory: true,
    parentCategory: { name: 'Bilingual', slug: 'bilingual' },
  },
  chinese: {
    icon: 'streamline-icon-passport.svg',
    isSubCategory: true,
    parentCategory: { name: 'Bilingual', slug: 'bilingual' },
  },
  german: {
    icon: 'streamline-icon-passport.svg',
    isSubCategory: true,
    parentCategory: { name: 'Bilingual', slug: 'bilingual' },
  },
  japanese: {
    icon: 'streamline-icon-passport.svg',
    isSubCategory: true,
    parentCategory: { name: 'Bilingual', slug: 'bilingual' },
  },
  portuguese: {
    icon: 'streamline-icon-passport.svg',
    isSubCategory: true,
    parentCategory: { name: 'Bilingual', slug: 'bilingual' },
  },
  'business-development': {
    icon: 'streamline-icon-performance-increase-mail.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'call-center': {
    icon: 'streamline-icon-phone-actions-ringing.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'online-inbound-call': {
    icon: 'streamline-icon-phone-actions-ringing.svg',
    isSubCategory: true,
    parentCategory: { name: 'Call Center', slug: 'call-center' },
  },
  'outbound-call': {
    icon: 'streamline-icon-phone-actions-ringing.svg',
    isSubCategory: true,
    parentCategory: { name: 'Call Center', slug: 'call-center' },
  },
  communications: {
    icon: 'streamline-icon-conversation-browser.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'computer-it': {
    icon: 'streamline-icon-laptop-sync.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'online-technical-support': {
    icon: 'streamline-icon-laptop-sync.svg',
    isSubCategory: true,
    parentCategory: { name: 'Computer & IT', slug: 'computer-it' },
  },
  'online-sql': {
    icon: 'streamline-icon-laptop-sync.svg',
    isSubCategory: true,
    parentCategory: { name: 'Computer & IT', slug: 'computer-it' },
  },
  'computer-security': {
    icon: 'streamline-icon-laptop-sync.svg',
    isSubCategory: true,
    parentCategory: { name: 'Computer & IT', slug: 'computer-it' },
  },
  'database-administration': {
    icon: 'streamline-icon-laptop-sync.svg',
    isSubCategory: true,
    parentCategory: { name: 'Computer & IT', slug: 'computer-it' },
  },
  'system-administration': {
    icon: 'streamline-icon-laptop-sync.svg',
    isSubCategory: true,
    parentCategory: { name: 'Computer & IT', slug: 'computer-it' },
  },
  networking: {
    icon: 'streamline-icon-laptop-sync.svg',
    isSubCategory: true,
    parentCategory: { name: 'Computer & IT', slug: 'computer-it' },
  },
  'consulting-coaching': {
    icon: 'streamline-icon-team-share-idea.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'it-consulting': {
    icon: 'streamline-icon-team-share-idea.svg',
    isSubCategory: true,
    parentCategory: { name: 'Consulting', slug: 'consulting-coaching' },
  },
  'healthcare-consultant': {
    icon: 'streamline-icon-team-share-idea.svg',
    isSubCategory: true,
    parentCategory: { name: 'Consulting', slug: 'consulting-coaching' },
  },
  'education-consultant': {
    icon: 'streamline-icon-team-share-idea.svg',
    isSubCategory: true,
    parentCategory: { name: 'Consulting', slug: 'consulting-coaching' },
  },
  'business-consultant': {
    icon: 'streamline-icon-team-share-idea.svg',
    isSubCategory: true,
    parentCategory: { name: 'Consulting', slug: 'consulting-coaching' },
  },
  'customer-service-call-center': {
    icon: 'streamline-icon-conversation-smile-type.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'data-entry': {
    icon: 'streamline-icon-keyboard-wireless.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  editing: {
    icon: 'streamline-icon-editing.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'online-proofreading': {
    icon: 'streamline-icon-editing.svg',
    isSubCategory: true,
    parentCategory: { name: 'Editing', slug: 'editing' },
  },
  'education-training': {
    icon: 'streamline-icon-e-learning-monitor.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'online-tutoring': {
    icon: 'streamline-icon-e-learning-monitor.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Education & Training',
      slug: 'education-training',
    },
  },
  'online-teaching': {
    icon: 'streamline-icon-e-learning-monitor.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Education & Training',
      slug: 'education-training',
    },
  },
  'online-k12': {
    icon: 'streamline-icon-e-learning-monitor.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Education & Training',
      slug: 'education-training',
    },
  },
  'online-college-university': {
    icon: 'streamline-icon-e-learning-monitor.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Education & Training',
      slug: 'education-training',
    },
  },
  'education-online-teaching': {
    icon: 'streamline-icon-e-learning-monitor.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Education & Training',
      slug: 'education-training',
    },
  },
  engineering: {
    icon: 'streamline-icon-tools-wench-screwdriver.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'entertainment-media': {
    icon: 'streamline-icon-show-theater-masks.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  video: {
    icon: 'streamline-icon-show-theater-masks.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Entertainment & Media',
      slug: 'entertainment-media',
    },
  },
  film: {
    icon: 'streamline-icon-show-theater-masks.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Entertainment & Media',
      slug: 'entertainment-media',
    },
  },
  gaming: {
    icon: 'streamline-icon-show-theater-masks.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Entertainment & Media',
      slug: 'entertainment-media',
    },
  },
  environmental: {
    icon: 'streamline-icon-recycling-refresh.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'event-planning-wedding': {
    icon: 'streamline-icon-calendar-edit.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'fashion-beauty': {
    icon: 'streamline-icon-dating-lipstick.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'food-hospitality': {
    icon: 'streamline-icon-chef-gear-hat.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'government-politics': {
    icon: 'streamline-icon-official-building.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'federal-government': {
    icon: 'streamline-icon-official-building.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Government & Politics',
      slug: 'government-politics',
    },
  },
  military: {
    icon: 'streamline-icon-official-building.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Government & Politics',
      slug: 'government-politics',
    },
  },
  'state-local-government': {
    icon: 'streamline-icon-official-building.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Government & Politics',
      slug: 'government-politics',
    },
  },
  'graphic-design': {
    icon: 'streamline-icon-vectors-pen-add.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'digital-design': {
    icon: 'streamline-icon-vectors-pen-add.svg',
    isSubCategory: true,
    parentCategory: { name: 'Graphic Design', slug: 'graphic-design' },
  },
  'interactive-design': {
    icon: 'streamline-icon-vectors-pen-add.svg',
    isSubCategory: true,
    parentCategory: { name: 'Graphic Design', slug: 'graphic-design' },
  },
  'print-design': {
    icon: 'streamline-icon-vectors-pen-add.svg',
    isSubCategory: true,
    parentCategory: { name: 'Graphic Design', slug: 'graphic-design' },
  },
  'visual-design': {
    icon: 'streamline-icon-vectors-pen-add.svg',
    isSubCategory: true,
    parentCategory: { name: 'Graphic Design', slug: 'graphic-design' },
  },
  'human-resources-recruiting': {
    icon: 'streamline-icon-job-choose-cadidate.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'human-services': {
    icon: 'streamline-icon-messages-people-user-bubble-circle.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'social-work': {
    icon: 'streamline-icon-messages-people-user-bubble-circle.svg',
    isSubCategory: true,
    parentCategory: { name: 'Human Services', slug: 'human-services' },
  },
  community: {
    icon: 'streamline-icon-messages-people-user-bubble-circle.svg',
    isSubCategory: true,
    parentCategory: { name: 'Human Services', slug: 'human-services' },
  },
  insurance: {
    icon: 'streamline-icon-car-insurance.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'insurance-underwriting': {
    icon: 'streamline-icon-car-insurance.svg',
    isSubCategory: true,
    parentCategory: { name: 'Insurance', slug: 'insurance' },
  },
  'insurance-claims': {
    icon: 'streamline-icon-car-insurance.svg',
    isSubCategory: true,
    parentCategory: { name: 'Insurance', slug: 'insurance' },
  },
  'risk-management': {
    icon: 'streamline-icon-car-insurance.svg',
    isSubCategory: true,
    parentCategory: { name: 'Insurance', slug: 'insurance' },
  },
  international: {
    icon: 'streamline-icon-earth-refresh.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'internet-ecommerce': {
    icon: 'streamline-icon-internet-and-commerce.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'online-seo-sem': {
    icon: 'streamline-icon-internet-and-commerce.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Internet & Ecommerce',
      slug: 'internet-ecommerce',
    },
  },
  'online-social-media': {
    icon: 'streamline-icon-internet-and-commerce.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Internet & Ecommerce',
      slug: 'internet-ecommerce',
    },
  },
  legal: {
    icon: 'streamline-icon-legal.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  manufacturing: {
    icon: 'streamline-icon-manufacturing.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  marketing: {
    icon: 'streamline-icon-marketing.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'online-marketing': {
    icon: 'streamline-icon-manufacturing.svg',
    isSubCategory: true,
    parentCategory: { name: 'Marketing', slug: 'marketing' },
  },
  'math-economics': {
    icon: 'streamline-icon-performance-graph-calculator.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  statistics: {
    icon: 'streamline-icon-performance-graph-calculator.svg',
    isSubCategory: true,
    parentCategory: { name: 'Math & Economics', slug: 'math-economics' },
  },
  'healthcare-medical': {
    icon: 'streamline-icon-hospital-first-aid.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'online-nursing': {
    icon: 'streamline-icon-hospital-first-aid.svg',
    isSubCategory: true,
    parentCategory: { name: 'Medical & Health', slug: 'healthcare-medical' },
  },
  'online-pharmaceutical': {
    icon: 'streamline-icon-hospital-first-aid.svg',
    isSubCategory: true,
    parentCategory: { name: 'Medical & Health', slug: 'healthcare-medical' },
  },
  'case-management': {
    icon: 'streamline-icon-hospital-first-aid.svg',
    isSubCategory: true,
    parentCategory: { name: 'Medical & Health', slug: 'healthcare-medical' },
  },
  therapy: {
    icon: 'streamline-icon-hospital-first-aid.svg',
    isSubCategory: true,
    parentCategory: { name: 'Medical & Health', slug: 'healthcare-medical' },
  },
  'medical-coding': {
    icon: 'streamline-icon-hospital-first-aid.svg',
    isSubCategory: true,
    parentCategory: { name: 'Medical & Health', slug: 'healthcare-medical' },
  },
  dental: {
    icon: 'streamline-icon-hospital-first-aid.svg',
    isSubCategory: true,
    parentCategory: { name: 'Medical & Health', slug: 'healthcare-medical' },
  },
  nutrition: {
    icon: 'streamline-icon-hospital-first-aid.svg',
    isSubCategory: true,
    parentCategory: { name: 'Medical & Health', slug: 'healthcare-medical' },
  },
  psychiatry: {
    icon: 'streamline-icon-hospital-first-aid.svg',
    isSubCategory: true,
    parentCategory: { name: 'Medical & Health', slug: 'healthcare-medical' },
  },
  'doctor-practitioner': {
    icon: 'streamline-icon-hospital-first-aid.svg',
    isSubCategory: true,
    parentCategory: { name: 'Medical & Health', slug: 'healthcare-medical' },
  },
  'health-administration': {
    icon: 'streamline-icon-hospital-first-aid.svg',
    isSubCategory: true,
    parentCategory: { name: 'Medical & Health', slug: 'healthcare-medical' },
  },
  'medical-billing': {
    icon: 'streamline-icon-hospital-first-aid.svg',
    isSubCategory: true,
    parentCategory: { name: 'Medical & Health', slug: 'healthcare-medical' },
  },
  'medical-imaging': {
    icon: 'streamline-icon-hospital-first-aid.svg',
    isSubCategory: true,
    parentCategory: { name: 'Medical & Health', slug: 'healthcare-medical' },
  },
  'medical-lab-technology': {
    icon: 'streamline-icon-hospital-first-aid.svg',
    isSubCategory: true,
    parentCategory: { name: 'Medical & Health', slug: 'healthcare-medical' },
  },
  'medical-research': {
    icon: 'streamline-icon-hospital-first-aid.svg',
    isSubCategory: true,
    parentCategory: { name: 'Medical & Health', slug: 'healthcare-medical' },
  },
  'medical-pediatric': {
    icon: 'streamline-icon-hospital-first-aid.svg',
    isSubCategory: true,
    parentCategory: { name: 'Medical & Health', slug: 'healthcare-medical' },
  },
  'real-estate': {
    icon: 'streamline-icon-mortgage-and-real-estate.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'news-journalism': {
    icon: 'streamline-icon-news-and-journalism.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  reporter: {
    icon: 'streamline-icon-news-and-journalism.svg',
    isSubCategory: true,
    parentCategory: { name: 'News & Journalism', slug: 'news-journalism' },
  },
  publishing: {
    icon: 'streamline-icon-news-and-journalism.svg',
    isSubCategory: true,
    parentCategory: { name: 'News & Journalism', slug: 'news-journalism' },
  },
  'nonprofit-philanthropy': {
    icon: 'streamline-icon-love-it-hand-give.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'online-fundraising': {
    icon: 'streamline-icon-love-it-hand-give.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Nonprofit & Philanthropy',
      slug: 'nonprofit-philanthropy',
    },
  },
  operations: {
    icon: 'streamline-icon-task-list-settings.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  product: {
    icon: 'streamline-icon-monitor-touch.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'project-management': {
    icon: 'streamline-icon-time-clock-file-setting.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'research-development': {
    icon: 'streamline-icon-read-glasses.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  analyst: {
    icon: 'streamline-icon-read-glasses.svg',
    isSubCategory: true,
    parentCategory: { name: 'Research', slug: 'research-development' },
  },
  library: {
    icon: 'streamline-icon-read-glasses.svg',
    isSubCategory: true,
    parentCategory: { name: 'Research', slug: 'research-development' },
  },
  'consumer-products': {
    icon: 'streamline-icon-shopping-cart-star.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'sales-business-development': {
    icon: 'streamline-icon-messages-people-user-dollar.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'online-business-to-business': {
    icon: 'streamline-icon-messages-people-user-dollar.svg',
    isSubCategory: true,
    parentCategory: { name: 'Sales', slug: 'sales-business-development' },
  },
  'online-business-to-consumer': {
    icon: 'streamline-icon-messages-people-user-dollar.svg',
    isSubCategory: true,
    parentCategory: { name: 'Sales', slug: 'sales-business-development' },
  },
  'online-medical-sales': {
    icon: 'streamline-icon-messages-people-user-dollar.svg',
    isSubCategory: true,
    parentCategory: { name: 'Sales', slug: 'sales-business-development' },
  },
  'online-sales-representative': {
    icon: 'streamline-icon-messages-people-user-dollar.svg',
    isSubCategory: true,
    parentCategory: { name: 'Sales', slug: 'sales-business-development' },
  },
  'insurance-sales': {
    icon: 'streamline-icon-messages-people-user-dollar.svg',
    isSubCategory: true,
    parentCategory: { name: 'Sales', slug: 'sales-business-development' },
  },
  'software-sales': {
    icon: 'streamline-icon-messages-people-user-dollar.svg',
    isSubCategory: true,
    parentCategory: { name: 'Sales', slug: 'sales-business-development' },
  },
  'pharmaceutical-science': {
    icon: 'streamline-icon-lab-tube-experiment.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  chemistry: {
    icon: 'streamline-icon-lab-tube-experiment.svg',
    isSubCategory: true,
    parentCategory: { name: 'Science', slug: 'pharmaceutical-science' },
  },
  'web-software-development-programming': {
    icon: 'streamline-icon-programming-browser.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'online-java': {
    icon: 'streamline-icon-programming-browser.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Software Development',
      slug: 'web-software-development-programming',
    },
  },
  'online-php': {
    icon: 'streamline-icon-programming-browser.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Software Development',
      slug: 'web-software-development-programming',
    },
  },
  asp: {
    icon: 'streamline-icon-programming-browser.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Software Development',
      slug: 'web-software-development-programming',
    },
  },
  'ruby-on-rails': {
    icon: 'streamline-icon-programming-browser.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Software Development',
      slug: 'web-software-development-programming',
    },
  },
  python: {
    icon: 'streamline-icon-programming-browser.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Software Development',
      slug: 'web-software-development-programming',
    },
  },
  'qa-testing': {
    icon: 'streamline-icon-programming-browser.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Software Development',
      slug: 'web-software-development-programming',
    },
  },
  ios: {
    icon: 'streamline-icon-programming-browser.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Software Development',
      slug: 'web-software-development-programming',
    },
  },
  'sports-fitness': {
    icon: 'streamline-icon-fitness-weights.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  coaching: {
    icon: 'streamline-icon-fitness-weights.svg',
    isSubCategory: true,
    parentCategory: { name: 'Sports & Fitness', slug: 'sports-fitness' },
  },
  telemarketing: {
    icon: 'streamline-icon-smart-house-call.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  transcription: {
    icon: 'streamline-icon-ecology-globe-message.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'translation-travel': {
    icon: 'streamline-icon-audio-book-record-microphone-a.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'travel-hospitality': {
    icon: 'streamline-icon-airplane-swoosh.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'web-design': {
    icon: 'streamline-icon-vectors-square-link.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'writing-editing-journalism': {
    icon: 'streamline-icon-writing.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'online-blogging': {
    icon: 'streamline-icon-writing.svg',
    isSubCategory: true,
    parentCategory: { name: 'Writing', slug: 'writing-editing-journalism' },
  },
  'online-copywriting': {
    icon: 'streamline-icon-writing.svg',
    isSubCategory: true,
    parentCategory: { name: 'Writing', slug: 'writing-editing-journalism' },
  },
  'online-grant-writing': {
    icon: 'streamline-icon-writing.svg',
    isSubCategory: true,
    parentCategory: { name: 'Writing', slug: 'writing-editing-journalism' },
  },
  'online-content': {
    icon: 'streamline-icon-writing.svg',
    isSubCategory: true,
    parentCategory: { name: 'Writing', slug: 'writing-editing-journalism' },
  },
  'technical-writing': {
    icon: 'streamline-icon-writing.svg',
    isSubCategory: true,
    parentCategory: { name: 'Writing', slug: 'writing-editing-journalism' },
  },
  'youth-children': {
    icon: 'streamline-icon-family-child-teeter.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
};

export const AdvanceSearchFieldNames = {
  searchkeyword: 'searchkeyword',
  remoteoptions: 'remoteoptions',
  usLocation: 'USLocation',
  internationalLocation: 'interNationalLocation',
  jobtypes: 'jobtypes',
  jobschedules: 'jobschedules',
  careerlevel: 'careerlevel',
  educations: 'educations',
  accolades: 'accolades',
  categories: 'categories',
  travels: 'travels',
  excludeWords: 'excludeWords',
  anywhereInUS: 'anywhereInUS',
  anywhereInWorld: 'anywhereInWorld',
};

export const US_GLOBAL_LOCATION = {
  US_LOC: [
    'United States',
    'US',
    'USA',
    'U.S.',
    'U.S.A.',
    'U.S',
    'US National',
  ],
  GLOBAL_LOC: ['Anywhere', 'Global', 'World', 'Work from Anywhere'],
};

export const LOCATION_LOOKUP_COUNT = 15;

export const DISALLOW_MULTISELECT = ['careerlevel'];

export const LOCATION_CHECK_BOX_VALUE = {
  US: 'Work from Anywhere in US',
  WORLD: 'Work from Anywhere',
};

export const USER_TYPE = {
  Employer: 'Employer',
  Partner: 'Econ',
};

export const HIDDEN_COUNTRY_NAMES = [
  // Enter in lowercase
  'united states',
];

export const SECTION_TYPE_CD = {
  jobSearchBasics: 'JSRB',
};

export const FIELD_TYPE_CD = {
  jobCategory: 'JBCT',
};

export const SAVED_SEARCH_TYPE = {
  followedCompany: 'followedComp',
  savedSearch: 'savedSearch',
};

export const ECOM_WIDGET_URLS = [
  '/registration/signup',
  '/login',
  '/registration/msignup',
];

export const Debuger_Script_URLS = [
  '/registration/msignup',
  '/registration/signup',
  '/remote-jobs/world/anywhere',
  '/remote-jobs/world/United-Kingdom',
  '/remote-jobs/world/India',
  '/remote-jobs/world/Mexico',
  '/remote-jobs/world/Japan',
  '/remote-jobs/company/whatsapp',
  'remote-jobs/company/working_solutions',
  '/remote-jobs/company/invisible_technologies',
  '/remote-jobs/company/appen',
  '/remote-jobs/company/10up',
  '/remote-jobs/company/transcribeme',
  '/remote-jobs/company/axion_data_services',
  '/remote-jobs/company/athena_ea',
  '/remote-jobs/company/spacex',
  '/remote-jobs/company/chat_shop',
  '/remote-jobs/developer',
  '/remote-jobs/typing',
];

export const SALESFORCE_CHAT_URLS = [
  '/remote-jobs',
  '/company-guide',
  '/remote-jobs/world/anywhere',
  '/remote-jobs/usa/us-national',
  '/remote-jobs/world/Canada',
  '/remote-jobs/world/United-Kingdom',
  '/remote-jobs/europe',
  '/remote-jobs/world/Australia',
  '/remote-jobs/entry-level',
];

export enum SAVED_SEARCH_BUTTON_STATE {
  save_search = 'SAVE_SEARCH',
  saved = 'Saved',
  save_changes = 'SAVE_CHANGES',
  hide = 'HIDE',
}

export enum EXIT_POP_UP_EVENT_LABEL {
  location = 'Location Page',
  popular_keyword = 'popular keyword page',
  top_searched = 'Top Searched Page',
  category_page = 'Category Page',
  about = 'about',
  benefits = 'benefits',
  pricing = 'pricing',
  company = 'remote-jobs/company',
  registration_page = 'Registration Page',
  tour = 'tour',
  public_jobs = 'publicjobs',
}

export const monthNamesShort = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const filterDropOrder: any = {
  Remote: {
    '100% Remote Work': '0',
    'Hybrid Remote Work': '0',
    'Option for Remote Work': '0',
    'No Remote Work': '0',
  },
  'Job Type': { Employee: '0', Freelance: '0', Temporary: '0' },
  'Career Level': {
    'Entry-Level': '0',
    Experienced: '0',
    Manager: '0',
    'Senior Level Manager': '0',
  },
  Schedule: {
    'Full-Time': '0',
    'Part-Time': '0',
    'Flexible Schedule': '0',
    'Alternative Schedule': '0',
  },
  Education: {
    'No Education Specified': '0',
    "Associate's Degree or Some College": '0',
    "Bachelor's/Undergraduate Degree": '0',
    "Master's Degree": '0',
    'Doctorate Degree': '0',
  },
  Travel: {
    No: '0',
    Yes: '0',
    'No specification': '0',
  },
  Category: {},
};

export const API_NAMES = {
  COUNTRY_LIST_EB: 'countryListEB',
  NEWS_WIDGET: 'newsWidget',
  SUCCESS_STORY_WIDGET: 'successStoryWidget',
  HIRING_COUNT: 'hiringCount',
  SAVE_JOB_ID: 'saveJobId',
  JOB_CATEGORIES: 'jobCategories',
  NEWS_AND_STORIES_WIDGET: 'newsAndStoriesWidget',
};

export const TIMEOUT_DURATION =
  Number(process.env.NEXT_PUBLIC_REQ_TIMEOUT) || 10000;

export const RegTestimonials = [
  {
    comment:
      'I joined FlexJobs skeptical that I would find a remote job because I have large employment gaps from being a stay-at-home mom.<strong> After the first week, I had three interviews and landed two jobs.</strong>',
    author: '-Ashley P.,  <strong>Hired at ModSquad</strong>',
  },
  {
    comment:
      'I only wish I had heard of FlexJobs sooner.<strong> Within a month of joining, I was interviewing for a new job that felt like a Cinderella fit for my skill set.</strong> The best part is that it&apos;s 100% remote, so no need to worry about the morning commute.',
    author: '-Lorraine B., <strong>Hired at Rainforest Trust</strong>',
  },
  {
    comment:
      'Remote work is getting harder to find, and FlexJobs served me better than other sites combined.<strong> Signing up for FlexJobs was the best job hunt decision I&apos;ve ever made.</strong>',
    author: '-Tom R., <strong>Hired at Beyond Finance</strong>',
  },
];

export const Top_Qualities_Remote_Sites = [
  {
    headcont: 'Highest Quality Listings',
    content:
      'Only legit jobs. No ads, scams, or junk to sift through. Our team spends 200+ hours/day verifying every job.',
  },
  {
    headcont: 'Unlimited Job Search Resources',
    content:
      'Full access to all features including unlimited jobs, articles, skills tests, and webinars to help you with your remote job search.',
  },
  {
    headcont: 'Save Time',
    content:
      'Go straight from job listings to applications. No more hopping from one job board to the next.',
  },
];

export const jobFeedSlugs = ['gjw', 'partner'];

export const LocalStorageKeys: Record<string, string> = {
  TotalJobsCount: 'TotalJobsCount',
};

export const locationQueryMapping: Record<string, string> = {
  'st-paul': 'St.-Paul',
  'st-petersburg': 'St.-Petersburg',
};

export const unlockBannerImages = [
  'unlock-banner-1.png',
  'unlock-banner-2.png',
  'unlock-banner-3.png',
];

export const SKIP_VISIT_URLS = [
  '/PasswordRecovery.aspx',
  '/registration/signup',
  '/registration/msignup',
  '/members/employers/signup',
  '/agnc',
  '/atehub',
  '/codekentucky',
  '/common',
  '/eri',
  '/konexio',
  '/loomeeGroup',
  '/myseco',
  '/naamal',
  '/portsMouth',
  '/ruralandremote',
  '/signUp',
  '/stateofhawaii',
  '/workReadyOklahoma',
  '/workwell',
];

export const TWENTY_FOUR_HOUR_CACHE = 24 * 60 * 60 * 1000;

export const STATE_CATEGORIES = [
  ['accounting', 'Accounting'],
  ['administrative-assistant', 'Administrative Assistant'],
  ['analyst', 'Analyst'],
  ['bookkeeping', 'Bookkeeping'],
  ['call-center', 'Call Center'],
  ['chat-support', 'Chat Support'],
  ['communications', 'Communications'],
  ['compliance', 'Compliance'],
  ['consulting', 'Consulting'],
  ['copywriting', 'Copywriting'],
  ['creative', 'Creative'],
  ['criminal-justice', 'Criminal Justice'],
  ['customer-service', 'Customer Service'],
  ['cybersecurity', 'Cybersecurity'],
  ['data-analyst', 'Data Analyst'],
  ['data-entry', 'Data Entry'],
  ['data-science', 'Data Science'],
  ['design', 'Design'],
  ['developer', 'Developer'],
  ['editing', 'Editing'],
  ['education', 'Education'],
  ['environmental', 'Environmental'],
  ['finance', 'Finance'],
  ['freelance', 'Freelance'],
  ['graphic-design', 'Graphic Design'],
  ['healthcare', 'Healthcare'],
  ['hospitality', 'Hospitality'],
  ['human-resources', 'Human Resources'],
  ['it', 'IT'],
  ['legal', 'Legal'],
  ['life-coach', 'Life Coach'],
  ['live-chat', 'Live Chat'],
  ['marketing', 'Marketing'],
  ['medical', 'Medical'],
  ['nonprofit', 'Nonprofit'],
  ['nursing', 'Nursing'],
  ['part-time', 'Part-Time'],
  ['procurement', 'Procurement'],
  ['product-manager', 'Product Manager'],
  ['project-management', 'Project Management'],
  ['proofreading', 'Proofreading'],
  ['public-health', 'Public Health'],
  ['qa', 'QA'],
  ['recruiter', 'Recruiter'],
  ['rn', 'RN'],
  ['sales', 'Sales'],
  ['social-media', 'Social Media'],
  ['software-engineering', 'Software Engineering'],
  ['teaching', 'Teaching'],
  ['training', 'Training'],
  ['transcription', 'Transcription'],
  ['translation', 'Translation'],
  ['travel-agent', 'Travel Agent'],
  ['tutoring', 'Tutoring'],
  ['typing', 'Typing'],
  ['ui-and-ux', 'UI & UX'],
  ['video-editing', 'Video Editing'],
  ['virtual-assistant', 'Virtual Assistant'],
  ['writing', 'Writing'],
];

export const countryTitlesArr = {
  'Other International Countries': 'All International',
};

export const countrySubTitlesArr = {
  'Other International Countries': 'All Flexible and Remote International Jobs',
};

export const metaTitleArr = {
  'Other International Countries': 'International',
};

export const metaDescriptionArr = {
  'Other International Countries':
    'Find remote, work from home international and overseas jobs in over 240 countries. Discover a better job search with FlexJobs today.',
};

export const readValueUsingKey = (titleArr: any, category: string) => {
  if (category in titleArr) {
    return titleArr[category];
  } else {
    return null;
  }
};

export const abbrevatedCategory = ['IT', 'RN', 'QA', 'UI & UX'];

export const countryWithAccentedLetters = [
  {
    name: 'Åland',
    slug: '/world/aland',
    oldLocationRoot: 'Åland',
    aliseName: 'aland',
  },
  {
    name: 'Cocos [Keeling] Islands',
    slug: '/world/cocos-keeling-islands',
    oldLocationRoot: 'Cocos-[Keeling]-Islands',
    aliseName: 'cocos-keeling-islands',
  },
  {
    name: 'Réunion',
    slug: '/world/reunion',
    oldLocationRoot: 'Réunion',
    aliseName: 'reunion',
  },
  {
    name: 'Curaçao',
    slug: '/world/curacao',
    oldLocationRoot: 'Curaçao',
    aliseName: 'curacao',
  },
  {
    name: 'São Tomé and Príncipe',
    slug: '/world/sao-tome-and-principe',
    oldLocationRoot: 'São-Tomé-And-Príncipe',
    aliseName: 'sao-tome-and-principe',
  },
  {
    name: 'Saint Barthélemy',
    slug: '/world/saint-barthelemy',
    oldLocationRoot: 'Saint-Barthélemy',
    aliseName: 'saint-barthelemy',
  },
  {
    name: 'U.S. Virgin Islands',
    slug: '/world/us-virgin-islands',
    oldLocationRoot: 'U.S.-Virgin-Islands',
    aliseName: 'us-virgin-islands',
  },
  {
    name: 'U.S. Minor Outlying Islands',
    slug: '/world/us-minor-outlying-islands',
    oldLocationRoot: 'U.S.-Minor-Outlying-Islands',
    aliseName: 'us-minor-outlying-islands',
  },
  {
    name: 'Heard Island and McDonald Islands',
    slug: '/world/heard-island-and-mcdonald-islands',
    oldLocationRoot: 'Heard-Island-And-Mcdonald-Islands',
    aliseName: 'heard-island-and-mcdonald-islands',
  },
  {
    name: 'Guinea-Bissau',
    slug: '/world/guinea-bissau',
    oldLocationRoot: 'Guinea-bissau',
    aliseName: 'guinea-bissau',
  },
];
export const WizardURL = '/job_wizard/welcome';

export const filterFlyerList = [
  'Remote',
  'Schedule',
  'Career Level',
  'Job Type',
  'Salary',
  'Benefits',
  'Education',
  'Category',
  'Travel',
  'Title',
  'Accolades',
];

export const nonUSDCountry: any = {
  MX: 'Mexico',
  CA: 'Canada',
  AT: 'Austria',
  BE: 'Belgium',
  BG: 'Bulgaria',
  HR: 'Croatia',
  CY: 'Cyprus',
  CZ: 'Czechia',
  DK: 'Denmark',
  EE: 'Estonia',
  FI: 'Finland',
  FR: 'France',
  DE: 'Germany',
  GR: 'Greece',
  HU: 'Hungary',
  IT: 'Italy',
  LV: 'Latvia',
  LT: 'Republic of Lithuania',
  LU: 'Luxembourg',
  MT: 'Malta',
  NL: 'Netherlands',
  PT: 'Portugal',
  RO: 'Romania',
  AL: 'Albania',
  BY: 'Belarus',
  BA: 'Bosnia and Herzegovina',
  IS: 'Iceland',
  LI: 'Liechtenstein',
  MD: 'Republic of Moldova',
  MC: 'Monaco',
  ME: 'Montenegro',
  MK: 'North Macedonia',
  NO: 'Norway',
  RU: 'Russia',
  SM: 'San Marino',
  RS: 'Serbia',
  SK: 'Slovakia',
  SI: 'Slovenia',
  ES: 'Spain',
  SE: 'Sweden',
  CH: 'Switzerland',
  UA: 'Ukraine',
  IE: 'Ireland',
  PL: 'Poland',
  IN: 'India',
  AU: 'Australia',
  GB: 'United Kingdom',
  BR: 'Brazil',
};

export const filterPageList = [
  'entry-level',
  'part-time',
  'full-time',
  'freelance',
  'temp',
];

export const companyListForRUMData = [
  'unitedhealth_group',
  'cvs_health',
  'reviewsorg',
  'transcribeme',
];

export const expertApplyApplicationUrl = '/expertapply/applications';

export const isProdEnv = process.env.NEXT_PUBLIC_ENV === 'prod';

export const filtersQueryParamKeys: string[] = [
  'categories',
  'remoteoptions',
  'joblocations',
  'searchkeyword',
  'loc.latlng',
  'loc.radius',
  'useclocation',
  'jobtypes',
  'jobschedules',
  'careerlevel',
  'educations',
  'travels',
  'anywhereinus',
  'anywhereinworld',
  'salaryrange',
  'age',
  'companies',
  'onlyexpertapplyjobs',
  'page',
  'search',
  'category',
  'eID',
  'vID',
  'os',
  'accolade',
  'time_frame',
  'location',
  'anx',
  'career_level',
  'schedule',
  'psafe_param',
  'accolade[]',
  'category[]',
  'jobtypes[]',
  'will_travel[]',
  'schedule[]',
  'tele_level[]',
  'title[]',
];

export const popularSearchData = [
  {
    title: 'Remote Data Entry Jobs',
    href: '/search?searchkeyword=Data%20Entry&remoteoptions=100%25%20Remote%20Work&useclocation=true',
  },
  {
    title: 'Remote Customer Service Jobs',
    href: '/search?searchkeyword=Customer%20Service&remoteoptions=100%25%20Remote%20Work&useclocation=true',
  },
  {
    title: 'Remote Virtual Assistant Jobs',
    href: '/search?searchkeyword=Virtual%20Assistant&remoteoptions=100%25%20Remote%20Work&useclocation=true',
  },
  {
    title: 'Remote Jobs',
    href: '/search?remoteoptions=100%25%20Remote%20Work&useclocation=true',
  },
  {
    title: 'Remote Entry Level Jobs',
    href: '/search?remoteoptions=100%25%20Remote%20Work&careerlevel=Entry-Level&useclocation=true',
  },
  {
    title: 'Remote Experienced Jobs',
    href: '/search?remoteoptions=100%25%20Remote%20Work&careerlevel=Experienced&useclocation=true',
  },
  {
    title: 'Remote Project Manager Jobs',
    href: '/search?searchkeyword=Project%20Manager&useclocation=true',
  },
  {
    title: 'Remote Data Analyst Jobs',
    href: '/search?searchkeyword=Data%20Analyst&remoteoptions=100%25%20Remote%20Work&careerlevel=Entry-Level&useclocation=true',
  },
];

export const fieldsToRemoveFromBenefits = [
  'Community Service',
  'Commuter Benefits',
  'Snacks Provided',
  'Lunch Provided',
  'Relocation Stipend',
  'Complimentary Parking',
  'Mindfulness Parking',
  'Dogs in Office',
];
