import {
  getURLSearchParams,
  getUserPreferenceValue,
  localStorageSetItem,
} from '@components/common';
import { PreferenceItem, Preferences } from '@components/shared.types';
import {
  getAllJobsExpertApplyStatus,
  getLatestActiveDocument,
  getUserCredits,
  getUserPreferences,
} from 'API/ApiHelpers';
import { getExperimentVariant } from 'helper/experiments/experimentActions';
import { FJPortalExperiments } from 'helper/experiments/experimentConstants';
import { transformData } from './jobsCount';

export const getExpertApplyExperimentObject = async (
  userId: string,
  cookieHeader: string,
  queryString: string,
  deviceType: string
) => {
  let isExpertApplyExperimentRunning = false;
  let eaplID = '';
  let eaplDoc = null;
  let onlyExpertApplyJobFilterSelected: string = 'false';
  let userPref = 'NOTSTARTED';
  let doesUserUploadResume = false;

  // let expertApplyJobStatusMap: any = {};
  // step1 - check if the experiment is running for this user or not
  const desktiopExpId = FJPortalExperiments.fjExpertApplyDesktop.id;
  const mobileExpId = FJPortalExperiments.fjExpertApplyMobile.id;
  const desktopVariant = getExperimentVariant(desktiopExpId, cookieHeader);

  isExpertApplyExperimentRunning = desktopVariant === 3;
  if (desktopVariant !== 3) {
    const mobileVariant = getExperimentVariant(mobileExpId, cookieHeader);
    isExpertApplyExperimentRunning = mobileVariant === 3;
  }

  // step1.2 get the user credits
  if (isExpertApplyExperimentRunning) {
    const creditResponse = await getUserCredits(userId, cookieHeader);
    if (creditResponse.success) {
      if (creditResponse.data?.credits <= 0) {
        userPref = 'MAX_LIMIT';
      }
    }
  }
  // step2 - get the eaplId for the given user
  if (isExpertApplyExperimentRunning) {
    onlyExpertApplyJobFilterSelected = queryString
      ? getURLSearchParams(queryString, 'onlyexpertapplyjobs') == '1'
        ? 'true'
        : 'false'
      : 'false';
    const { data: activeUserDoc } = await getLatestActiveDocument(
      userId,
      cookieHeader,
      'EAPL',
      1,
      true,
      true,
      undefined,
      false
    );
    if (Array.isArray(activeUserDoc) && activeUserDoc.length > 0) {
      eaplID = activeUserDoc[0]?.id || '';
      eaplDoc = activeUserDoc[0] || null;
    }
    const resumeStatus = getPrefByCode(activeUserDoc[0]?.preferences, 'RURL');

    if (resumeStatus && resumeStatus.pref?.value) {
      doesUserUploadResume = true;
    }

    // expert apply profile status
    if (userPref !== 'MAX_LIMIT') {
      const userPreferencesResponse = await getUserPreferences(
        userId,
        cookieHeader
      );
      const eastStatus = userPreferencesResponse.data?.find(
        (item) => item.code === 'EAST'
      );
      const ebstStatus = userPreferencesResponse.data?.find(
        (item) => item.code === 'EBST'
      );

      const finalStatus = eastStatus || ebstStatus;
      if (finalStatus) {
        userPref =
          finalStatus.value === 'FINISH' ? finalStatus.value : 'INCOMPLETE';
      }
    }
    // fetch all jobs status map
    // let expertApplyJobStatus = await fetchExpertApplyJobStatusMap(eaplID);
  }

  return {
    isExpertApplyExperimentRunning,
    eaplID,
    onlyExpertApplyJobFilterSelected,
    userExpertApplyProfileStatus: userPref,
    eaplDoc,
    expertApplyJobStatus: eaplID
      ? await fetchExpertApplyJobStatusMap(eaplID, '', cookieHeader)
      : null,
    doesUserUploadResume,
  };
};

const getPrefByCode = (preferences: Array<Preferences>, prefCD: string) => {
  const prefInfo: { pref: Preferences | null; index: number } = {
    pref: null,
    index: -1,
  };
  if (preferences?.length > 0) {
    preferences.forEach((item: Preferences, index: number) => {
      if (item.docPreferenceTypeCD === prefCD) {
        prefInfo.pref = item;
        prefInfo.index = index;
      }
    });
  }
  return prefInfo;
};

function getVariantFromResponse(response: any) {
  if (response?.isSuccess && response?.data) {
    return response.data.variant;
  }
  return 1;
}
export const fetchExpertApplyJobStatusMap = async (
  eaplId: string,
  jobId: string,
  cookieHeader?: string
): Promise<any> => {
  const allExpertJobStatus = await getAllJobsExpertApplyStatus(
    eaplId,
    cookieHeader
  );
  let allExpertJobStatusMap: any = {};

  if (allExpertJobStatus) {
    allExpertJobStatusMap = transformData(allExpertJobStatus?.data);
  }

  if (jobId) {
    return allExpertJobStatusMap[jobId]?.status || '';
  }
  return allExpertJobStatusMap;
};

export const getExpertAppliedJobDate = (
  expertApplyJobStatus: string,
  jobId: string,
  expertApplyJobStatusMap: any
) => {
  if (expertApplyJobStatus !== 'SUCCESS') return '';
  return expertApplyJobStatusMap[jobId]?.appliedDate;
};

export const incompleteExpertApplyHandler = (
  type: string,
  router: any,
  expertApplyExperimentObj: any,
  jobId: string
) => {
  if (typeof window !== 'undefined') {
    if (
      type.toLocaleLowerCase() === 'incomplete' &&
      expertApplyExperimentObj.doesUserUploadResume
    ) {
      //setting previous url in localstorage for the refrence to navigate from the short-summary page
      let currentUrl = '';
      if (typeof window !== 'undefined') {
        currentUrl = window.location.pathname + window.location.search;
      }
      localStorageSetItem('previousUrl', currentUrl);
      router.push(`/profile-review/short-summary?jobid=${jobId}`);
    } else {
      router.push('/profile-review/questions');
    }
  }
};

export const getExpertApplyData = async (
  commonSSRProps: any,
  queryString: string
) => {
  if (commonSSRProps.userId && commonSSRProps.isPremiumUser) {
    return await getExpertApplyExperimentObject(
      commonSSRProps.userId,
      commonSSRProps.cookieHeader,
      queryString,
      commonSSRProps.deviceType
    );
  }
  return {};
};
