import {
  Colors,
  Icons,
  base_font,
  fontWeight,
  lineHeight,
} from '@styles/base/styled';

const jobGuideInfoStyle = {
  BgColors: {
    header: Colors.gray2,
    wrap: Colors.white,
  },
  BdrColors: {
    header: Colors.gray2,
    wrap: Colors.gray1,
    popoverArrow: Colors.white,
    popoverArrowBefore: Colors.gray1,
  },
  Colors: {
    popoverHeader: Colors.black,
    listItem: Colors.black,
  },
  FontSizes: {
    wrap: (base_font * 0.875).toFixed(1) + 'rem',
    listItem: (base_font * 0.75).toFixed(1) + 'rem',
    header: base_font.toFixed(1) + 'rem',
  },
  FontWeight: {
    popoverHeader: fontWeight.semibold,
    listItem: fontWeight.normal,
  },
  Icons: {
    locationIcon: Icons.location,
  },
  LineHeight: {
    wrap: lineHeight.xl,
    desc: lineHeight.normal,
  },
};

export default jobGuideInfoStyle;
