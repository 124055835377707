/* eslint-disable no-unused-vars */
export enum TrackingEvents {
  Job_Search_Result = 'job search result',
  Job_Search_Performed = 'job search performed',
  Job_Search_Result_Interactions = 'job search results interactions',
  FJ_Exp_Viewed = 'FJ Experiment Viewed',
  Job_Application_Performed = 'Manage Job Applications',
  Job_Search_Impression = 'Job Search Impression',
  FJ_Pop_Up_Viewed = 'popup',
  FJ_Pop_Up_Clicked = 'popup',
}

export enum ViewType {
  Separate_Tab = 'separate tab',
  Job_Post_Details = 'job post details',
}

export enum Action {
  Clicked = 'clicked',
  Applied = 'applied',
  Viewed = 'viewed',
  Save_Search = 'save search',
}

export enum ClickOption {
  Job_summary = 'job summary',
  Company = 'Company',
  Create_Account = 'Create Account',
  Apply = 'apply',
  Unsave_Job = 'unsave job',
  Save_Job = 'save job',
  Report_Job = 'report job',
  Success_Stories = 'success stories',
  Category = 'category',
  Tips = 'tips',
  Clear = 'clear',
  Date = 'date',
  Relevance = 'relevance',
  ExpertApply = 'ExpertApply',
  ReviewApplication = 'Review Application',
  Salary_Filter = 'salary filter',
  Benefits_Filter = 'benefits filter',
}

export enum SearchType {
  Basic_Search = 'basic search',
  Detailed_Search = 'detailed search',
  Saved_Search = 'saved search',
  Advanced_Search = 'advanced search',
}

export enum MODAL_CLOSE_POP_UP {
  exit_pop_up = 'Flexer exit popup',
  pop_up_coupon = 'Flexer popup coupon',
  employer_coupon = 'Employer popup coupon',
}

export const POP_UP_EVENT = {
  how_FJ_is_diff_viewed: {
    event: 'how FJ works popup',
    properties: { action: 'viewed' },
  },
  how_FJ_is_diff_clicked: {
    event: 'how FJ works popup',
    properties: { action: 'clicked', 'click option': 'lets go' },
  },
  pop_up_close_event: {
    event: 'popup',
    properties: {
      action: 'close',
    },
  },
  registration_pop_up_closed: {
    event: 'registration popup',
    properties: {
      action: 'clicked',
      'click option': 'x_close',
    },
  },
  registration_pop_up_joined: {
    event: 'registration popup',
    properties: {
      action: 'clicked',
      'click option': 'join now',
    },
  },
};

export const REGISTRATION_EVENTS = {
  event_page_click: 'page click',
  event_name: 'registration entry',
  event_registration_error: 'registration error',
  fullName: 'full name',
  first_name_prop: 'first name',
  last_name_prop: 'last name',
  email_prop: 'email address',
  confirm_email_prop: 'confirm email',
  password_prop: 'password',
  sign_up_prop: 'sign up today',
};

export const SEARCH_REG_MODAL_CLICK_EVENTS = {
  close: 'close',
  email_prop: 'email address',
  password_prop: 'password',
  submit: 'submit',
  log_in: 'log in',
};

export const USER_TRAITS_JOBS_COUNT = {
  SAVED_SEARCH: 'saved searches',
  VIEWED_JOBS: 'viewed jobs',
  JOB_APPLICATIONS: 'job applications',
  SAVED_JOBS: 'saved jobs',
};

export const API_CALLING_DURATION = {
  API_CALL_COUNTS: 100,
  API_CALL_DURATIONS: 'apiCallDurations',
};

export const SEARCH_PAGE_EVENTS = {
  event_click: 'page click',
  keyword_search: 'keyword search box',
  location_search_box: 'location search box',
  click_location: 'bottom',
};
