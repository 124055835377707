import { _CONSTANTS } from '@components/common/constant';
import { PopularKeywordsExpVariant } from '@components/shared.types';
import {
  ABtestData,
  FJPortalExperiments,
} from 'helper/experiments/experimentConstants';
import { ReactNode, createContext, useContext } from 'react';

type CookieData = {
  [key: string]: any;
};

interface ExperimentContextType {
  experimentCookies: CookieData;
  deviceType: any;
  queryParams: any;
  domain?: string;
  expertApplyExperimentObj: any;
  popularKeywordsExpVariantObj: PopularKeywordsExpVariant;
  getExperimentVariant: (
    _experimentID: string,
    _cookieData: CookieData
  ) => number;
}

export const ExperimentContext = createContext<ExperimentContextType>({
  experimentCookies: {},
  deviceType: undefined,
  queryParams: {},
  domain: '',
  getExperimentVariant: () => 0,
  expertApplyExperimentObj: {},
  popularKeywordsExpVariantObj: {
    experimentId: '',
    variant: 0,
    isConducted: false,
  },
});

export const useExperimentContext = () => {
  const context = useContext(ExperimentContext);
  if (!context) {
    throw new Error(
      'useExperimentContext must be used within an ExperimentProvider'
    );
  }
  return context;
};

interface ExperimentProviderProps {
  children: ReactNode;
  experimentCookies: CookieData;
  deviceType: any;
  queryParams?: any;
  domain?: string;
  expertApplyExperimentObj?: any;
  popularKeywordsExpVariantObj: PopularKeywordsExpVariant;
}

export const ExperimentProvider: React.FC<ExperimentProviderProps> = ({
  children,
  deviceType,
  experimentCookies,
  queryParams,
  domain,
  expertApplyExperimentObj,
  popularKeywordsExpVariantObj,
}) => {
  return (
    <ExperimentContext.Provider
      value={{
        getExperimentVariant,
        deviceType: deviceType,
        experimentCookies,
        queryParams,
        domain,
        expertApplyExperimentObj,
        popularKeywordsExpVariantObj,
      }}
    >
      {children}
    </ExperimentContext.Provider>
  );
};

function getExperimentVariant(
  experimentID: string,
  cookieData: { [key: string]: any }
): number {
  let variant: number = 0;
  if (experimentID == FJPortalExperiments.fjWizardExperimentSpa.id) {
    return 3;
  }

  const runTestCookie: string | null = cookieData?.[ABtestData.RUNTEST];
  if (runTestCookie) {
    const runTestValue: string[] = runTestCookie.split('_');
    if (runTestValue.length === 2 && runTestValue[0] === experimentID) {
      return parseInt(runTestValue[1]);
    }
  } else {
    const disableTestCookie: string | null =
      cookieData?.[ABtestData.DISABLE_TESTS];

    if (disableTestCookie && disableTestCookie === '1') {
      return 1;
    }
  }

  try {
    const parsedCookieData = JSON.parse(
      cookieData[_CONSTANTS.CONDUCT_EXPERIMENT]
    );
    if (parsedCookieData && parsedCookieData[experimentID]) {
      variant = parsedCookieData[experimentID].v;
    }
  } catch (error) {
    // console.error('Error while getting variant from cookie:', error);
  }

  return variant;
}

export function JobsToWizardExperimentVariantDesktop(): String {
  const { queryParams } = useExperimentContext();
  const experimentRunning =
    queryParams?.eID === '15a74333-62b9-4cca-95e5-8712b9e7650a';
  if (experimentRunning) {
    return queryParams?.vID;
  }
  return '0';
}

export function SortByDateExperimentVariantSubscriber(): number {
  const { experimentCookies, deviceType, getExperimentVariant } =
    useExperimentContext();
  const experiemntId =
    deviceType === 'mobile'
      ? FJPortalExperiments?.fjSearchResultSortSubscriberMobile?.id
      : FJPortalExperiments?.fjSearchResultSortSubscriberDesktop?.id;
  return getExperimentVariant(experiemntId, experimentCookies);
}

export function SortByDateExperimentVariantVisitor(query?: any): number {
  let { deviceType, queryParams } = useExperimentContext();
  queryParams = query || queryParams;
  if (
    (queryParams?.eID == 'c6f60743-009d-4908-9660-24301151011a' &&
      queryParams?.vID == '3' &&
      deviceType == 'desktop') ||
    (queryParams?.eID == 'be71cfb0-8518-4970-bb23-52c23edeba67' &&
      queryParams?.vID == '3' &&
      deviceType == 'mobile')
  ) {
    return 3;
  }

  return 0;
}

export function ExpertApplyExperimentVariantSubscriber(query?: any): number {
  // TBD once the experiment is created
  const { experimentCookies, deviceType, getExperimentVariant } =
    useExperimentContext();
  const expId = '8a09e072-e32b-4fbe-ad87-517696a3ae3c';
  const variant = getExperimentVariant(expId, experimentCookies);
  return variant;
}

export function ABDrawerUpdateExperimentVarinatVisitor(query?: any): number {
  let { deviceType, queryParams } = useExperimentContext();
  queryParams = query || queryParams;
  if (
    (queryParams?.eID == '9a9b77d9-91bf-4a12-92cf-964c91de7cb1' &&
      queryParams?.vID == '3' &&
      deviceType == 'desktop') ||
    (queryParams?.eID == '3b4e353d-d887-4ca9-a01d-2df8e9d8a25d' &&
      queryParams?.vID == '3' &&
      deviceType == 'mobile')
  ) {
    return 3;
  }
  return 0;
}
