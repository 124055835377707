import { Colors, base_font, fontWeight } from '@styles/base/styled';

const JobDetailsStyle = {
  Colors: {
    btn: Colors.black,
    linkActive: Colors.black,
    link: Colors.gray1,
    linkDark: Colors.black,
    linkBtn: Colors.white,
    list: Colors.black,
    icon: Colors.black,
    iconblue: Colors.black,
    approvedHover: Colors.gray1,
    approvedText: Colors.gray2,
    jobDate: Colors.black,
    aboutTitle: Colors.black,
    verifiedInfo: Colors.black,
    authour: Colors.black,
    textHighlight: Colors.black,
    bannerQuote: Colors.black,
    jobTime: Colors.black,
    compName: Colors.black,
  },
  BgColors: {
    wrapper: Colors.white,
    linkBtn: Colors.black,
    card: Colors.gray2,
    stickyWrap: Colors.white,
    quoteBox: Colors.gray2,
  },
  BdrColors: {
    wrapper: Colors.gray1,
    tabsWrapper: Colors.gray2,
    link: Colors.black,
    imageCard: Colors.gray2,
    wrapperActive: Colors.gray2,
    card: Colors.gray2,
  },
  BoxShadow: {
    card: Colors.gray2,
    cardHover: Colors.gray2,
  },
  FontSize: {
    linkBtn: base_font + 'rem',
    approvedText: (base_font * 0.685).toFixed(1) + 'rem',
    jobDate: (base_font * 0.75).toFixed(1) + 'rem',
    jobTitleSm: (base_font * 1.25).toFixed(1) + 'rem',
    aboutTitle: (base_font * 1.12).toFixed(1) + 'rem',
    unlockBannerTitleSm: (base_font * 1.12).toFixed(1) + 'rem',
    verfiedInfo: (base_font * 0.75).toFixed(1) + 'rem',
    authour: (base_font * 0.875).toFixed(1) + 'rem',
    jobTime: (base_font * 0.875).toFixed(1) + 'rem',
    saveJobBtn: (base_font * 0.875).toFixed(1) + 'rem',
    unlockBannerTitle: (base_font * 1.75).toFixed(1) + 'rem',
    stickyButtonWrapper: (base_font * 1.125).toFixed(1) + 'rem',
    stickyButtonNewWrapper: (base_font * 1.125).toFixed(1) + 'rem',
    IconTick: (base_font * 0.75).toFixed(1) + 'rem',
    IconTickSm: (base_font * 0.625).toFixed(1) + 'rem',
  },
  FontWeight: {
    linkBtn: fontWeight.medium,
    icon: fontWeight.strong,
    verfiedInfo: 600,
    highlightText: 500,
    quote: 500,
  },
};

export default JobDetailsStyle;
