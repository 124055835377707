import { Colors, base_font } from '@styles/sapphire/styled';

const HeaderSectionStyle = {
  Colors: {
    title: Colors.blue1,
    badge: Colors.white,
  },
  FontSizes: {
    badge: base_font + 'rem',
    Heading: base_font * 1.625 + 'rem',
  },
  BgColors: {
    badge: Colors.orange1,
  },
  BdrColors: {
    wraperborder: Colors.gray15,
  },
  LineHeights: {
    badge: base_font * 0.625 + 'rem',
  },
};

export default HeaderSectionStyle;
